<div (click)="close()" class="d-flex flex-row-reverse">
    <i class="fas fa-times hover-cursor"></i>
</div>
<h2 mat-dialog-title>Text-Blöcke</h2>

<mat-dialog-content [formGroup]="form">
    <mat-form-field>
        <input matInput
               minlength="1"
               maxlength="10"
               placeholder="Kürzel"
               formControlName="shortcutKey"
               [(ngModel)]="this.shortCodeObject.ShortcutKey">
    </mat-form-field>
    <mat-form-field>
      <textarea matInput
             minlength="1"
             placeholder="Text"
             formControlName="shortcutText"
             [(ngModel)]="this.shortCodeObject.ShortcutText"></textarea>
    </mat-form-field>
</mat-dialog-content>

<mat-dialog-actions class="d-flex justify-content-between flex-row-reverse">
        <button mat-button color="primary" [disabled]="!this.shortCodeObject.ShortcutKey || !this.shortCodeObject.ShortcutText" (click)="save()">Speichern</button>
        <button *ngIf="editMode" mat-button color="warn" (click)="delete()">Entfernen</button>
</mat-dialog-actions>
