export const PortalTypes = {
    values: [
        { value: 'Apotheke', id: 45 },
        { value: 'Arzt/Ärztin', id: 1 },
        { value: 'Therapeut/Therapeutin', id: 36 },
        { value: 'Radiologie', id: 13 },
        { value: 'Labor', id: 33 },
        { value: 'Rehabilitationseinrichtungen', id: 43 },
        { value: 'Andere', id: 99 }
        // { value: 'Ambulatorium', id: 44 },
        // { value: 'Anästhesiologie und Intensivmedizin', id: 2 },
        // { value: 'Apotheke', id: 45 },
        // { value: 'Arbeits- und Betriebsmedizin', id: 29 },
        // { value: 'Arzt/Ärztin für Allgemeinmedizin', id: 1 },
        // { value: 'Augenheilkunde und Optometrie', id: 3 },
        // { value: 'Chirurgie', id: 4 },
        // { value: 'CT, MR und sonstige bildgebende technische Leistungen', id: 41 },
        // { value: 'Ergotherapeut', id: 40 },
        // { value: 'Frauenheilkunde und Geburtshilfe', id: 6 },
        // { value: 'Hals-, Nasen- und Ohren', id: 9 },
        // { value: 'Haut- und Geschlechtskrankheiten', id: 5 },
        // { value: 'Histologie und Embryologie', id: 26 },
        // { value: 'Hygiene und Mikrobiologie', id: 35 },
        // { value: 'Immunologie', id: 32 },
        // { value: 'Innere Medizin', id: 7 },
        // { value: 'Kinder- und Jugendheilkunde', id: 8 },
        // { value: 'Kinder- und Jugendpsychiatrie', id: 25 },
        // { value: 'Kinderchirurgie', id: 22 },
        // { value: 'Klinischer Psychologe', id: 39 },
        // { value: 'Labor', id: 33 },
        // { value: 'Logopäde', id: 37 },
        // { value: 'Lungenkrankheiten', id: 21 },
        // { value: 'medizinische Biologie', id: 27 },
        // { value: 'Medizinische und Chemische Labordiagnostik', id: 31 },
        // { value: 'Neurochirurgie', id: 17 },
        // { value: 'Neurologie', id: 18 },
        // { value: 'Neurologie und Psychiatrie', id: 10 },
        // { value: 'Nuklearmedizin', id: 23 },
        // { value: 'Orthopädie und orthopädische Chirurgie', id: 11 },
        // { value: 'Pathologie und Histologie', id: 34 },
        // { value: 'Pflegeheim, Pensionistenheim', id: 42 },
        // { value: 'Pharmakologie und Toxikologie', id: 30 },
        // { value: 'Physikalische Medizin', id: 12 },
        // { value: 'Physiotherapeut', id: 36 },
        // { value: 'Plastische Chirurgie', id: 20 },
        // { value: 'Psychiatrie', id: 19 },
        // { value: 'Psychotherapeut', id: 38 },
        // { value: 'Radiologie', id: 13 },
        // { value: 'Rehabilitationseinrichtungen', id: 43 },
        // { value: 'Strahlentherapie - Radioonkologie', id: 24 },
        // { value: 'Unfallchirurgie', id: 14 },
        // { value: 'Urologie', id: 15 },
        // { value: 'Virologie', id: 28 },
        // { value: 'Zahn-, Mund- und Kieferheilkunde', id: 16 }
    ],

    get(id?: number) {
        return typeof id === 'undefined' ? this.values.slice() : this.values.filter(item => item.id === id);
    }
};

