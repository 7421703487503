import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'statusFilter'
})
export class StatusFilterPipe implements PipeTransform {
  transform(value: any[], statusID: number): unknown {
    return (value || []).filter((item) => (!statusID && statusID != 0) || item.StatusID === statusID);
  }
}
