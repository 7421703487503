import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { Favorite } from '../../../shared/classes/favorite';
import { PatientRequest } from '../../../shared/classes/patient-request';
import { RequestsClientService } from '../clients/requests-client.service';

@Injectable({
  providedIn: 'root'
})
export class RequestsService {
  constructor(private requestClientService: RequestsClientService) {}

  readRequests(params?: object): Observable<PatientRequest[]> {
    return this.requestClientService.readRequests(params);
  }

  updateRequest(item: Partial<PatientRequest>): Promise<PatientRequest> {
    return this.requestClientService.update(item);
  }

  addRequest(patientID: number): Promise<PatientRequest> {
    return this.requestClientService.sendRequest(patientID);
  }

  deleteRequest(item: PatientRequest) {
    return this.requestClientService.deleteRequest(item);
  }

  sendRequestCollection(requests: PatientRequest[]): Promise<any> {
    //return Promise.resolve();
    return this.requestClientService.sendRequestCollection(requests).toPromise();
  }

  printRequestResult(request: PatientRequest, favorite: Favorite, sendToMA = false) {
    let requestResult = request?.RequestResultsJSON;
    if (!requestResult?.testTemplateName && requestResult?.testResult) {
      throw new Error('incomplete data');
    }
    const { testTemplateName, testResult } = requestResult;

    const resultMap = {
      1: 'POS',
      2: 'NEG',
      3: 'DELAYED',
      4: 'INVALID'
    };

    const templateName = `${testTemplateName}${resultMap[testResult] ? resultMap[testResult] : ''}`;
    console.log(templateName);
    // try {
    //   requestResult = JSON.parse(request.RequestResultsJSON);
    // } catch (err) {
    //   requestResult = {};
    // }
    // console.log(favorite);
    return this.requestClientService.getPdf(
      {
        PatientTitle: favorite.PersonTitle ?? '',
        PatientFirstName: favorite.FirstNameEn ?? '',
        PatientLastName: favorite.LastNameEn ?? '',
        PatientMiddleName: favorite.MiddleNameEn ?? '',
        PatientDateOfBirth: favorite.DateOfBirth ? moment(favorite.DateOfBirth).format('DD.MM.YYYY') : '',
        PatientCity: favorite.City ?? '',
        PatientPlaceOfBirth: favorite.PlaceOfBirth ?? '',
        PersonalIDNo: favorite.PersonalIDNo || '',
        MedicalReportDate: request.RequestDate ? moment(request.RequestDate).format('DD.MM.YYYY') : '',
        MedicalReportDateTime: request.RequestDate ? moment(request.RequestDate).format('DD.MM.YYYY HH:mm') : '',
        MAuserID: sendToMA ? favorite.MAContactID : null,
        MArequestID: sendToMA ? request.id : null,
        ...requestResult
      },
      templateName
      // 'MedicalReportCoronaZeugnis'
    );
  }
}
