import { Injectable } from "@angular/core";
import { AzureServiceService } from "./azure-service.service";
import { environment } from '../../../environments/environment';
import { HttpClient } from "@angular/common/http";

export interface IRegistrationData {
    title: string,
    firstName: string,
    lastName: string,
    fieldOfPractice: number,
    phonePrefix: string,
    phoneMobile: string,
    email: string,
    username: string,
    password: string,
    confirmPassword: string,
    agbAccepted: boolean,
    dsgvoAccepted: boolean,
    portalName: string,
    portalName2?: string,  // Detailed free-text field of practice (Tätigkeit gem. AG)
    salutation?: number
}

type IRegistrationRequest = IRegistrationData & { registrationToken?: string }

@Injectable()
export class RegistrationService {
    private registrationToken: string;
    private registrationPrefillData: Partial<IRegistrationData>;

  constructor(private azureService: AzureServiceService, private http: HttpClient) {

  }

  /**
   * Register a new user in BefundExpress
   * If a valid registrationToken is provided, the user won't have to wait for e-mail confirmation/activation
   *
   * @param {IRegistrationData} data
   * @param {string} [registrationToken]
   * @memberof ConnectedAccountsService
   */
  public async register(data: IRegistrationData, registrationToken?: string) {
    return await this.azureService.client.invokeApi('login', {
        body: {
            ...data,
            registrationToken
        } as IRegistrationRequest,
        method: 'put'
    });
  }

  public setRegistrationToken(token: string) { this.registrationToken = token; }
  public getRegistrationToken() { return this.registrationToken; }

  public setRegistrationPrefillData(data: Partial<IRegistrationData>) { this.registrationPrefillData = data; }
  public getRegistrationPrefillData() { return this.registrationPrefillData; }

  public getRegistrationDefaultData(): IRegistrationData {
    return {
		title: '',
		firstName: '',
		lastName: '',
		fieldOfPractice: 44,
		phonePrefix: '0043',
		phoneMobile: '',
		email: '',
		username: '',
		password: '',
		confirmPassword: '',
		agbAccepted: false,
		dsgvoAccepted: false,
		portalName: '',
		salutation: null
	};
  }
}
