<div class="container">
  <div class="row h-100 justify-content-center mt-5">
    <div class="col col-12 log-box">
      <div class="text-center">
        <mat-radio-group [(ngModel)]="documentType">
          <mat-radio-button (click)="updateDateFilter(filterForm.value)" [value]="1">Gesendet</mat-radio-button>
          <mat-radio-button (click)="updateDateFilter(filterForm.value)" [value]="2">Empfangen</mat-radio-button>
          <!-- <mat-radio-button [value]="3">Admission</mat-radio-button> -->
          <mat-radio-button *ngIf="displayRequests" [value]="4">Anfragen</mat-radio-button>
        </mat-radio-group>
      </div>
      <div class="row">
        <ng-container [formGroup]="filterForm" *ngIf="!loading && documentType != 4">
          <div class="col-2">
            <div class="form-group">
              <mat-form-field>
                <input
                  matInput
                  [matDatepicker]="fromDatepicker"
                  placeholder="von"
                  formControlName="fromDate"
                  (dateChange)="updateDateFilter(filterForm.value)"
                />
                <mat-datepicker-toggle matSuffix [for]="fromDatepicker"></mat-datepicker-toggle>
                <mat-datepicker #fromDatepicker></mat-datepicker>
              </mat-form-field>
            </div>
          </div>
          <div class="col-2">
            <div class="form-group">
              <mat-form-field>
                <input
                  matInput
                  [matDatepicker]="toDatepicker"
                  placeholder="bis"
                  formControlName="toDate"
                  (dateChange)="updateDateFilter(filterForm.value)"
                />
                <mat-datepicker-toggle matSuffix [for]="toDatepicker"></mat-datepicker-toggle>
                <mat-datepicker #toDatepicker></mat-datepicker>
              </mat-form-field>
            </div>
          </div>
        </ng-container>
      </div>
      <div class="col-2" *ngIf="loading">
        <i class="fa fa-spinner fa-spin fa-pulse"></i>
      </div>

      <div class="row" [hidden]="documentType != 1">
        <div class="col-12">
          <mat-form-field>
            <input matInput (keyup)="applyFilterToSent($event.target.value)" placeholder="Suche" />
          </mat-form-field>
          <!-- class="mat-elevation-z8" -->
          <mat-table #table [dataSource]="dataSource" matSortActive="CreatedOn" matSortDirection="desc" matSort>
            <ng-container matColumnDef="MessageTypeID">
              <mat-header-cell class="mat-header-cell-icon" *matHeaderCellDef mat-sort-header> </mat-header-cell>
              <mat-cell class="mat-cell-icon" *matCellDef="let element">
                <i class="fa fa-envelope" *ngIf="element.MessageTypeID == 0 || element.MessageTypeID == 300"></i>
                <i class="fa fa-address-book" *ngIf="element.MessageTypeID == 1 || element.MessageTypeID == 301"></i>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="RecipientName">
              <mat-header-cell *matHeaderCellDef mat-sort-header> Empfänger </mat-header-cell>
              <mat-cell *matCellDef="let element">
                {{ element.RecipientName }}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="CreatedOn">
              <mat-header-cell *matHeaderCellDef mat-sort-header> Gesendet am </mat-header-cell>
              <mat-cell *matCellDef="let element">
                {{ element.CreatedOn | date: 'dd.MM.yyyy HH:mm' }}
                {{ element.CreatedBy && element.CreatedBy.length === 2 ? '(' + element.CreatedBy + ')' : '' }}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="AffectedPersonTitle">
              <mat-header-cell *matHeaderCellDef mat-sort-header> Titel der/des Betroffenen </mat-header-cell>
              <mat-cell *matCellDef="let element">
                {{ element.AffectedPersonTitle || '' }}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="AffectedPersonFirstName">
              <mat-header-cell *matHeaderCellDef mat-sort-header> Vorname der/des Betroffenen </mat-header-cell>
              <mat-cell *matCellDef="let element">
                {{ element.AffectedPersonFirstName || '' }}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="AffectedPersonLastName">
              <mat-header-cell *matHeaderCellDef mat-sort-header> Nachname der/des Betroffenen </mat-header-cell>
              <mat-cell *matCellDef="let element">
                {{ element.AffectedPersonLastName || '' }}
              </mat-cell>
            </ng-container>

            <!-- <ng-container matColumnDef="StatusID">
              <mat-header-cell *matHeaderCellDef mat-sort-header> Status </mat-header-cell>
              <mat-cell *matCellDef="let element"> {{ element.StatusID }} </mat-cell>
            </ng-container> -->

            <ng-container matColumnDef="InternalNote">
              <mat-header-cell *matHeaderCellDef mat-sort-header> Interne Hinweise </mat-header-cell>
              <mat-cell *matCellDef="let element" [title]="element.InternalNote">
                {{
                  element.InternalNote?.length > 25
                    ? element.InternalNote.substring(0, 25) + '...'
                    : element.InternalNote
                }}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="TextStatus">
              <mat-header-cell *matHeaderCellDef mat-sort-header class="center"> SMS Status </mat-header-cell>
              <mat-cell *matCellDef="let element">
                <div *ngIf="element.SMSID">
                  <i class="fa fa-check" *ngIf="element.SMSStatusID == 1"></i>
                  <i class="fa fa-hourglass-half" *ngIf="element.SMSStatusID == 0"></i>
                  <i class="fa fa-times" *ngIf="element.SMSStatusID == -1"></i>
                </div>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="DownloadStatus" *ngIf="(screenSize$ | async) !== APP_SCREEN_SIZES.small">
              <mat-header-cell *matHeaderCellDef mat-sort-header class="center"> Download Status </mat-header-cell>
              <mat-cell *matCellDef="let element">
                <div class="dlIcon">
                  <i class="fa fa-check" title="Geöffnet" *ngIf="element.StatusID == 101"></i>
                  <i class="fa fa-hourglass-half" title="Empfangen" *ngIf="element.StatusID !== 101"></i>
                </div>
              </mat-cell>
            </ng-container>

            <mat-header-row
              *matHeaderRowDef="
                (screenSize$ | async) !== APP_SCREEN_SIZES.small ? displayedColumns : displayedColumnsMobile
              "
            ></mat-header-row>
            <mat-row
              *matRowDef="
                let row;
                columns: (screenSize$ | async) !== APP_SCREEN_SIZES.small ? displayedColumns : displayedColumnsMobile
              "
              (click)="selectDownloadEntry(row)"
            ></mat-row>
          </mat-table>
        </div>
      </div>

      <div class="row" [hidden]="documentType != 2">
        <div class="col-12">
          <mat-form-field>
            <input matInput (keyup)="applyFilterToReceived($event.target.value)" placeholder="Suche" />
          </mat-form-field>
          <!-- class="mat-elevation-z8" -->
          <mat-table
            #tableReceived
            [dataSource]="dataSourceReceived"
            matSortActive="CreatedOn"
            matSortDirection="desc"
            matSort
          >
            <ng-container matColumnDef="CreatedOn">
              <mat-header-cell *matHeaderCellDef mat-sort-header> Empfangen am </mat-header-cell>
              <mat-cell *matCellDef="let element">
                {{ element.CreatedOn | date: 'dd.MM.yyyy HH:mm' }}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="SenderPortalName">
              <mat-header-cell *matHeaderCellDef mat-sort-header> Sender </mat-header-cell>
              <mat-cell *matCellDef="let element">
                {{ element.SenderPortalName }}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="AffectedPersonTitle">
              <mat-header-cell *matHeaderCellDef mat-sort-header> Titel der/des Betroffenen </mat-header-cell>
              <mat-cell *matCellDef="let element">
                {{ element.AffectedPersonTitle || '' }}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="AffectedPersonFirstName">
              <mat-header-cell *matHeaderCellDef mat-sort-header> Vorname der/des Betroffenen </mat-header-cell>
              <mat-cell *matCellDef="let element">
                {{ element.AffectedPersonFirstName || '' }}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="AffectedPersonLastName">
              <mat-header-cell *matHeaderCellDef mat-sort-header> Nachname der/des Betroffenen </mat-header-cell>
              <mat-cell *matCellDef="let element">
                {{ element.AffectedPersonLastName || '' }}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="Status">
              <mat-header-cell *matHeaderCellDef mat-sort-header> Status </mat-header-cell>
              <mat-cell *matCellDef="let element">
                {{ element.RecipientComments || '' }}
              </mat-cell>
            </ng-container>

            <!-- <ng-container matColumnDef="StatusID">
              <mat-header-cell *matHeaderCellDef mat-sort-header> Status </mat-header-cell>
              <mat-cell *matCellDef="let element"> {{ element.StatusID }} </mat-cell>
            </ng-container> -->

            <mat-header-row *matHeaderRowDef="displayedColumnsReceived"></mat-header-row>
            <mat-row
              *matRowDef="let row; columns: displayedColumnsReceived"
              (click)="selectDownloadEntry(row)"
            ></mat-row>
          </mat-table>
        </div>
      </div>

      <div class="row" *ngIf="documentType == 4">
        <div class="col-12">
          <app-requests-view></app-requests-view>
        </div>
      </div>
    </div>
  </div>
</div>
