import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { PatientRequest } from '../../../../shared/classes/patient-request';

@Component({
  selector: 'app-requests-contact-list',
  templateUrl: './requests-contact-list.component.html',
  styleUrls: ['./requests-contact-list.component.css']
})
export class RequestsContactListComponent implements OnInit {
  @Input() requests: PatientRequest[];
  @Output() itemClicked = new EventEmitter();
  @ViewChild('table', { read: MatSort, static: true })
  sort: MatSort;
  public hasFetched = false;
  // public dataSource: MatTableDataSource<PatientRequest>; // = new MatTableDataSource(this.requests);
  public status = ['Offen', 'In Bearbeitung', 'Resultat Erhalten', 'Versendet'];
  public resultMap = {
    '1': 'pos',
    '2': 'neg',
    '3': 'verzögert',
    '4': 'zu wiederholen'
  };

  displayedColumns = ['reqdate', 'resdate', 'status', 'type', 'result'];

  constructor() {}

  ngOnInit(): void {
    // console.log(this.requests)
    // this.dataSource = new MatTableDataSource(this.requests);
    // this.dataSource.sort = this.sort;
  }

  ngOnChanges() {
    // if (!this.dataSource.data) {
    //   this.dataSource = new MatTableDataSource(this.requests);
    // } else {
    //   const today = new Date();
    //   const endDate = new Date();
    //   console.log(this.filter);
    //   if (this.filter === 'year') {
    //     endDate.setDate(today.getDate() - 365);
    //   } else if (this.filter === 'month') {
    //     endDate.setDate(today.getDate() - 31);
    //   } else if (this.filter === 'week') {
    //     endDate.setDate(today.getDate() - 7);
    //   } else if (this.filter === 'today') {
    //     endDate.setDate(today.getDate());
    //     endDate.setHours(0, 0, 0, 0);
    //   }
    //   this.dataSource.data = this.requests?.filter((item) => new Date(item.CreatedOn) >= endDate);
    //   console.log(this.requests);
    //   console.log(this.dataSource.data);
    // }
  }

  ngAfterViewInit() {}
}
