export class Favorite {
  id: any;
  Active: any;
  BxPortailID: any;
  City: any;
  ContactNo: any;
  CountryID: any;
  CreatedBy: any;
  CreatedOn: any;
  DateOfBirth: any;
  DefaultSendSMS: any;
  DefaultPW: any;
  EMail: any;
  EditedBy: any;
  EditedOn: any;
  ETresorTID: any;
  ETresorUID: any;
  FirstNameAr: any;
  FirstNameEn: any;
  GenderID: any;
  InsuranceNo: any;
  InsurancePrimary: any;
  IsSystemReferrer: any;
  JobTitle: any;
  LastNameAr: any;
  LastNameEn: any;
  MAPortailID: any;
  MaritalStatusID: any;
  MiddleNameAr: any;
  MiddleNameEn: any;
  Note: any;
  OrganisationID: any;
  OrganisationName: any;
  PIReferrerID: any;
  PISpecialityCode: any;
  PISpecialityID: any;
  PersonTitle: any;
  PersonalIDNo?: any;
  PersonalIDTYpeID?: any;
  PhoneHome: any;
  PhoneMobile: any;
  PhoneOffice: any;
  PortalID: any;
  Position: any;
  RelationTypeID: any;
  ReportTemplateName: any;
  Salutation: any;
  ServiceProviderTypeID: any;
  Speciality: any;
  StatusID: any;
  Street: any;
  TitleID: any;
  Website: any;
  ZIPCode: any;
  PlaceOfBirth: String;
  MAContactID: number;

  constructor(inputData) {
    this.id = inputData.id;
    this.Active = inputData.Active;
    this.BxPortailID = inputData.BxPortailID;
    this.City = inputData.City;
    this.ContactNo = inputData.ContactNo;
    this.CountryID = inputData.CountryID;
    this.CreatedBy = inputData.CreatedBy;
    this.CreatedOn = inputData.CreatedOn;
    this.DateOfBirth = inputData.DateOfBirth;
    this.DefaultPW = inputData.DefaultPW;
    this.DefaultSendSMS = inputData.DefaultSendSMS;
    this.EMail = inputData.EMail;
    this.EditedBy = inputData.EditedBy;
    this.EditedOn = inputData.EditedOn;
    this.ETresorTID = inputData.ETresorTID;
    this.ETresorUID = inputData.ETresorUID;
    this.FirstNameAr = inputData.FirstNameAr;
    this.FirstNameEn = inputData.FirstNameEn;
    this.GenderID = inputData.GenderID;
    this.IsSystemReferrer = inputData.IsSystemReferrer;
    this.InsuranceNo = inputData.InsuranceNo;
    this.InsurancePrimary = inputData.InsurancePrimary;
    this.JobTitle = inputData.JobTitle;
    this.LastNameAr = inputData.LastNameAr;
    this.LastNameEn = inputData.LastNameEn;
    this.MAPortailID = inputData.MAPortailID;
    this.MaritalStatusID = inputData.MaritalStatusID;
    this.MiddleNameAr = inputData.MiddleNameAr;
    this.MiddleNameEn = inputData.MiddleNameEn;
    this.Note = inputData.Note;
    this.OrganisationID = inputData.OrganisationID;
    this.OrganisationName = inputData.OrganisationName;
    this.PIReferrerID = inputData.PIReferrerID;
    this.PISpecialityCode = inputData.PISpecialityCode;
    this.PISpecialityID = inputData.PISpecialityID;
    this.PersonTitle = inputData.PersonTitle;
    this.PersonalIDNo = inputData.PersonalIDNo;
    this.PersonalIDTYpeID = inputData.PersonalIDTYpeID;
    this.PhoneHome = inputData.PhoneHome;
    this.PhoneMobile = inputData.PhoneMobile;
    this.PhoneOffice = inputData.PhoneOffice;
    this.PortalID = inputData.PortalID;
    this.Position = inputData.Position;
    this.RelationTypeID = inputData.RelationTypeID;
    this.ReportTemplateName = inputData.ReportTemplateName;
    this.Salutation = inputData.Salutation;
    this.ServiceProviderTypeID = inputData.ServiceProviderTypeID;
    this.Speciality = inputData.Speciality;
    this.StatusID = inputData.StatusID;
    this.Street = inputData.Street;
    this.TitleID = inputData.TitleID;
    this.Website = inputData.Website;
    this.ZIPCode = inputData.ZIPCode;
    this.PlaceOfBirth = inputData.PlaceOfBirth;
    this.MAContactID = inputData.MAContactID;
  }
}
