<div class="row">
  <h3>Kontakte</h3>
  <div class="col col-12 scroll-list">
    <mat-form-field>
        <input type="text" placeholder="Suche" matInput [(ngModel)]="searchName" name="searchName" (ngModelChange)="filterFavorites(searchName)"
      />
    </mat-form-field>
      
    <mat-list>
      <mat-list-item *ngFor="let favorite of filteredFavorites">
          <button mat-button class="w-100" [mat-dialog-close]="favorite">
              {{favorite.PersonTitle}} {{favorite.FirstNameEn}} {{favorite.LastNameEn}}
          </button>
      </mat-list-item>
      
    </mat-list>
  </div>
</div>