<div class="text-center">
  <div class="d-flex flex-row justify-content-between">
    <button mat-raised-button (click)="delete()">Stornieren</button>
    <div mat-dialog-close>
      <i class="fas fa-times hover-cursor"></i>
    </div>
  </div>

  <h2 mat-dialog-title>Anfragen-Status</h2>
  <div class="row">
    <!-- <div class="col col-10 offset-1">
      <button
        *ngIf="data.StatusID === 20"
        class="w-100"
        color="primary"
        mat-raised-button
        (click)="updateStatus(10)"
      >
        Erledigt
      </button>
      <button
        *ngIf="data.StatusID === 10"
        class="w-100"
        color="primary"
        mat-raised-button
        (click)="updateStatus(20)"
      >
        In Bearbeitung
      </button>
    </div> -->
    <div class="col col-10 offset-1">
      <mat-horizontal-stepper [linear]="false" #stepper [selectedIndex]="data.request.StatusID">
        <mat-step>
          <ng-template matStepLabel>Offen</ng-template>
          <div class="resultForm">
            <!-- <div class="row"> -->
            <div class="col-12 col-lg-6">
              <div class="resultRadios">
                <label mat-label>Art des Tests</label>
                <mat-radio-group [(ngModel)]="testTemplateName">
                  <mat-radio-button [value]="'LabPCRTest'">PCR Test</mat-radio-button>
                  <mat-radio-button [value]="'LabAntibodyTest'">Antik&ouml;rper Test</mat-radio-button>
                </mat-radio-group>
              </div>

              <mat-form-field>
                <input matInput [(ngModel)]="requestNo" placeholder="Test Nummer" pattern="\w{0,50}" maxlength="50" />
              </mat-form-field>
            </div>

            <div class="col-12 col-lg-6">
              <button mat-raised-button color="primary" (click)="updateStatus(1)">In Bearbeitung</button>
            </div>
          </div>
        </mat-step>
        <mat-step>
          <ng-template matStepLabel>In Bearbeitung</ng-template>

          <div class="resultForm">
            <!-- <div class="row"> -->
            <div class="col-12 col-lg-6">
              <button mat-raised-button (click)="updateStatus(0)">zur&uuml;ck zu Offen</button>
            </div>
            <div class="col-12 col-lg-6">
              <div class="resultRadios">
                <label mat-label>Ergebnis</label>
                <mat-radio-group [(ngModel)]="testResult">
                  <mat-radio-button [value]="1">Positiv</mat-radio-button>
                  <mat-radio-button [value]="2">Negativ</mat-radio-button>
                  <mat-radio-button [value]="3">Verzögert</mat-radio-button>
                  <mat-radio-button [value]="4">zu Wiederholen</mat-radio-button>
                </mat-radio-group>
              </div>
              <button [disabled]="!testResult" color="primary" (click)="updateStatus(2)" mat-raised-button>
                Resultat eintragen
              </button>
            </div>
            <!-- </div> -->
          </div>
        </mat-step>
        <mat-step>
          <ng-template matStepLabel>Resultat Erhalten</ng-template>

          <div class="resultForm mb-5">
            <button mat-raised-button (click)="updateStatus(1)" [disabled]="loading">Resultat &auml;ndern</button>
            <button *ngIf="!loading" color="primary" mat-raised-button (click)="send('BefundExpress')">
              Versenden
            </button>
            <button *ngIf="loading" color="primary" mat-raised-button disabled>
              <i class="fa fa-spinner fa-spin fa-pulse"></i>
            </button>
            <button
              *ngIf="!loading && accountLinked && showMAButton"
              class="maButton"
              mat-raised-button
              (click)="send('MeineAkte')"
            >
              An Meine Akte versenden
            </button>
            <button *ngIf="loading && accountLinked && showMAButton" class="maButton" mat-raised-button disabled>
              <i class="fa fa-spinner fa-spin fa-pulse"></i>
            </button>
          </div>
          <div class="form-group col col-12 alert alert-danger font-size-75p" *ngIf="!!errorMessage" role="alert">
            {{ errorMessage }}
          </div>
        </mat-step>
        <mat-step>
          <ng-template matStepLabel>Versendet</ng-template>
          <div class="resultForm mb-5">
            <button mat-raised-button color="primary" (click)="send('BefundExpress')">Erneut Versenden</button>
            <button mat-raised-button *ngIf="showMAButton" class="maButton" (click)="send('MeineAkte')">
              Erneut Versenden (Meine Akte)
            </button>
          </div>
        </mat-step>
      </mat-horizontal-stepper>
    </div>
  </div>
</div>
