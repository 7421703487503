<div class="text-center">
  <h2 mat-dialog-title>{{data.title}}</h2>
  <div class="row">
    <div class="col col-12">
      <div class="form-group">
        <div class="text--prewrap">{{data.message}}</div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col col-10 offset-1 mt-3">
      <div class="row">
        <div class="col-12 col-md-12">
          <button class="w-100" color="primary" (click)="data.buttonAction()" mat-raised-button>{{data.buttonLabel}}</button>
        </div>
      </div>
    </div>
  </div>
</div>