import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FavoritesService } from '../../../../../shared/services/favorites.service';
import { Favorite } from '../../../../../shared/classes/favorite';

@Component({
	selector: 'app-atrust-button',
	templateUrl: './atrust-button.component.html',
	styleUrls: [ './atrust-button.component.css' ]
})
export class AtrustButtonComponent implements OnInit {
	@Input() public label = 'atrust';
	@Output() public click = new EventEmitter<Date>();

	constructor(favoritesService: FavoritesService) {}

	ngOnInit() {}

	public buttonClicked(): void {
		this.click.emit(new Date());
	}
}
