import { Component, OnInit, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { FavoritesService } from '../../../shared/services/favorites.service';

@Component({
	selector: 'app-favorites-dialog',
	templateUrl: './favorites-dialog.component.html',
	styleUrls: [ './favorites-dialog.component.css' ]
})
export class FavoritesDialogComponent implements OnInit {
	private favorites;
	public filteredFavorites;
	public searchName;

	constructor(@Inject(MAT_DIALOG_DATA) public data: any, private favoritesService: FavoritesService) {
		this.favorites = Object.values(data);
		this.filteredFavorites = this.favorites.slice(0,25);
	}

	ngOnInit() {}

	public getFavorites() {
		return this.favoritesService.getFavorites();
	}

	public filterFavorites(searchText) {
		const search = searchText.replace(/ /g, ''); // Filter out whitespaces not correct fix
		console.log(search);
		const filtered = this.favorites.filter((item) => {
			return !searchText || (item.FirstNameEn + item.LastNameEn).toLowerCase().indexOf(search.toLowerCase()) > -1;
		});
		this.filteredFavorites = filtered.slice(0,25);
		return filtered;
	}
}
