<div class="row">
  <div class="profile-box  col-12 mt-4">
    <h4>Schnittstellen - Benutzer</h4>
    <label class="text--info">(zur Anbindung an Care01, docFinder und anderen Anbietern)</label>
    <div class="row">
      <div class="col col-12 col-md-12">
        <div class="form-group">
          <app-connected-accounts></app-connected-accounts>
        </div>
      </div>
      <hr />
    </div>
    <div class="row" *ngIf="!hasAdmissionAccount()">
      <div class="col col-12 col-md-6">
        <div class="form-group">
          <label class="text--info">Mit Care01 - Admission Account verbinden</label>
          <button mat-raised-button class="w-100" color="primary" *ngIf="!saveInProgress"
            (click)="showAdmissionDialog()">
            Verbinden
          </button>
          <button class="w-100" color="primary" mat-raised-button disabled *ngIf="saveInProgress">
            <i class="fa fa-spinner fa-spin fa-pulse"></i>
          </button>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="apiUser?.UserId">
      <div class="col col-12" *ngIf="apiUser.PW">
        <label class="text--info">Bitte notieren Sie das Passwort für ihren Schnittstellen - Benutzer, da es Ihnen nur
          einmalig angezeigt wird.</label>
      </div>
      <div class="col col-12 col-md-6">
        <div class="form-group">
          <label class="text--info">Benutzername</label>
          <div>{{apiUser.UserId}}</div>
        </div>
      </div>
      <div class="col col-12 col-md-6" *ngIf="apiUser.PW">
        <label class="text--info">Passwort</label>
        <div>{{apiUser.PW}}</div>
      </div>
      
    </div>
    <div class="row" *ngIf="apiUser?.UserId">
      <div class="col col-12 col-md-6" *ngIf="!apiUser.PW">
        <div class="form-group">
          <button mat-raised-button class="w-100" color="primary" *ngIf="!saveInProgress"
            (click)="showChangePWDialog()">
            Neues Passwort generieren
          </button>
          <button class="w-100" color="primary" mat-raised-button disabled *ngIf="saveInProgress">
            <i class="fa fa-spinner fa-spin fa-pulse"></i>
          </button>
        </div>
      </div>
      <div class="col col-12 col-md-6" *ngIf="!apiUser.PW">
        <div class="form-group">
          <button mat-raised-button class="w-100" color="primary" *ngIf="!saveInProgress" (click)="showDeleteDialog()">
            Benutzer löschen
          </button>
          <button class="w-100" color="primary" mat-raised-button disabled *ngIf="saveInProgress">
            <i class="fa fa-spinner fa-spin fa-pulse"></i>
          </button>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="!apiUser?.UserId">
      <div class="col col-12 col-md-6">
        <div class="form-group">
          <button mat-raised-button class="w-100" color="primary" *ngIf="!saveInProgress" (click)="createApiUser()">
            Benutzer erstellen
          </button>
          <button class="w-100" color="primary" mat-raised-button disabled *ngIf="saveInProgress">
            <i class="fa fa-spinner fa-spin fa-pulse"></i>
          </button>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="tokenUsers?.length">
      <div class="col col-12">
        <h4>Aktive Verbindungen</h4>
      </div>
    </div>
    <div class="row" *ngIf="tokenUsers?.length">
      <div class="col col-12">
        <div class="row" *ngFor="let tokenUser of tokenUsers; let last = last">
          <div class="col col-6">
            <!-- <div class="form-group"> -->
              <!-- <label class="text--info">App</label> -->
              <div class="lh-36">{{tokenUser.APIName}}</div>
            <!-- </div> -->
          </div>
          <div class="col col-6">
            <!-- <div class="form-group"> -->
              <button mat-button class="w-100" color="primary" *ngIf="!tokenUser.clickDisabled"
                (click)="showDeleteBYAPIIDDialog(tokenUser)">
                Entfernen
              </button>
              <button class="w-100" color="primary" mat-button disabled *ngIf="tokenUser.clickDisabled">
                <i class="fa fa-spinner fa-spin fa-pulse"></i>
              </button>
            <!-- </div> -->
          </div>
          
            <div class="col col-6" *ngIf="hasAdmissionAccount()">
              <!-- <div class="form-group"> -->
                <!-- <label class="text--info">App</label> -->
                <div class="lh-36">Care01 - Admission</div>
              <!-- </div> -->
            </div>
            <div class="col col-6" *ngIf="hasAdmissionAccount()">
              <!-- <div class="form-group"> -->
                <button mat-button class="w-100" color="primary"
                  (click)="showDeleteAdmissionDialog()">
                  Entfernen
                </button>
                
              <!-- </div> -->
            </div>
          <div class="col col-12">
            <mat-divider *ngIf="!last"></mat-divider>
          </div>
        </div>
        
      </div>
    </div>
  </div>

</div>