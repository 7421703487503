<div *ngIf="smsStats?.length > 0">
  <mat-table [dataSource]="smsStats">
    <ng-container matColumnDef="month">
      <mat-header-cell *matHeaderCellDef>Monat</mat-header-cell>
      <mat-cell class="description-cell" *matCellDef="let entry">{{ months[entry.M - 1] }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="year">
      <mat-header-cell *matHeaderCellDef>Jahr</mat-header-cell>
      <mat-cell class="duration-cell" *matCellDef="let entry">{{ entry.Y }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="amount">
      <mat-header-cell *matHeaderCellDef>Versendete SMS</mat-header-cell>
      <mat-cell *matCellDef="let entry">{{ entry.Anz }} / {{ limit }} </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>

    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
  </mat-table>
</div>
<div *ngIf="smsStats?.length === 0">
  Keine SMS vorhanden
</div>
