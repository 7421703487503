import { Component, OnInit, Inject } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { XConfigShortcode } from '../../../shared/classes/xconfig-shortcode';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { ShortcodeService } from '../../../shared/services/shortcode.service';
import { ConfirmDialogComponent } from '../../../shared/components/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-text-block-detail',
  templateUrl: './text-block-detail.component.html',
  styleUrls: ['./text-block-detail.component.css']
})
export class TextBlockDetailComponent implements OnInit {
  form: UntypedFormGroup;
  shortCodeObject: XConfigShortcode;
  public editMode = false;

  constructor(
    private fb: UntypedFormBuilder,
    public dialogRef: MatDialogRef<TextBlockDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private shortCodeService: ShortcodeService,
    private dialog: MatDialog
  ) {
    if (data) {
      this.editMode = true;
      this.shortCodeObject = data;
    } else {
      this.shortCodeObject = new XConfigShortcode({
        ShortcodeKey: '',
        ShortcodeText: ''
      });
    }
  }

  ngOnInit() {
    console.log(this.shortCodeObject);
    this.form = this.fb.group({
      shortcutKey: [this.shortCodeObject.ShortcutKey, []],
      shortcutText: [this.shortCodeObject.ShortcutText, []]
    });
  }

  async delete() {
    // if(confirm('Wollen Sie den Textblock tatsächlich löschen?')){
    //   await this.shortCodeService.delete(this.shortCodeObject);
    //   this.dialogRef.close();
    // }
    if(this.dialog.openDialogs.some(openDialog => openDialog.componentInstance instanceof ConfirmDialogComponent)){
			return;
		  }
    const dialogRef2 = this.dialog.open(ConfirmDialogComponent, {
      width: '500px',
      data: `Sind Sie sicher, dass sie den Textblock für das Kürzel ${
        this.shortCodeObject.ShortcutKey
      } löschen wollen?`
    });

    dialogRef2.afterClosed().subscribe(async result => {
      if (result) {
        await this.shortCodeService.delete(this.shortCodeObject);
        console.log('deleted');
        this.dialogRef.close();
      }
    });
  }

  close() {
    this.dialogRef.close();
  }

  async save() {
    if (this.editMode) {
      await this.shortCodeService.update(this.data, this.shortCodeObject);
      console.log('Updated');
      this.dialogRef.close();

    } else {
      await this.shortCodeService.insert(this.shortCodeObject);
      console.log('Added');
      this.dialogRef.close();
    }
    this.dialogRef.close(this.form.value);
  }
}
