<div [formGroup]="form">
  <div [ngClass]="(screenSize$ | async) !== APP_SCREEN_SIZES.small ? 'form-container' : 'mobile-container'">
    <div class="row">
      <div class="col-12 col-lg-3">
        <mat-form-field>
          <input matInput minlength="1" placeholder="Straße" formControlName="street" />
        </mat-form-field>
      </div>
      <div class="col-12 col-lg-3">
        <mat-form-field>
          <input
            matInput
            minlength="1"
            placeholder="PLZ"
            formControlName="zipCode"
            (focusout)="checkZipCode(form.get('zipCode').value)"
          />
        </mat-form-field>
      </div>
      <div class="col-12 col-lg-3">
        <mat-form-field>
          <input matInput minlength="1" placeholder="Stadt" formControlName="city" />
        </mat-form-field>
      </div>
      <div class="col-12 col-lg-3">
        <mat-form-field>
          <mat-select placeholder="Land" formControlName="countryID">
            <mat-option *ngFor="let country of countries" [value]="country.id">{{ country.CountryNameDe }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12 col-lg-4">
      <mat-form-field>
        <mat-label>Geburtsdatum</mat-label>
        <input matInput formControlName="dateOfBirth" [matDatepicker]="picker" />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
    </div>
    <div class="col-12 col-lg-4">
      <mat-form-field>
        <input matInput minlength="1" placeholder="Versicherungsnummer" formControlName="insuranceNumber" />
      </mat-form-field>
    </div>
    <div class="col-12 col-lg-4">
      <mat-form-field>
        <mat-select placeholder="Versicherung" formControlName="insurance">
          <mat-option></mat-option>
          <mat-option *ngFor="let insurance of insurances" [value]="insurance.code">
            {{ insurance.kurzbezeichnung }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <div class="row">
    <div class="col-12 col-lg-4">
      <mat-form-field>
        <input matInput minlength="1" placeholder="Geburtsort" formControlName="placeOfBirth" />
      </mat-form-field>
    </div>
    <div class="col-12 col-lg-4">
      <mat-form-field>
        <input matInput minlength="1" placeholder="Passnummer" formControlName="personalIDNo" />
      </mat-form-field>
    </div>
  </div>
</div>
