import { Injectable } from "@angular/core";
import { AzureServiceService } from "../../../shared/services/azure-service.service";
@Injectable()
export class ProfileService {
  constructor(private azureClient: AzureServiceService) {}
  private userTable = this.azureClient.client.getTable("XSecUser");

  public async get() {
    const userResult = await this.userTable.read();
    return userResult;
  }

  public async update(userData) {
    const updateResult = await this.userTable.update(userData);
    return updateResult;
  }

  public async changePassword(userData) {
    const result = await this.userTable.update({
      id: userData.id,
      PW: userData.PW,
      NewPW: userData.NewPW
    });
    return result;
  }
}
