import { Component, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';

import { ApiUserService } from '../services/api-user.service';
import { ConfirmDialogComponent } from '../../../shared/components/confirm-dialog/confirm-dialog.component';
import { ApiDetailComponent } from './api-detail/api-detail.component';
import { AdmissionService } from '../services/admission.service';

@Component({
	selector: 'app-api-user',
	templateUrl: './api-user.component.html',
	styleUrls: [ './api-user.component.css' ],
	providers: [ ApiUserService ]
})
export class ApiUserComponent implements OnInit {
	apiUser: any;
	saveInProgress = false;
	tokenUsers: any[];
	constructor(
		private apiUserService: ApiUserService,
		private dialog: MatDialog,
		private admissionService: AdmissionService
	) {}

	async ngOnInit() {
		try {
			const apiUserRes = await this.apiUserService.get();
			this.apiUser = apiUserRes.apiUser;
			this.tokenUsers = apiUserRes.tokenUsers;
		} catch (err) {
			console.log(err);
		}
	}

	async createApiUser() {
		try {
			this.saveInProgress = true;
			const newApiUser = await this.apiUserService.insert();
			this.apiUser = newApiUser;
			return newApiUser;
		} catch (err) {
			console.log(err);
		} finally {
			this.saveInProgress = false;
		}
	}

	hasAdmissionAccount() {
		return this.admissionService.hasAccount;
	}

	showChangePWDialog() {
		if(this.dialog.openDialogs.some(openDialog => openDialog.componentInstance instanceof ConfirmDialogComponent)){
			return;
		  }
		const dialogRef = this.dialog.open(ConfirmDialogComponent, {
			width: '500px',
			data:
				'Wenn Sie das Passwort ändern, müssen Sie dieses bei allen aktuell verbundenen Anbietern auch anpassen.' +
				'\r\nSind Sie sicher, dass Sie das Passwort für Ihren API - Benutzer ändern wollen?'
		});

		dialogRef.afterClosed().subscribe((result) => {
			if (result) {
				this.changeApiUserPW(this.apiUser);
			}
		});
	}

	async changeApiUserPW(currentUser) {
		try {
			this.saveInProgress = true;
			const changePWRes = await this.apiUserService.changePassword(currentUser.UserId);
			this.apiUser = changePWRes;
			return changePWRes;
		} catch (err) {
			console.log(err);
		} finally {
			this.saveInProgress = false;
		}
	}

	showAdmissionDialog() {
		if(this.dialog.openDialogs.some(openDialog => openDialog.componentInstance instanceof ApiDetailComponent)){
			return;
		  }
		const dialogRef = this.dialog.open(ApiDetailComponent, {
			width: '500px'
		});

		dialogRef.afterClosed().subscribe((result) => {
			if (result) {
				this.changeApiUserPW(this.apiUser);
			}
		});
	}

	async showDeleteAdmissionDialog() {
		if(this.dialog.openDialogs.some(openDialog => openDialog.componentInstance instanceof ConfirmDialogComponent)){
			return;
		  }
		const dialogRef = this.dialog.open(ConfirmDialogComponent, {
			width: '500px',
			data:
				'Wenn Sie das diesen Benutzer löschen ist eine Anbindung über andere Anbieter nicht mehr möglich.' +
				'\r\nSind Sie sicher, dass Sie ihren Schnittstellen - Benutzer löschen wollen?'
		});

		dialogRef.afterClosed().subscribe(async (result) => {
			if (result) {
				await this.admissionService.removeAccount();
			}
		});
	}

	showDeleteDialog() {
		if(this.dialog.openDialogs.some(openDialog => openDialog.componentInstance instanceof ConfirmDialogComponent)){
			return;
		  }
		const dialogRef = this.dialog.open(ConfirmDialogComponent, {
			width: '500px',
			data:
				'Wenn Sie das diesen Benutzer löschen ist eine Anbindung über andere Anbieter nicht mehr möglich.' +
				'\r\nSind Sie sicher, dass Sie ihren Schnittstellen - Benutzer löschen wollen?'
		});

		dialogRef.afterClosed().subscribe((result) => {
			if (result) {
				this.deleteApiUser(this.apiUser);
			}
		});
	}

	async deleteApiUser(currentUser) {
		try {
			this.saveInProgress = true;
			const deleteRes = await this.apiUserService.delete(currentUser.UserId);
			this.apiUser = {};
			return deleteRes;
		} catch (err) {
			console.log(err);
		} finally {
			this.saveInProgress = false;
		}
	}

	showDeleteBYAPIIDDialog(tokenUser) {
		if(this.dialog.openDialogs.some(openDialog => openDialog.componentInstance instanceof ConfirmDialogComponent)){
			return;
		  }
		const dialogRef = this.dialog.open(ConfirmDialogComponent, {
			width: '500px',
			data: `Sind Sie sicher, dass Sie die Verbinung zu ${tokenUser.APIName} entfernen wollen?`
		});

		dialogRef.afterClosed().subscribe((result) => {
			if (result) {
				this.deleteByAPIID(tokenUser);
			}
		});
	}

	async deleteByAPIID(tokenUser: any) {
		try {
			tokenUser.clickDisabled = true;
			const deleteRes = await this.apiUserService.deleteByAPIID(tokenUser.APIID);
			// this.apiUser = {};
			this.tokenUsers = this.tokenUsers.filter((item) => item.APIID !== tokenUser.APIID);
			return deleteRes;
		} catch (err) {
			console.log(err);
		} finally {
			this.saveInProgress = false;
		}
	}
}
