<form #sendFileForm="ngForm" (ngSubmit)="sendFile(sendFileForm)" *ngIf="fileSentStatus < 2">
  <div class="form-group" *ngIf="!uploadData.filesToReSend">
    <app-file-uploader
      [maxFileAmount]="8"
      [maxFileSize]="10 * 1024 * 1024"
      (fileAddedEvt)="fileAdd($event)"
    ></app-file-uploader>
  </div>
  <div
    class="col col-10 offset-1 text-center"
    style="margin: 0px;"
    *ngIf="getFilesToSend() && !uploadData.signedFiles && !uploadData.filesToReSend"
  >
    <mat-list dense>
      <mat-list-item *ngFor="let file of getFilesToSend(); index as index" class="font-size-90p">
        <div class="remove-file" (click)="removeFile(index, 'normal')">
          <i class="fa fa-times"></i>
        </div>
        <span class="hover-cursor">{{ file?.name }}</span>
      </mat-list-item>
    </mat-list>
  </div>
  <div
    class="col col-10 offset-1 text-center"
    style="margin: 0px;"
    *ngIf="uploadData.signedFiles && !uploadData.filesToReSend"
  >
    <mat-list dense>
      <mat-list-item *ngFor="let file of uploadData.signedFiles; index as index" class="font-size-90p">
        <div class="remove-file" (click)="removeFile(index, 'signed')">
          <i class="fa fa-times"></i>
        </div>
        <span>{{ file?.fileName }} </span>
        <i style="color: green;" class="fa fa-check"></i>
      </mat-list-item>
    </mat-list>
  </div>
  <div class="col col-10 offset-1 text-center" style="margin: 0px;" *ngIf="uploadData.filesToReSend">
    <mat-list dense>
      <mat-list-item *ngFor="let file of uploadData.filesToReSend; index as index" class="font-size-90p">
        <div class="remove-file" (click)="removeFile(index, 'reSend')">
          <i class="fa fa-times"></i>
        </div>
        <span>{{ file?.fileName }} </span>
      </mat-list-item>
    </mat-list>
  </div>
  <!-- <mat-checkbox
    [(ngModel)]="signDocuments"
    name="signDocuments"
    [disabled]="
      (!getFilesToSend() && !signDocuments) ||
      (!uploadData.signedFiles && signDocuments)
    "
    (click)="signAll()"
    >Dateien Signieren</mat-checkbox
  > -->

  <div class="col col-12 text-center hover--pointer recipient" (click)="showPortalFilterDialog()">
    <span>Empfänger:</span>
    <span>{{ uploadData.recipientPortal?.Name || '- bitte auswählen -' }}</span>
    <!-- <input type="text" placeholder="Empfänger" matInput [(ngModel)]="uploadData.recipientPortal.Name" required /> -->
  </div>

  <!-- <mat-form-field hideRequiredMarker>
    <input type="text" placeholder="Empfänger" matInput [(ngModel)]="uploadData.recipientPortal" name="recipientPortal" (ngModelChange)="filterPortals($event, portalTypeFilter)"
      [matAutocomplete]="autoRecipient" (focusout)="focusOutPortalSelect(uploadData.recipientPortal)" #recipientPortal="ngModel"
      required>
    <mat-error *ngIf="recipientPortal?.errors && recipientPortal?.touched">Bitte wählen Sie einen Empfänger aus der Liste.</mat-error>
  </mat-form-field>
  <mat-autocomplete #autoRecipient="matAutocomplete" [displayWith]="displayFn">
    <mat-option *ngFor="let portal of filteredPortals" [value]="portal">{{portal?.Name}}</mat-option>
  </mat-autocomplete> -->

  <!-- <mat-form-field>
    <textarea matInput placeholder="Hinweise" [(ngModel)]="uploadData.senderComments" maxlength="2000" name="senderComments"
      matTextareaAutosize matAutosizeMinRows="2" matAutosizeMaxRows="5"></textarea>
  </mat-form-field> -->

  <mat-form-field>
    <mat-select
      placeholder="Titel der/des Betroffenen"
      [(ngModel)]="uploadData.patientTitle"
      name="patientTitle"
      #patientTitle="ngModel"
    >
      <mat-option></mat-option>
      <mat-option *ngFor="let title of personTitles" [value]="title">
        {{ title }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field>
    <input
      type="text"
      matInput
      placeholder="Vorname der/des Betroffenen"
      [(ngModel)]="uploadData.patientFirstName"
      #patientFirstName="ngModel"
      name="patientFirstName"
    />
  </mat-form-field>
  <mat-form-field>
    <input
      type="text"
      matInput
      placeholder="Nachname der/des Betroffenen"
      [(ngModel)]="uploadData.patientLastName"
      #patientLastName="ngModel"
      name="patientLastName"
    />
  </mat-form-field>
  <mat-form-field>
    <textarea
      matInput
      placeholder="Interne Hinweise (nicht für Empfänger ersichtlich)"
      [(ngModel)]="uploadData.internalNote"
      maxlength="2000"
      name="internalNote"
      matTextareaAutosize
      matAutosizeMinRows="2"
      matAutosizeMaxRows="5"
      shortcut
    ></textarea>
  </mat-form-field>
  <div class="button-row col col-10 offset-1 mt-3">
    <button
      *ngIf="fileSentStatus == 0"
      mat-raised-button
      class="w-100"
      color="primary"
      [disabled]="
        !sendFileForm.form.valid ||
        (!getFilesToSend() && !uploadData.signedFiles && !uploadData.filesToReSend) ||
        !uploadData.recipientPortal
      "
    >
      Senden
    </button>
    <button class="w-100" color="primary" mat-raised-button disabled *ngIf="fileSentStatus == 1">
      <i class="fa fa-spinner fa-spin fa-pulse"></i>
    </button>
  </div>
</form>
<div class="text-center" *ngIf="fileSentStatus == 2">
  <div class="form-group col col-12 alert alert-success" role="alert">
    Datei wurde erfolgreich versendet!
  </div>
  <button mat-button class="w-100" color="primary" (click)="resetFileSentStatus()">
    Zurück
  </button>
</div>
