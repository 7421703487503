import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
// Depending on whether rollup is used, moment needs to be imported differently.
// Since Moment.js doesn't have a default export, we normally need to import using the `* as`
// syntax. However, rollup creates a synthetic default module and we thus need to import it using
// the `default as` syntax.
import * as _moment from 'moment';
// tslint:disable-next-line:no-duplicate-imports
import { default as _rollupMoment } from 'moment';
import { Observable } from 'rxjs';
import { SVTInsurances } from 'src/app/shared/collections/svt-insurances';
import { Favorite } from '../../../shared/classes/favorite';
import { ApplicationStateService, APP_SCREEN_SIZES } from '../../../shared/services/application-state.service';
import { FavoritesService } from '../../../shared/services/favorites.service';

const moment = _rollupMoment || _moment;

// See the Moment.js docs for the meaning of these formats:
// https://momentjs.com/docs/#/displaying/format/
export const MY_FORMATS = {
  parse: {
    dateInput: 'DD.MM.YYYY'
  },
  display: {
    dateInput: 'DD.MM.YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  }
};

@Component({
  selector: 'app-favorites-extension',
  templateUrl: './favorites-extension.component.html',
  styleUrls: ['./favorites-extension.component.css'],
  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }
  ]
})
export class FavoritesExtensionComponent implements OnInit {
  @Input() favorite: Favorite;
  @Input() form: UntypedFormGroup;
  @Input() editMode: boolean;
  screenSize$: Observable<string>;
  APP_SCREEN_SIZES = APP_SCREEN_SIZES;
  public insurances = SVTInsurances;

  countries: any[];
  cities: any[];

  constructor(private appStateService: ApplicationStateService, private favoritesService: FavoritesService) {}

  async ngOnInit() {
    this.screenSize$ = this.appStateService.screenSize$;
    this.initForm();
    this.countries = await this.favoritesService.getCountries();
    this.cities = await this.favoritesService.getCities();
  }

  private initForm(): void {
    this.form.addControl('street', new UntypedFormControl(this.favorite.Street || ''));
    this.form.addControl('zipCode', new UntypedFormControl(this.favorite.ZIPCode || ''));
    this.form.addControl('city', new UntypedFormControl(this.favorite.City || ''));
    this.form.addControl('countryID', new UntypedFormControl(this.favorite.CountryID || ''));
    this.form.addControl('dateOfBirth', new UntypedFormControl(this.favorite.DateOfBirth || ''));
    this.form.addControl('insuranceNumber', new UntypedFormControl(this.favorite.InsuranceNo || ''));
    this.form.addControl('insurance', new UntypedFormControl(this.favorite.InsurancePrimary || ''));
    this.form.addControl('placeOfBirth', new UntypedFormControl(this.favorite.PlaceOfBirth || ''));
    this.form.addControl('personalIDNo', new UntypedFormControl(this.favorite.PersonalIDNo || ''));
  }

  checkZipCode(zip) {
    // const zip = this.form.value.zipCode;
    const city = this.cities.find((city) => city.ZIPCode == zip);
    if (city) {
      this.form.controls['city'].setValue(city.CityNameDe);
    }
  }
}
