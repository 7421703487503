<div class="container h-100">
    <div class="row justify-content-center align-items-center h-100">
      <div class="login-box col col-12">
        <h2>Admission Login</h2>
        <form #loginForm="ngForm" (ngSubmit)="loginClick($event)">
          <mat-form-field hideRequiredMarker class="form-group">
            <input matInput placeholder="Benutzername" [(ngModel)]="username" required name="username" #usernameModel="ngModel">
          </mat-form-field>
  
          <mat-form-field hideRequiredMarker class="form-group">
            <input type="password" matInput placeholder="Passwort" [(ngModel)]="password" required name="password" #passwordModel="ngModel"
            />
          </mat-form-field>
          <mat-error class="text-center" *ngIf="errorMessage">{{errorMessage}}</mat-error>
          <div class="button-row col col-10 offset-1 mt-3">
            <button mat-raised-button class="w-100" color="primary" *ngIf="!loginPending" [disabled]="!loginForm.form.valid">Login</button>
            <button class="w-100" color="primary" mat-raised-button disabled *ngIf="loginPending">
                <i class="fa fa-spinner fa-spin fa-pulse"></i>
              </button>
          </div>
          <!-- <div class="button-row col col-10 offset-1">
            <a mat-button class="w-100" color="primary" routerLink="/passwordReset">Passwort vergessen?</a>
          </div> -->
        </form>
      </div>
    </div>
  </div>