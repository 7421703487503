import { Injectable } from "@angular/core";
import { AzureServiceService } from "./azure-service.service";
import { environment } from '../../../environments/environment';
import { HttpClient } from "@angular/common/http";

interface IOAuthRedirect {
  url: string
}
export interface IConnectedAccount {
  AccountType: string
}

export interface IConnectedAccountsLoginResponse {
  action: 'login',
  login: {
    token: string,
    user: any,
    culture: string
  }
}
export interface IConnectedAccountsRegisterResponse {
  action: 'register',
  registrationToken: string,
  profile: {
    email: string,
    first_name: string,
    last_name: string,
    gender: 'm' | 'f' | 'u',
    uuid: string,
    academic_title?: string,
    specialization: string,
    ma_number: string
  }
}
export type IConnectedAccountsResponse = IConnectedAccountsLoginResponse | IConnectedAccountsRegisterResponse;

@Injectable()
export class ConnectedAccountsService {
  constructor(private azureService: AzureServiceService, private http: HttpClient) {

  }
  async getRedirect(accountType: string, action: 'login' | 'connect') {
    return await this.http.get<IOAuthRedirect>(`${environment.azureAppURL}/api/connected-accounts/${accountType}/redirect`, {
      params: { env: environment.name, action },
      headers: action == 'connect' ? {
        'ZUMO-API-VERSION': '2.0.0',
        'X-ZUMO-AUTH': this.azureService.client.currentUser.mobileServiceAuthenticationToken
      } : { }
    }).toPromise();
  }
  async connect(accountType: string, params: { state: string, code: string }) {
    if(!this.azureService.checkLogin()) throw new Error("Need to be logged in to connect accounts");
    return await this.http.post<void>(`${environment.azureAppURL}/api/connected-accounts/${accountType}/connect`,
    params,
    {
      headers: {
        'ZUMO-API-VERSION': '2.0.0',
        'X-ZUMO-AUTH': this.azureService.client.currentUser.mobileServiceAuthenticationToken
      }
    }).toPromise();
  }
  async login(accountType: string, params: { state: string, code: string }) {
    return await this.http.post<IConnectedAccountsResponse>(`${environment.azureAppURL}/api/connected-accounts/${accountType}/login`, params).toPromise();
  }

  async getAccountTypes() {
    return await this.http.get<string[]>(`${environment.azureAppURL}/api/connected-accounts/types`, {
      headers: {
        'ZUMO-API-VERSION': '2.0.0',
        'X-ZUMO-AUTH': this.azureService.client.currentUser.mobileServiceAuthenticationToken
      }
    }).toPromise();
  }
  async getConnectedAccounts() {
    return await this.http.get<IConnectedAccount[]>(`${environment.azureAppURL}/api/connected-accounts/connected`, {
      headers: {
        'ZUMO-API-VERSION': '2.0.0',
        'X-ZUMO-AUTH': this.azureService.client.currentUser.mobileServiceAuthenticationToken
      }
    }).toPromise();
  }
  async unlinkConnectedAccount(accountType: string) {
    return await this.http.post<void>(`${environment.azureAppURL}/api/connected-accounts/${accountType}/unlink`,
    null,
    {
      headers: {
        'ZUMO-API-VERSION': '2.0.0',
        'X-ZUMO-AUTH': this.azureService.client.currentUser.mobileServiceAuthenticationToken
      }
    }).toPromise();
  }
}
