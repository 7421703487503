<div class="container pricing">
  <div class="pricing-box col-12 mt-4">
    <h2 class="pricing-title text-center">Unser Angebot</h2>
    <h3 #bxLabel id="bxLabel">BefundExpress+</h3>
    <div class="row">
      <div #pBox id="pBox" class="col-lg-15 col-md-15 mb-5 mb-md-0 pricebox">
        <div class="top">
          <div class="name">FREE</div>
          <div class="price">0€</div>
          <div class="disclaimer">pro Monat</div>
        </div>
        <div class="bottom">
          <div class="bulletpoint">
            <span class="perks">99 Cent pro SMS</span>
          </div>
          <div class="bulletpoint">
            <span class="perks">Daten 1 Woche abrufbar</span>
          </div>
          <br />
        </div>
        <div id="freeBtn" class="buttonField active">
          <span [innerText]="userSettings.freeText"></span>
        </div>
      </div>

      <div class="col-lg-15 col-md-15 mb-5 mb-md-0 pricebox">
        <div class="top">
          <div class="name">START</div>
          <div class="price">11€</div>
          <div class="disclaimer">pro Monat</div>
        </div>
        <div class="bottom">
          <div class="bulletpoint">
            <span class="perks">15 SMS monatlich**</span>
          </div>
          <div class="bulletpoint">
            <span class="perks">Daten 3 Monate abrufbar</span>
          </div>
          <br />
        </div>
        <div #startBtn id="startBtn" class="buttonField available">
          <div *ngIf="aboType === 0" (click)="upgrade(4)">
            <span [innerText]="userSettings.startText"></span>
          </div>
          <div *ngIf="aboType != 0">
            <span [innerText]="userSettings.startText"></span>
          </div>
        </div>
      </div>

      <div class="col-lg-15 col-md-15 mb-5 mb-md-0 pricebox">
        <div class="top">
          <div class="name">MINI</div>
          <div class="price">22€</div>
          <div class="disclaimer">pro Monat</div>
        </div>
        <div class="bottom">
          <div class="bulletpoint">
            <span class="perks">50 SMS monatlich*</span>
          </div>
          <div class="bulletpoint">
            <span class="perks">Daten 1 Jahr abrufbar</span>
          </div>
          <br />
        </div>
        <div #miniBtn id="miniBtn" class="buttonField available">
          <div *ngIf="aboType === 0 || aboType === 4" (click)="upgrade(1)">
            <span [innerText]="userSettings.miniText"></span>
          </div>
          <div *ngIf="aboType !== 0 && aboType !== 4">
            <span [innerText]="userSettings.miniText"></span>
          </div>
        </div>
      </div>
      <div class="col-lg-15 col-md-15 mb-5 mb-md-0 pricebox">
        <div class="top">
          <div class="name">BASIC</div>
          <div class="price">33€</div>
          <div class="disclaimer">pro Monat</div>
        </div>
        <div class="bottom">
          <div class="bulletpoint">
            <span class="perks">120 SMS monatlich*</span>
          </div>
          <div class="bulletpoint">
            <span class="perks">Daten 7 Jahre abrufbar</span>
          </div>
          <br />
        </div>
        <div #basicBtn id="basicBtn" class="buttonField available">
          <div *ngIf="aboType < 2 || aboType === 4" (click)="upgrade(2)">
            <span [innerText]="userSettings.basicText"></span>
          </div>
          <div *ngIf="aboType >= 2 && aboType != 4">
            <span [innerText]="userSettings.basicText"></span>
          </div>
        </div>
      </div>
      <div class="col-lg-15 col-md-15 mb-5 mb-md-0 pricebox">
        <div class="top">
          <div class="name">PLUS</div>
          <div class="price">44€</div>
          <div class="disclaimer">pro Monat</div>
        </div>
        <div class="bottom">
          <div class="bulletpoint">
            <span class="perks">200 SMS monatlich*</span>
          </div>
          <div class="bulletpoint">
            <span class="perks">Daten unlimitiert abrufbar</span>
          </div>
          <br />
        </div>
        <div #plusBtn id="plusBtn" class="buttonField available">
          <div *ngIf="aboType < 3 || aboType === 4" (click)="upgrade(3)">
            <span [innerText]="userSettings.plusText"></span>
          </div>
          <div *ngIf="aboType === 3">
            <span [innerText]="userSettings.plusText"></span>
          </div>
        </div>
      </div>
    </div>

    <p class="margin-top text--size14">
      Alle Preise exkl. 20% USt. Jährliche Zahlung im Voraus.
    </p>
    <p class="margin-top text--size14">
      * Kosten je weitere SMS: 0,22€.
    </p>
    <p class="margin-top text--size14">
      ** Kosten je weitere SMS: 0,99€.
    </p>
    <div>
      <i
        #arrow
        id="arrow"
        style="color:#fbc51a"
        class="fa fa-arrow-circle-left"
        (click)="expandPricing()"
      ></i>
    </div>
  </div>
</div>
