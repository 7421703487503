import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MatStepper } from '@angular/material/stepper';
import { Router } from '@angular/router';
import { PatientRequest } from 'src/app/shared/classes/patient-request';

@Component({
  selector: 'app-requests-dialog',
  templateUrl: './requests-dialog.component.html',
  styleUrls: ['./requests-dialog.component.css']
})
export class RequestsDialogComponent implements OnInit {
  @ViewChild('stepper') stepper: MatStepper;
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      request: PatientRequest;
      getPdf: (request: PatientRequest, sendToMA: boolean) => Promise<any>;
      updateRequestItem: (request: Partial<PatientRequest>) => Promise<any>;
      deleteRequest: (requestData: PatientRequest) => Promise<any>;
    },
    private dialogRef: MatDialogRef<RequestsDialogComponent>,
    private router: Router,
    private dialog: MatDialog
  ) {}
  testTemplateName: 'LabPCRTest' | 'LabAntibodyTest' = 'LabPCRTest';
  testResult: 1 | 2 | 3 | 4;
  requestNo: number;
  loading = false;
  errorMessage: string;
  accountLinked = false;
  showMAButton = this.data.request.CreatedBy === 'MeineAkte' ? true : false;
  ngOnInit(): void {
    // console.log(this.data);
    const request = this.data.request;
    this.requestNo = request.RequestNo;
    this.testTemplateName = request.RequestResultsJSON?.testTemplateName || 'LabPCRTest';
    this.testResult = request.RequestResultsJSON?.testResult;
    if (request.RequestExternalID) {
      this.accountLinked = true;
    }
    // if (request.StatusID >= 2) {

    // }
  }
  ngAfterViewInit() {
    // this.stepper._getIndicatorType = () => 'number';
    // this.stepper.selectedIndex = null;
    // setTimeout(() => {
    //   this.stepper.selectedIndex = this.data.request.StatusID;
    // },1);
  }

  async updateStatus(statusID) {
    const requestItem = { ...this.data.request };
    requestItem.StatusID = statusID;
    requestItem.RequestNo = this.requestNo;
    if (!(requestItem.RequestNo ? `${requestItem.RequestNo}` : '').match(/\w{0,50}/)) {
      return;
    }
    if (statusID === 0 && this.data.request.RequestResultsJSON) {
      requestItem.RequestResultsJSON = null;
    }

    if (statusID === 1) {
      // requestItem.RequestResultsJSON = null;
      requestItem.RequestResultsJSON = { testResult: null, testTemplateName: this.testTemplateName };
      requestItem.ResultDate = null;
    }
    if (statusID === 2) {
      requestItem.RequestResultsJSON = { testResult: this.testResult, testTemplateName: this.testTemplateName };
      requestItem.ResultDate = new Date();
    }
    const res = await this.data.updateRequestItem({
      id: requestItem.id,
      RequestResultsJSON: requestItem.RequestResultsJSON,
      ResultDate: requestItem.RequestDate,
      RequestNo: requestItem.RequestNo,
      StatusID: requestItem.StatusID
    });
    this.dialogRef.close();
    //this.dialogRef.close(res); Case
  }

  async delete() {
    try {
      const deleteRes = await this.data.deleteRequest(this.data.request);
      this.dialogRef.close();
    } catch (error) {
      this.errorMessage = error;
    }
  }

  async send(destination: 'MeineAkte' | 'BefundExpress') {
    try {
      if (destination === 'BefundExpress') {
        const res: any = await this.generatePdf(false).catch((err) => {
          throw new Error('Fehler beim Erstellen des Ergebnis - PDFs!');
        });
        const uploadData = this.setUploadData(res.sasUrl);
        this.router.navigate(['/upload'], { state: { data: uploadData } });
        // this.dialogRef.close();
        this.dialog.closeAll();
      } else if (destination === 'MeineAkte') {
        const res: any = await this.generatePdf(true).catch((err) => {
          throw new Error('Fehler beim Erstellen des Ergebnis - PDFs!');
        });
        await this.updateStatus(3);
      }
    } catch (err) {
      this.errorMessage = err;
    }
  }

  async generatePdf(sendToMA: boolean) {
    this.loading = true;
    const res = await this.data.getPdf(this.data.request, sendToMA);

    this.loading = false;
    return res;
  }

  setUploadData(url: string) {
    const files = [
      {
        url,
        fileName: `temp-${Date.now()}.pdf`
      }
    ];
    return {
      reqID: this.data.request.id,
      files,
      contactID: this.data.request.PatientID
    };
  }
}
