<div class="row">
  <div class="col-12">
    <div class="text-center">
      <mat-radio-group
        [(ngModel)]="serviceProviderTypeID"
        (ngModelChange)="filterPortals(portalFilterText, serviceProviderTypeID)"
      >
        <mat-radio-button [value]="null">Alle</mat-radio-button>
        <mat-radio-button
          *ngFor="let provider of serviceProviderTypes.get()"
          [value]="provider.id"
          >{{ provider.value }}</mat-radio-button
        >
        <mat-radio-button
          *ngFor="let group of groups"
          [value]="group.id * -1"
          >{{ group.GroupName }}</mat-radio-button
        >
      </mat-radio-group>
    </div>
  </div>
  <div class="col col-12">
    <mat-form-field hideRequiredMarker>
      <input
        type="text"
        placeholder="Suche"
        matInput
        [(ngModel)]="portalFilterText"
        name="portalFilterText"
        (ngModelChange)="filterPortals(portalFilterText, serviceProviderTypeID)"
      />
    </mat-form-field>
  </div>

  <div class="col col-12 scroll-list">
    <mat-list>
      <mat-list-item *ngFor="let portal of filteredPortals">
        <button class="w-100" mat-button [mat-dialog-close]="portal">
          {{ getPortalLabel(portal) }}
        </button>
      </mat-list-item>
    </mat-list>

    <!-- <mat-selection-list #portalList>
      <mat-list-option *ngFor="let portal of filteredPortals"  [value]="portal">
        {{portal.Name}}
      </mat-list-option>
    </mat-selection-list> -->
  </div>
</div>

<!-- <button mat-raised-button class="w-100" color="primary" [mat-dialog-close]="portalList.selectedOptions.selected">Auswählen</button> -->
