import { Injectable } from "@angular/core";
import { AzureServiceService } from "./azure-service.service";

@Injectable({
  providedIn: "root"
})
export class GroupsService {
  private groupTable;
  private memberTable;

  constructor(private azureService: AzureServiceService) {
    this.memberTable = azureService.client.getTable("REGroupMember");
    this.groupTable = azureService.client.getTable("REGroup");
  }

  public async getGroupMembers(groupIDs) {
    return await this.groupTable.read({GroupIDs: groupIDs.join(',')});
  }

  public async getUserGroups() {
    return await this.memberTable.read();
  }
}
