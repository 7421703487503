import { Component, OnInit, Inject } from "@angular/core";

import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from "@angular/material/legacy-dialog";
declare const pdfAs: any;
@Component({
  selector: "app-sign-dialog",
  templateUrl: "./sign-dialog.component.html",
  styleUrls: ["./sign-dialog.component.css"]
})
export class SignDialogComponent implements OnInit {
  private file;
  private signedDocument;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialogRef<SignDialogComponent>
  ) {
    this.file = data;
  }

  ngOnInit(): void {}

  async ngAfterViewInit() {
    try {
      console.log(this.file);
      const res = await this.sign(this.file);
      this.signedDocument = { url: res, fileName: this.file.fileName };
      this.dialog.close(this.signedDocument);
    } catch (error) {
      this.dialog.close("Abort");
    }
  }

  public async sign(file) {
    const op = {
      content: file.url, // Diese Eigenschaft ist optional, wenn ausgelassen wird dem Benutzer ein Texteingabefeld gezeigt. Dies ist der Inhalt der signiert werden soll.
      connector: "mobilebku", // Diese Eigenschaft ist optional, wenn ausgelassen wird dem Benutzer gefragt. Moegliche Werte sind: "bku" -> Signatur mit der lokalen BKU, "onlinebku" -> Signatur mit der online BKU, "mobilebku" -> Signatur mit der Handy Signatur
      uiId: "mobileSignatureBox", // Diese Eigenschaft ist optional, wenn ausgelassen wird ein Overlay erzeugt. Dies ist die id eines div elements in das das iframe zur Benutzerinteraktion eingefuegt werden soll.
      sig_pos_x: 320, // Die X-Koordinate der links-unten Signaturblockecke.
      sig_pos_y: 820, // Die X-Koordinate der links-unten Signaturblockecke.
      sig_pos_p: file.pages + 1, // Die Seite auf der der Signaturblock platziert werden soll.
      sig_pos_w: 250, //Die Breite des Signaturblocks
      sig_pos_f: 50 // Die Hoehe der Fuszzeile ueber der der Signaturblock platziert werden soll.
    };

    return new Promise(function(resolve, reject) {
      pdfAs.signPdf(op, errorHandler, successHandler);

      function errorHandler(error, cause) {
        reject(error);
        console.log(cause);
      }
      function successHandler(pdfurl) {
        //this.dialog.close(pdfurl);
        resolve(pdfurl);
      }
    });
  }
}
