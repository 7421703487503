import { Component, OnInit, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

@Component({
	selector: 'app-admission-log-detail',
	templateUrl: './admission-log-detail.component.html',
	styleUrls: [ './admission-log-detail.component.css' ]
})
export class AdmissionLogDetailComponent implements OnInit {
	public questions;
	public results;
	public answered = false;
	public answeredQuestions;

	constructor(
		public dialogRef: MatDialogRef<AdmissionLogDetailComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any
	) {
		this.setData();
	}

	ngOnInit() {}

	private setData() {
		if (this.data) {
			const id = Object.keys(this.data.ResultsJSON)[0];
			this.answered = true;
			this.results = this.data.ResultsJSON[id].results;
			this.questions = this.data.QuestionsJSON;
			this.createTemplate();
		} else {
			console.log('Not answered yet');
		}
	}

	private createTemplate() {
		const allQuestions = [];
		this.questions.forEach((element) => {
			element.questions.forEach((item) => {
				allQuestions.push({ label: item.label, answer: this.results[element.pageNo][item.key] });
			});
		});
		allQuestions.forEach((item) => {
			if (typeof item.answer === 'boolean') {
				item.answer ? (item.answer = 'Ja') : (item.answer = 'Nein');
			}
		});
		this.answeredQuestions = allQuestions;
	}
}
