import { Component, OnInit, Inject, EventEmitter } from '@angular/core';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA
} from '@angular/material/legacy-dialog';
import { Url } from 'url';
import { UploadComponent } from '../../upload/upload.component';
import { Router } from '@angular/router';
import { DownloadService } from '../../../shared/services/download.service';

@Component({
  selector: 'app-report-log-detail',
  templateUrl: './report-log-detail.component.html',
  styleUrls: ['./report-log-detail.component.css']
})
export class ReportLogDetailComponent implements OnInit {
  public downloadData;
  public downloadLinks: Url[];
  public onSubmitReason = new EventEmitter();
  // constructor(private downloadService: DownloadService) { }

  constructor(
    public dialogRef: MatDialogRef<ReportLogDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public uploadComponent: UploadComponent,
    public router: Router,
    private downloadService: DownloadService
  ) {
    // console.log(data);
    // console.log(data.documentType);
    this.downloadData = data;
    this.downloadLinks = data.downloadLinks;
    // console.log(this.downloadData);
  }

  openDownloadLink(url) {
    window.open(url, '_blank');
  }

  ngOnInit() {}

  sendAgain() {
    const uploadData = this.setUploadData();
    this.router.navigate(['/upload'], { state: { data: uploadData } });
    this.dialogRef.close();
  }

  async update() {
    // console.log(this.downloadData);
    const res = await this.downloadService.update(this.downloadData.idx, this.downloadData.RecipientComments);
    this.onSubmitReason.emit(res);
  }

  async setSeen(data) {
    if (data.StatusID !== 101 && !data.RecipientName) {
      await this.downloadService.updateDownloadStatusPortal(data.idx);
      console.log('updated status');
    }
  }

  setUploadData() {
    // console.log(this.downloadData);
    let files;
    files = this.downloadData.fileNames?.map((fileName, index) => {
      return { url: this.downloadLinks[index], fileName };
    });
    if (!this.downloadData.fileNames) {
      const fileEnding = this.downloadData?.DocumentPathSender?.split('/')?.slice(-1)[0]?.split('.')[1] || 'pdf';
      console.log({ fileEnding });
      files = [
        {
          url: this.downloadLinks[0],
          fileName: `temp-${Date.now()}.${fileEnding}`
        }
      ];
    }
    return {
      idx: this.downloadData.idx,
      files,
      patientFirstName: this.downloadData.AffectedPersonFirstName,
      patientLastName: this.downloadData.AffectedPersonLastName,
      patientTitle: this.downloadData.AffectedPersonTitle,
      recipientEmail: this.downloadData.RecipientEMail,
      senderComments: this.downloadData.SenderCommentsSender,
      recipientName: this.downloadData.RecipientName,
      contactID: this.downloadData.CRContactID
    };
  }
}
