export const SVTInsurances: ISVTInsurance[] = [
  {
    code: '11',
    nurAnzeige: false,
    text: 'Österreichische Gesundheitskasse',
    ASVG: true,
    EKVKCode: '1100',
    EKVKName: 'OEGK',
    kurzbezeichnung: 'ÖGK-W'
  },
  {
    code: '12',
    nurAnzeige: false,
    text: 'Österreichische Gesundheitskasse',
    ASVG: true,
    EKVKCode: '1200',
    EKVKName: 'OEGK',
    kurzbezeichnung: 'ÖGK-N'
  },
  {
    code: '13',
    nurAnzeige: false,
    text: 'Österreichische Gesundheitskasse',
    ASVG: true,
    EKVKCode: '1300',
    EKVKName: 'OEGK',
    kurzbezeichnung: 'ÖGK-B'
  },
  {
    code: '14',
    nurAnzeige: false,
    text: 'Österreichische Gesundheitskasse',
    ASVG: true,
    EKVKCode: '1400',
    EKVKName: 'OEGK',
    kurzbezeichnung: 'ÖGK-O'
  },
  {
    code: '15',
    nurAnzeige: false,
    text: 'Österreichische Gesundheitskasse',
    ASVG: true,
    EKVKCode: '1500',
    EKVKName: 'OEGK',
    kurzbezeichnung: 'ÖGK-ST'
  },
  {
    code: '16',
    nurAnzeige: false,
    text: 'Österreichische Gesundheitskasse',
    ASVG: true,
    EKVKCode: '1600',
    EKVKName: 'OEGK',
    kurzbezeichnung: 'ÖGK-K'
  },
  {
    code: '17',
    nurAnzeige: false,
    text: 'Österreichische Gesundheitskasse',
    ASVG: true,
    EKVKCode: '1700',
    EKVKName: 'OEGK',
    kurzbezeichnung: 'ÖGK-S'
  },
  {
    code: '18',
    nurAnzeige: false,
    text: 'Österreichische Gesundheitskasse',
    ASVG: true,
    EKVKCode: '1800',
    EKVKName: 'OEGK',
    kurzbezeichnung: 'ÖGK-T'
  },
  {
    code: '19',
    nurAnzeige: false,
    text: 'Österreichische Gesundheitskasse',
    ASVG: true,
    EKVKCode: '1900',
    EKVKName: 'OEGK',
    kurzbezeichnung: 'ÖGK-V'
  },
  {
    code: '22',
    nurAnzeige: false,
    text: 'Betriebskrankenkasse der Wiener Verkehrsbetriebe',
    ASVG: true,
    EKVKCode: '2200',
    EKVKName: 'BKKWVB',
    kurzbezeichnung: 'BKKVB'
  },
  {
    code: '24',
    nurAnzeige: false,
    text: 'Betriebskrankenkasse Mondi',
    ASVG: true,
    EKVKCode: '2400',
    EKVKName: 'BKKMONDI',
    kurzbezeichnung: 'BKKMO'
  },
  {
    code: '25',
    nurAnzeige: false,
    text: 'Betriebskrankenkasse voestalpine Bahnsysteme',
    ASVG: true,
    EKVKCode: '2500',
    EKVKName: 'BKKVABS',
    kurzbezeichnung: 'BKKVABS'
  },
  {
    code: '26',
    nurAnzeige: false,
    text: 'Betriebskrankenkasse Zeltweg',
    ASVG: true,
    EKVKCode: '2600',
    EKVKName: 'BKKZELTWEG',
    kurzbezeichnung: 'BKKZW'
  },
  {
    code: '28',
    nurAnzeige: false,
    text: 'Betriebskrankenkasse Kapfenberg',
    ASVG: true,
    EKVKCode: '2800',
    EKVKName: 'BKKKAPFENBERG',
    kurzbezeichnung: 'BKKKA'
  },
  {
    code: '05',
    nurAnzeige: false,
    text: 'BVAEB-Eisenbahn Bergbau',
    ASVG: false,
    EKVKCode: '0500',
    EKVKName: 'BVAEBEB',
    kurzbezeichnung: 'BVAEB-EB'
  },
  {
    code: '07',
    nurAnzeige: false,
    text: 'BVAEB-Oeffentl. Bedienstete',
    ASVG: false,
    EKVKCode: '0700',
    EKVKName: 'BVAEBOEB',
    kurzbezeichnung: 'BVAEB-OEB'
  },
  {
    code: '40',
    nurAnzeige: false,
    text: 'Sozialversicherungsanstalt der Selbständigen - gewerbliche Wirtschaft',
    ASVG: false,
    EKVKCode: '4000',
    EKVKName: 'SVSGW',
    kurzbezeichnung: 'SVS-GW'
  },
  {
    code: '50',
    nurAnzeige: false,
    text: 'Sozialversicherungsanstalt der Selbständigen - Landwirtschaft',
    ASVG: false,
    EKVKCode: '5000',
    EKVKName: 'SVSLW',
    kurzbezeichnung: 'SVS-LW'
  },
  {
    code: '1A',
    nurAnzeige: false,
    text: 'Krankenfürsorgeanstalt der Bediensteten der Stadt Wien',
    ASVG: false,
    EKVKCode: '1A00',
    EKVKName: 'KFAWIEN',
    kurzbezeichnung: 'KFAW'
  },
  {
    code: '5A',
    nurAnzeige: false,
    text: 'Krankenfürsorgeanstalt für die Beamten der Landeshauptstadt Graz',
    ASVG: false,
    EKVKCode: '5A00',
    EKVKName: 'KFGRAZ',
    kurzbezeichnung: 'KFGRZ'
  },
  {
    code: '6A',
    nurAnzeige: false,
    text: 'Krankenfürsorgeanstalt für die Beamten der Stadt Villach',
    ASVG: false,
    EKVKCode: '6A00',
    EKVKName: 'KFAVILLACH',
    kurzbezeichnung: 'KFVIL'
  },
  {
    code: '7A',
    nurAnzeige: false,
    text: 'Krankenfürsorgeanstalt der Magistratsbeamten der Landeshauptstadt Salzburg',
    ASVG: false,
    EKVKCode: '7A00',
    EKVKName: 'KFASALZBURG',
    kurzbezeichnung: 'KFSAL'
  },
  {
    code: '8B',
    nurAnzeige: false,
    text: 'Kranken- und Unfallfürsorge der Tiroler Gemeindebeamten',
    ASVG: false,
    EKVKCode: '8B00',
    EKVKName: 'KUFTGEM',
    kurzbezeichnung: 'KFTGB'
  },
  {
    code: '8C',
    nurAnzeige: false,
    text: 'Kranken- und Unfallfürsorge der Tiroler Landesbeamten',
    ASVG: false,
    EKVKCode: '8C00',
    EKVKName: 'KUFTIROL',
    kurzbezeichnung: 'KFTLB'
  },
  {
    code: '8D',
    nurAnzeige: false,
    text: 'Kranken- und Unfallfürsorge der Tiroler Landeslehrer',
    ASVG: false,
    EKVKCode: '8D00',
    EKVKName: 'KUFTLEHRER',
    kurzbezeichnung: 'KFTLL'
  }
];

export interface ISVTInsurance {
  code: string;
  nurAnzeige: boolean;
  text: string;
  ASVG: boolean;
  EKVKCode: string;
  EKVKName: string;
  kurzbezeichnung: string;
}
