export class CuberryPromotion {
    id: number;
    PromoName: string;
    PromoStart: Date;
    PromoEnd: Date;
    FutureAboTypeID: number;
    PromoText: string;
    PromoResponse: string;
    ServiceStartDate: Date;
    ServiceDuration: number;
    IsMainAbo: boolean;
    UpgradeFromAboTypeID: number;
    ShowAsNotification: boolean;
    NotificationText: string;
    Active: boolean;
    StatusID: number;
    CreatedOn: Date;
    CreatedBy: string;
    EditedOn: Date;
    EditedBy: string;
}
