import { Component, OnInit, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { AzureServiceService } from '../../../../shared/services/azure-service.service';
import { AdmissionService } from '../../services/admission.service';

@Component({
	selector: 'app-api-detail',
	templateUrl: './api-detail.component.html',
	styleUrls: [ './api-detail.component.css' ]
})
export class ApiDetailComponent implements OnInit {
	public username = '';
	public password = '';
	public errorMessage = '';
	public loginPending = false;
	constructor(
		public dialogRef: MatDialogRef<ApiDetailComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any,
		private azureClient: AzureServiceService,
		private admissionService: AdmissionService
	) {}

	ngOnInit() {}

	async loginClick(event) {
		event.preventDefault();
		// console.log(event);
		if (!this.username || !this.password) {
			return;
		}
		this.errorMessage = '';
		try {
			this.loginPending = true;
			const loginData = {
				username: this.username,
				password: this.password
			};
			const loginRes = await this.admissionService.authenticate(loginData);
			console.log('Success', loginRes);
			this.dialogRef.close();
		} catch (err) {
			this.errorMessage = err.message;
		} finally {
			this.loginPending = false;
		}
	}
}
