import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './public/login/login.component';
import { UploadComponent } from './protected/upload/upload.component';
import { AzureServiceService } from './shared/services/azure-service.service';
import { DownloadComponent } from './public/download/download.component';
import { ProtectedComponent } from './protected/protected.component';
import { RegisterComponent } from './public/register/register.component';
import { ReportLogComponent } from './protected/report-log/report-log.component';
import { ReportLogDetailComponent } from './protected/report-log/report-log-detail/report-log-detail.component';
import { PasswordResetComponent } from './public/password-reset/password-reset.component';
import { ProfileComponent } from './protected/profile/profile.component';
import { AgbComponent } from './public/agb/agb.component';
import { HomeComponent } from './public/home/home.component';
import { TextBlockComponent } from './protected/text-block/text-block.component';
import { FavoritesComponent } from './protected/favorites/favorites.component';
import { PricingMobileComponent } from './protected/pricing-mobile/pricing-mobile.component';
import { AdmissionComponent } from './protected/admission/admission.component';
import { FavoritesDetailComponent } from './protected/favorites/favorites-detail/favorites-detail.component';
import { ETresorComponent } from './public/etresor/etresor.component';
import { SmsStatsViewComponent } from './protected/sms-stats/containers/sms-stats-view/sms-stats-view.component';
import { OauthComponent } from './public/oauth/oauth.component';

const routes: Routes = [
	// {
	//   path: '',
	//   redirectTo: 'login',
	//   // component: HomeComponent
	//   pathMatch: 'full'
	// },
	// {
	//   path: 'home', // nJ0O1ExJp9FFp8nfBhN7
	//   component: HomeComponent
	// },
	{
		path: 'login', // nJ0O1ExJp9FFp8nfBhN7
		component: LoginComponent
	},
	{
		path: 'login/via/:connectedAccountType',
		component: LoginComponent
	},
	{
		path: 'register',
		component: RegisterComponent
	},
	{
		path: 'register/via/:connectedAccountType',
		component: RegisterComponent
	},
	{
		path: 'passwordReset',
		component: PasswordResetComponent
	},
	{
		path: 'agb',
		component: AgbComponent
	},

	// {
	//   path: 'upload',
	//   component: UploadComponent,
	//   canActivate: [AzureServiceService]
	// },
	{
		path: 'download/:token',
		component: DownloadComponent
	},

	{
		path: 'et',
		component: ETresorComponent
	},

  {
    path: 'oauth/callback',
    component: OauthComponent
  },

	{
		path: '',
		children: [
			{
				path: '',
				component: ProtectedComponent,
				children: [
					{ path: '', redirectTo: 'upload', pathMatch: 'full' },
					{ path: 'upload', component: UploadComponent, canActivate: [ AzureServiceService ] },
					{ path: 'reportLog', component: ReportLogComponent, canActivate: [ AzureServiceService ] },
					{ path: 'reportLog/:idx', component: ReportLogComponent, canActivate: [ AzureServiceService ] },
					{ path: 'profile', component: ProfileComponent, canActivate: [ AzureServiceService ] },
					{ path: 'textblock', component: TextBlockComponent, canActivate: [ AzureServiceService ] },
					{ path: 'statistics', component: SmsStatsViewComponent, canActivate: [ AzureServiceService ] },
					{ path: 'pricing', component: PricingMobileComponent, canActivate: [ AzureServiceService ] },
					{ path: 'favorites', component: FavoritesComponent, canActivate: [ AzureServiceService ] },
					{ path: 'admission', component: AdmissionComponent, canActivate: [ AzureServiceService ] }
				]
			}
		]
	},
	{
		path: '**',
		redirectTo: 'login'
		// pathMatch: 'full'
	}
];

@NgModule({
	imports: [ RouterModule.forRoot(routes) ],
	exports: [ RouterModule ]
})
export class AppRoutingModule {}
