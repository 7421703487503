import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import * as moment from 'moment';
import { BehaviorSubject, defer, from, merge, Observable, of } from 'rxjs';
import { catchError, exhaustMap, map, scan } from 'rxjs/operators';
import { Favorite } from '../../../shared/classes/favorite';
import { PatientRequest } from '../../../shared/classes/patient-request';
import { PersonTitles } from '../../../shared/collections/person-titles';
import { ConfirmDialogComponent } from '../../../shared/components/confirm-dialog/confirm-dialog.component';
import { ApplicationStateService, APP_SCREEN_SIZES } from '../../../shared/services/application-state.service';
import { FavoritesService } from '../../../shared/services/favorites.service';
import { RequestsDialogComponent } from '../../requests/components/requests-dialog/requests-dialog.component';
import { RequestsService } from '../../requests/services/requests.service';

@Component({
  selector: 'app-favorites-detail',
  templateUrl: './favorites-detail.component.html',
  styleUrls: ['./favorites-detail.component.css']
})
export class FavoritesDetailComponent implements OnInit {
  form: UntypedFormGroup;
  favorite: Favorite;
  public editMode = false;
  public personTitles = PersonTitles;
  public header: String;
  public notNotified = false;
  public sendSMS = true;
  public displayExtension = JSON.parse(localStorage.getItem('userobject'))?.PortalTypeID === 99;
  screenSize$: Observable<string>;
  APP_SCREEN_SIZES = APP_SCREEN_SIZES;

  requestsState$: Observable<{ items: PatientRequest[]; status?: string }>;
  requestsEvent$$: BehaviorSubject<{ dataObservable?: Observable<PatientRequest[]>; action?: string }>;

  constructor(
    private fb: UntypedFormBuilder,
    public dialogRef: MatDialogRef<FavoritesDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private favoritesService: FavoritesService,
    private dialog: MatDialog,
    private appStateService: ApplicationStateService,
    private requestsService: RequestsService
  ) {
    if (data?.FirstNameEn) {
      // console.log('edit');
      this.editMode = true;
      this.header = 'Kontakt Bearbeiten';
      this.favorite = data;
      if (this.favorite.StatusID === 1) {
        this.notNotified = true;
      }
      this.sendSMS = this.favorite.DefaultSendSMS ?? true;
    } else {
      this.header = 'Neuer Kontakt';
      this.favorite = new Favorite({
        PersonTitle: '',
        FirstNameEn: '',
        LastNameEn: '',
        EMail: data?.EMail || '',
        PhoneMobile: data?.PhoneMobile || '',
        Note: '',
        DefaultSendSMS: true,
        DefaultPW: ''
      });
    }
  }

  async ngOnInit() {
    // console.log(this.favorite);
    if (this.displayExtension) {
      this.initRequestObservables();
    }

    this.screenSize$ = this.appStateService.screenSize$;
    this.form = this.fb.group({
      title: [this.favorite.PersonTitle, []],
      firstName: [this.favorite.FirstNameEn, []],
      lastName: [this.favorite.LastNameEn, []],
      email: [this.favorite.EMail, []],
      phone: [this.favorite.PhoneMobile, []],
      note: [this.favorite.Note, []],
      password: [this.favorite.DefaultPW, []]
    });

    // console.log('OK');
    //await this.favoritesService.registerPhone(this.favorite);
  }

  async registerPhone() {
    return await this.favoritesService.registerPhone(this.favorite);
  }

  async delete() {
    if (this.dialog.openDialogs.some((openDialog) => openDialog.componentInstance instanceof ConfirmDialogComponent)) {
      return;
    }
    const dialogRef2 = this.dialog.open(ConfirmDialogComponent, {
      width: '500px',
      data: `Sind Sie sicher, dass Sie ${this.favorite.PersonTitle} ${this.favorite.FirstNameEn} ${this.favorite.LastNameEn}
      aus ihren Favoriten löschen wollen?`
    });

    dialogRef2.afterClosed().subscribe(async (result) => {
      if (result) {
        await this.favoritesService.delete(this.favorite);
        // console.log('deleted');
        this.dialogRef.close();
      }
    });
  }

  close() {
    this.dialogRef.close();
  }

  async save(reopenDialog?: boolean) {
    if (this.displayExtension) {
      this.setExtendedFields();
    }
    let result;
    this.favorite.StatusID = this.notNotified ? 1 : 0;
    this.favorite.DefaultSendSMS = this.sendSMS;
    if (this.editMode) {
      result = await this.favoritesService.update(this.data, this.favorite);
      // console.log('Updated');
      // this.dialogRef.close();
    } else {
      result = await this.favoritesService.insert(this.favorite);
      // console.log('Inserted');
      // this.dialogRef.close();
    }
    this.dialogRef.close(reopenDialog ? { reOpen: reopenDialog, favoriteResult: result } : undefined);
    // this.dialogRef.close(this.form.value);
    return result;
  }

  public setExtendedFields() {
    // console.log(this.form.value.dateOfBirth);
    this.favorite.Street = this.form.value.street;
    this.favorite.ZIPCode = this.form.value.zipCode;
    this.favorite.City = this.form.value.city;
    this.favorite.CountryID = this.form.value.countryID;
    this.favorite.DateOfBirth = moment(this.form.value.dateOfBirth).isValid()
      ? moment.utc(this.form.value.dateOfBirth).toISOString()
      : null;
    this.favorite.InsuranceNo = this.form.value.insuranceNumber;
    this.favorite.InsurancePrimary = this.form.value.insurance;
    this.favorite.PlaceOfBirth = this.form.value.placeOfBirth;
    this.favorite.PersonalIDNo = this.form.value.personalIDNo;
    // console.log(this.favorite.DateOfBirth);
  }

  // async testLimit() {
  //   const amount = 2000;
  //   for (let i = 0; i < amount; i++) {
  //     const fav = new Favorite({
  //       PersonTitle: 'Herr',
  //       FirstNameEn: `Limit${i}`,
  //       LastNameEn: `Test${i}`,
  //       EMail: 'testemail@peacequare.at',
  //       PhoneMobile: '00232381515',
  //       Note: 'Delete test user',
  //       StatusID: 0
  //     });
  //     await this.favoritesService.insert(fav);
  //     console.log(i);
  //   }
  // }

  async showRequestDialog(request: PatientRequest, favorite) {
    try {
      if (
        this.dialog.openDialogs.some((openDialog) => openDialog.componentInstance instanceof RequestsDialogComponent)
      ) {
        return;
      }
      this.favorite.StatusID = this.notNotified ? 1 : 0;
      this.favorite.DefaultSendSMS = this.sendSMS;
      await this.favoritesService.update(this.data, this.favorite);
      const dialogRef = this.dialog.open(RequestsDialogComponent, {
        width: '900px',
        autoFocus: false,
        data: {
          request,
          updateRequestItem: (requestData: PatientRequest) => {
            this.requestsEvent$$.next({
              dataObservable: defer(() =>
                from(this.requestsService.updateRequest(requestData)).pipe(map((result) => [result]))
              ),
              action: 'update'
            });
          },
          getPdf: (requestData: PatientRequest) => this.requestsService.printRequestResult(requestData, favorite)
        }
      });
    } catch (err) {
      console.log(err);
    }
  }

  initRequestObservables() {
    this.requestsEvent$$ = new BehaviorSubject({
      dataObservable: this.requestsService.readRequests({ patientID: this.favorite.id }),
      action: 'read'
    });
    this.requestsState$ = this.requestsEvent$$.pipe(
      exhaustMap(({ dataObservable, action }) =>
        merge(
          dataObservable.pipe(
            map((items) => ({ items, action })),
            catchError(() => of({ action: 'error' }))
          ),
          of({ action: 'loading' })
        )
      ),
      scan(
        (acc: { items: PatientRequest[]; status?: string }, curr: { items?; action }) => {
          if (curr.action === 'read') {
            return { items: [...curr.items] };
          }
          if (curr.action === 'add') {
            return { items: [...curr.items, ...acc.items] };
          }
          if (curr.action === 'update') {
            const [currentItem] = curr.items;
            return {
              items: acc.items.map((item) => (item.id === currentItem.id ? currentItem : item))
            };
          }
          if (curr.action === 'loading') {
            return { items: [...acc.items], status: 'loading' };
          }
          if (curr.action === 'error') {
            return { items: [...acc.items], status: 'error' };
          }
          return acc;
        },

        { items: [] }
      )
    );
  }

  saveAndAddRequest(item: Favorite) {
    return this.save(true).then((result) => this.requestsService.addRequest(result.id));
  }

  addPatientRequest(patientID: number) {
    this.requestsEvent$$.next({
      dataObservable: defer(() => from(this.requestsService.addRequest(patientID)).pipe(map((result) => [result]))),
      action: 'add'
    });
  }
}
