import { Injectable } from '@angular/core';
import { MobileServiceClient } from 'azure-mobile-apps-client';
import { environment } from '../../../environments/environment';
import { Router, CanActivate } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import * as CryptoJS from 'crypto-js';
import { Favorite } from '../classes/favorite';

@Injectable()
export class AzureServiceService implements CanActivate {
  private appUrl: string = environment.azureAppURL;
  private redirectPath: string;
  public client = new MobileServiceClient(this.appUrl);
  public filesToUpload: File[];
  public filesIsEmpty = true;
  public showAd: Boolean;
  public pin;
  public signWithName = true;
  // .withFilter((request, next, callback) => {
  //   next(request, function (error, response) {
  //     if (error) {
  //       this.canActivate();
  //     }
  //     callback(error, response);
  //   });
  // });
  // // private myReader: FileReader = new FileReader();
  private loginErrors = {
    '6': 'Ungültiger Username!',
    '7': 'Dieser User ist inaktiv!',
    '8': (minutes) => `Zu viele Loginversuche, bitte versuchen Sie es in ${minutes} Minuten erneut`,
    '9': 'Falsches Passwort!',
    '14': 'Ungültige Logindaten!'
  };

  constructor(private router: Router, private http: HttpClient) {}

  public async login(username: string, password: string) {
    try {
      const loginRes = await this.client.invokeApi('login', {
        body: {
          username: username,
          password: password
        },
        method: 'post'
      });
      // console.log(loginRes);
      if (loginRes.result.forward) {
        return false;
      }
      this.setUserObject(loginRes.result);
      return true;
    } catch (err) {
      // console.log(err);
      const errResponse = JSON.parse(err.request.response)[0],
        errorMessage =
          errResponse.Error === 8
            ? this.loginErrors[errResponse.Error](errResponse.Field)
            : this.loginErrors[errResponse.Error];
      throw new Error(errorMessage);
    }
  }
  public async loginExternal(loginRes) {
    this.setUserObject(loginRes);
  }

  public getPin() {
    return this.pin;
  }

  public setPin(newPin) {
    this.pin = newPin;
  }

  public logout() {
    this.client.logout();
    localStorage.clear();
    this.router.navigate(['login']);
    window.location.reload();
    // localStorage.removeItem('cuberry-credentials');
    // localStorage.removeItem('cuberry-userobject');
  }

  private setUserObject(loginRes) {
    localStorage.clear();
    const credentials = {
      userId: loginRes.user.userId,
      mobileServiceAuthenticationToken: loginRes.token
    };
    localStorage.setItem('credentials', JSON.stringify(credentials));
    localStorage.setItem('userobject', JSON.stringify(loginRes.user));
    if (loginRes.token) {
      this.client.currentUser = credentials;
    }
  }

  public getUserAboType() {
    const uObject = JSON.parse(localStorage.getItem('userobject'));
    let aboType = uObject.ContactPortalObject.AboType;

    if ((aboType !== 1 && aboType !== 2 && aboType !== 3 && aboType !== 4) || aboType === null) {
      aboType = 0;
    }
    return aboType;
  }

  public checkLogin(): boolean {
    return this.client.currentUser || this.loadCredentials();
  }

  public getUserName(): string {
    const userObject = localStorage.getItem('credentials');
    return userObject ? JSON.parse(userObject).userId : '';
  }

  private loadCredentials() {
    const data = localStorage.getItem('credentials');
    const credentials = JSON.parse(data);
    if (credentials && this.checkCredentialsValid(credentials.mobileServiceAuthenticationToken)) {
      this.client.currentUser = credentials;
      return true;
    }
    console.log('loadCredentials -> logout');
    this.client.logout();
    localStorage.clear();
    return false;
  }

  private checkCredentialsValid(credentials: string) {
    try {
      const credInfo = JSON.parse(atob(credentials.split('.')[1]));
      return credInfo.exp * 1000 > +new Date();
    } catch (err) {
      console.log(err);
      return false;
    }
  }

  public async uploadFormDataFileSigned(uploadData, endpoint: string) {
    try {
      const res = await this.client.invokeApi(endpoint, {
        // parameters: {},
        body: uploadData,
        method: 'POST'
      });

      console.log('success', res);
    } catch (error) {
      console.log('error', error);
    }
  }

  public uploadFormDataFile(files, endpoint: string, uploadData) {
    // this.myReader.onload = async (event) => {
    // const key = CryptoJS.enc.Utf8.parse('dsa');
    // const iv = CryptoJS.enc.Utf8.parse('myIv');
    // const fileData = this.myReader.result.split(',')[1];
    // const rawArray = CryptoJS.enc.Base64.parse(fileData);
    // const encrypted = CryptoJS.AES.encrypt(rawArray, 'dsa');
    // console.log(encrypted);
    // const encB64 = CryptoJS.enc.Base64.stringify(encrypted.ciphertext);
    // const b64Blob = new Blob([encB64], { type: 'application/pdf' });
    // const url = `data:application/octet-stream;base64,${encB64}`;

    // const tempResp = await fetch(url);
    // const b64Blob = await tempResp.blob();
    // console.log(b64Blob);
    // .then(res => res.blob())
    // .then(blob => console.log(blob));
    // console.log(encB64);
    // // console.log(decrypted);

    const _headers = new HttpHeaders();
    const headers = _headers.set('X-ZUMO-AUTH', this.client.currentUser.mobileServiceAuthenticationToken);
    const formData = new FormData();

    const uObject = JSON.parse(localStorage.getItem('userobject'));
    const userName = `${uObject.PersonTitle} ${uObject.FirstName} ${uObject.LastName}`;

    formData.append('PIN', uploadData.uploadPIN);
    formData.append('RecipientEMail', uploadData.recipientEmail);
    formData.append('SenderComments', uploadData.senderComments ? uploadData.senderComments.trim() : '');
    formData.append('CRContactID', uploadData.contactID ? uploadData.contactID : '');
    formData.append('InternalNote', uploadData.internalNote ? uploadData.internalNote.trim() : '');
    formData.append('PatientTitle', uploadData.patientTitle ? uploadData.patientTitle.trim() : '');
    formData.append('PatientFirstName', uploadData.patientFirstName ? uploadData.patientFirstName.trim() : '');
    formData.append('PatientLastName', uploadData.patientLastName ? uploadData.patientLastName.trim() : '');
    files.forEach((file, index) => {
      formData.append('files', file, file.name);
    });
    formData.append('SMSID', uploadData.smsID);
    formData.append('Salt', uploadData.salt);
    formData.append('IV', uploadData.iv);
    formData.append('SignedFiles', uploadData.signedFiles);

    formData.append('UserName', this.signWithName ? userName : '');

    return this.http
      .post(`${this.appUrl}/${endpoint}`, formData, {
        headers: headers
      })
      .toPromise();
  }

  public async sendSMS(message: string, phoneNo) {
    try {
      const res = await this.client.invokeApi('sendsms', {
        body: {
          message: message,
          recipientPhoneNo: phoneNo
        },
        method: 'post'
      });
      return res.result.smsID;
    } catch (err) {
      console.log('Error: ' + err);
    }
  }

  public async checkSMS(smsID: string) {
    try {
      const res = await this.client.invokeApi('sendsms', {
        parameters: {
          smsID
        },
        method: 'get'
      });
      // console.log('CheckSMS Status', res.result.StatusText);
      return res.result.StatusText === 'DELIVERED' ? 1 : res.result.StatusText === 'SENT' ? 0 : -1;
    } catch (error) {
      console.log('GET SMS', error);
    }
  }

  public getURLFromFile(files: File[], endpoint: string) {
    const _headers = new HttpHeaders();
    const headers = _headers.set('X-ZUMO-AUTH', this.client.currentUser.mobileServiceAuthenticationToken);

    const formData = new FormData();
    formData.append('TargetPortalIDx', '2311');
    files.forEach((file) => {
      formData.append('files', file, file.name);
    });

    return this.http
      .post(`${this.appUrl}/${endpoint}`, formData, {
        headers: headers
      })
      .toPromise();
  }

  public async getURL(filePaths, encIVs) {
    const result = await this.client.invokeApi(`fileconversion`, {
      method: 'get',
      parameters: {
        FilePaths: filePaths,
        IVs: encIVs
      }
    });
    return result.result;
  }

  public sendFileToPortal(files: File[], endpoint: string, uploadData) {
    const _headers = new HttpHeaders();
    const headers = _headers.set('X-ZUMO-AUTH', this.client.currentUser.mobileServiceAuthenticationToken);
    const formData = new FormData();

    const uObject = JSON.parse(localStorage.getItem('userobject'));
    const userName = `${uObject.PersonTitle} ${uObject.FirstName} ${uObject.LastName}`;

    // formData.append('RecipientEMail', uploadData.recipientEmail);
    // formData.append('SenderComments', uploadData.senderComments ? uploadData.senderComments.trim() : '');
    formData.append('UserName', this.signWithName ? userName : '');
    formData.append('InternalNote', uploadData.internalNote ? uploadData.internalNote.trim() : '');
    formData.append('TargetPortalIDx', uploadData.recipientPortal.idx);
    formData.append('PatientTitle', uploadData.patientTitle ? uploadData.patientTitle.trim() : '');
    formData.append('PatientFirstName', uploadData.patientFirstName ? uploadData.patientFirstName.trim() : '');
    formData.append('PatientLastName', uploadData.patientLastName ? uploadData.patientLastName.trim() : '');
    files.forEach((file) => {
      formData.append('files', file, file.name);
    });
    return this.http
      .post(`${this.appUrl}/${endpoint}`, formData, {
        headers: headers
      })
      .toPromise();
  }

  canActivate(route, state): boolean {
    if (!this.checkLogin()) {
      if (state.url.indexOf('reportLog') > -1 && route.params.idx) {
        this.redirectPath = state.url;
      }
      this.router.navigate(['login']);
      return false;
    }
    return true;
  }

  redirectAfterLogin() {
    if (this.getUserAboType() === 0) {
      this.showAd = true;
      console.log('Free abo, ad activated');
    } else {
      this.showAd = false;
      console.log('not free abo');
    }
    const tempPath = this.redirectPath;
    !tempPath ? this.router.navigate(['upload']) : ((this.redirectPath = null), this.router.navigate([tempPath]));
  }

  setFiles() {
    this.filesIsEmpty = false;
  }

  async sendToETresor(favorite: Favorite, filesToSend: any[]) {
    const _headers = new HttpHeaders();
    const headers = _headers.set('X-ZUMO-AUTH', this.client.currentUser.mobileServiceAuthenticationToken);
    const formData = new FormData();

    formData.append('contactID', favorite.id);
    formData.append('contactUID', favorite.ETresorUID);
    for (const file of filesToSend) {
      formData.append('files', file.data, file.name);
    }

    return this.http
      .post(`${this.appUrl}/api/e-tresor`, formData, {
        headers: headers
      })
      .toPromise();

    try {
      console.log('FilesToSend: ', filesToSend);
      const res = await this.client.invokeApi('e-tresor', {
        body: { contact: favorite, files: filesToSend },
        method: 'POST'
      });

      console.log('Success', res.result);
    } catch (error) {
      console.log('Error', error.message);
    }
  }
}
