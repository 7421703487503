import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DownloadService } from '../../shared/services/download.service';
import { Url } from 'url';

@Component({
	selector: 'app-download',
	templateUrl: './download.component.html',
	styleUrls: [ './download.component.css' ]
})
export class DownloadComponent implements OnInit {
	private token: string;
	public pin: string;
	public checkFile = 0;
	// public downloadLink: Url;
	public downloadLinks: Url[];
	public fileDownloadStatus = 0;
	public senderComments: string;
	public fileNames: string[];
	private downloadID;
	private statusID;
	public errorMessage = false;
	constructor(private route: ActivatedRoute, private downloadService: DownloadService) {}

	async ngOnInit() {
		this.token = this.route.snapshot.paramMap.get('token');
		this.checkFile = await this.downloadService.checkIfDocumentExists(this.token);
		// .subscribe(data => this.uploadInfos = { ...data },
		//   error => this.errorMessage = 'Ungültiger Token');
	}

	async downloadFile(event) {
		event.preventDefault();
		if (this.fileDownloadStatus === 1) {
			return;
		}
		try {
			this.fileDownloadStatus = 1;
			const {id, comments, fileUrls, fileNames, statusID } = await this.downloadService.downloadDocument(this.token, this.pin);
			this.downloadID = id;
			this.statusID = statusID;
			// const result = await this.downloadService.downloadDocument(this.token, this.pin);

			/* const bytes = new Uint8Array(result.response.length);
			for (let i = 0; i < result.response.length; i++) {
				bytes[i] = result.response.charCodeAt(i);
			}
			const blob = new Blob([ bytes ], { type: 'text/plain' });
			console.log('Res: ', blob);*/
			this.errorMessage = false;
			// console.log(comments, fileUrl);
			this.senderComments = comments;
			this.fileNames = fileNames ? fileNames.split(',') : null;
			// this.downloadLink = fileUrl;
			this.downloadLinks = fileUrls;
			this.fileDownloadStatus = 2;
		} catch (err) {
			this.errorMessage = true;
			this.fileDownloadStatus = 0;
		}
	}

	async setSeen(){
		if(this.statusID != 101){
			await this.downloadService.updateDownloadStatus(this.token, this.downloadID);
		}
		console.log('already checked');
	}

	public blobToFile = (theBlob: Blob, fileName: string): File => {
		const b: any = theBlob;

		b.lastModifiedDate = new Date();
		b.name = fileName;

		return <File>theBlob;
	};

	// https://stackoverflow.com/questions/41379274/print-html-template-in-angular-2-ng-print-in-angular-2
	printToCart(printSectionId: string) {
		let popupWinindow;
		const innerContents = document.getElementById(printSectionId).innerHTML;
		popupWinindow = window.open(
			'',
			'_blank',
			'width=600,height=700,scrollbars=no,menubar=no,toolbar=no,location=no,status=no,titlebar=no'
		);
		popupWinindow.document.open();
		popupWinindow.document.write(
			`<html><head><link rel="stylesheet" type="text/css" href="style.css" /></head>
	  <body onload="window.print()">` +
				innerContents +
				'</body></html>'
		);
		popupWinindow.document.close();
	}
}
