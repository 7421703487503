<label class="text--info">Verbundene Accounts</label>
<div *ngIf="view == 'loading'">
  <i class="fa fa-spinner fa-spin fa-pulse"></i>
</div>
<div *ngIf="view == 'overview'">
    <table class="table table-striped">
        <tbody>
            <tr *ngIf="connectedAccounts.length == 0"><td>Keine Accounts verbunden</td></tr>
            <tr *ngFor="let connectedAccount of connectedAccounts">
              <td>
                {{nameFor(connectedAccount)}}
                <button mat-raised-button class="connected-button-delete" color="primary" (click)="unlinkAccount(connectedAccount)">Verbindung lösen</button>
              </td>
            </tr>
        </tbody>
    </table>
    <button mat-raised-button class="w-100" color="primary"
    (click)="switchView('connect')">
    Neuen Account verbinden
    </button>
</div>
<div *ngIf="view == 'connect'">
    <p>Bitte wählen Sie einen Accounttyp zum Verbinden</p>
    <app-connected-accounts-list (accountClick)="connectAccount($event)" [filterTypes]="filterAccountTypes"></app-connected-accounts-list>

    <button mat-raised-button class="w-100" color="primary"
    (click)="switchView('overview')">
    Zurück zur Übersicht
    </button>
</div>
