import { Injectable } from '@angular/core';
import { AzureServiceService } from '../../../shared/services/azure-service.service';

@Injectable()
export class ApiUserService {
	constructor(private azureClient: AzureServiceService) {}
	private apiUserTable = this.azureClient.client.getTable('XSecUserApi');

	public async get() {
		const userResult = await this.apiUserTable.read();
		return userResult;
	}

	public async insert() {
		const userResult = await this.apiUserTable.insert({});
		return userResult;
	}

	public async changePassword(userId: string) {
		const result = await this.apiUserTable.update({ id: userId });
		return result;
	}

	public async delete(userId: string) {
		const result = await this.apiUserTable.del({ id: userId });
		return result;
	}

	public async deleteByAPIID(apiid: number) {
		const result = await this.apiUserTable.del({ id: apiid }, { type: 'deleteByAPIID' });
		return result;
	}
}
