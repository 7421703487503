<div class="header">
<h5>Impressum</h5>
</div>
<div class="body">
  <br>
  Befundexpress ist ein Dienst der:
  <br>
  <br>
  <strong>Peacequare GmbH</strong>
  <br>
  Sebastian Kneipp Gasse 9/35
  <br>
  1020 Wien
  <br>
  Tel: +43 1 934 60 89
  <br>
  <br>
  Steuernummer: 12 549/5887
  <br>
  UID: ATU68951789
  <br>
  Amtsgericht Wien
  <br>
  Geschäftsführer: Mag. Amir Moussa
</div>
