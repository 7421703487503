export const ServiceProviderTypes = {
  values: [
    { id: 1, value: 'Arzt' },
    { id: 2, value: 'Apotheke' }
    // { id: 3, value: 'Labor' },
    // { id: 3, value: 'Meine Akte' }
  ],

  get(id?: number) {
    return typeof id === 'undefined'
      ? this.values.slice()
      : this.values.filter(item => item.id === id);
  }
};
