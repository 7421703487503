<div *ngIf="requests?.length > 0">
  <mat-table #table [dataSource]="requests" matSortActive="reqdate" matSortDirection="desc" matSort>
    <ng-container matColumnDef="reqdate">
      <mat-header-cell *matHeaderCellDef>Datum der Anfrage</mat-header-cell>
      <mat-cell *matCellDef="let entry">{{ entry.RequestDate | date: 'dd.MM.yyyy' }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="resdate">
      <mat-header-cell *matHeaderCellDef>Datum des Ergebnisses</mat-header-cell>
      <mat-cell *matCellDef="let entry">{{ entry.ResultDate | date: 'dd.MM.yyyy' }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="status">
      <mat-header-cell *matHeaderCellDef>Status</mat-header-cell>
      <mat-cell *matCellDef="let entry">
        {{ status[entry.StatusID] }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="type">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Testart</mat-header-cell>
      <mat-cell *matCellDef="let entry">
        {{ entry?.RequestResultsJSON?.testTemplateName }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="result">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Ergebnis</mat-header-cell>
      <mat-cell *matCellDef="let entry">
        {{ entry?.RequestResultsJSON?.testResult ? resultMap[entry.RequestResultsJSON.testResult] : '' }}
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>

    <mat-row *matRowDef="let row; columns: displayedColumns" (click)="itemClicked.emit(row)"></mat-row>
  </mat-table>
</div>
<div *ngIf="requests?.length === 0">Keine Anfragen vorhanden</div>
