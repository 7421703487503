import { Injectable } from '@angular/core';
import { AzureServiceService } from './azure-service.service';
import { HttpParams } from '@angular/common/http';

@Injectable()
export class DownloadService {
  downloadTable;
  constructor(private azureService: AzureServiceService) {
    this.downloadTable = azureService.client.getTable('CCPatientDocumentDownload');
  }

  public async read(parameters?: any) {
    return Promise.resolve(this.downloadTable.read(parameters || {}));
  }

  public async update(idx, comments) {
    try {
      const res = await Promise.resolve(this.downloadTable.update({ id: idx, RecipientComments: comments }));
      return res;
    } catch (e) {
      console.log('Error: ' + e);
    }
  }

  public checkIfDocumentExists(token): Promise<any> {
    const params = new HttpParams().set('RequestFrom', 'CheckEntry').set('token', token);
    return Promise.resolve(
      this.azureService.client.invokeApi(`fileupload?${params.toString()}`, {
        method: 'get'
      })
    )
      .then((res) => {
        return Promise.resolve(1);
      })
      .catch((err) => {
        // console.log(err);
        return Promise.resolve(2);
      });
  }

  public downloadDocument(token, pin) {
    if (!token) {
      throw new Error('no token');
    }
    if (!pin) {
      throw new Error('no PIN');
    }
    const params = new HttpParams().set('token', token).set('PIN', pin);
    return Promise.resolve(
      this.azureService.client.invokeApi(`fileupload?${params.toString()}`, {
        method: 'get'
      })
    ).then((result) => Promise.resolve(result.result));
  }

  public async updateDownloadStatus(token, id) {
    try {
      const res = await Promise.resolve(this.downloadTable.update({ id, StatusID: 101 }, { token }));
      return res;
    } catch (e) {
      console.log('Error ' + e);
    }
  }

  public async updateDownloadStatusPortal(idx) {
    try {
      const params = new HttpParams().set('DownloadID', idx);
      const res = await this.azureService.client.invokeApi('fileservice', { body: { DownloadID: idx }, method: 'put' });
      return res;
    } catch (e) {
      console.log('Error ' + e);
    }
  }

  public async downloadDocumentByIDx(data) {
    const documentIDx = data.idx;
    const requestFrom = data.MessageTypeID === 0 || data.MessageTypeID === 300 ? 'FileSent' : 'FileDownload';
    const params = new HttpParams()
      .set('DownloadID', documentIDx)
      .set('RequestFrom', requestFrom)
      .set('MessageTypeID', data.MessageTypeID);
    const result = await this.azureService.client.invokeApi(`fileservice?${params.toString()}`, {
      method: 'get'
    });
    return result.result;
  }
}
