import { Component, OnInit } from '@angular/core';
import { AzureServiceService } from '../../shared/services/azure-service.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {
  public userName = '';
  public labUserID = '';
  constructor(private azureClient: AzureServiceService) { }

  async ngOnInit() {
    this.userName = this.azureClient.getUserName();
    this.labUserID = JSON.parse(localStorage.getItem('userobject')).ContactPortalObject.LabUserID;
  }

  public logout(event) {
    event.preventDefault();
    this.azureClient.logout();
  }
}
