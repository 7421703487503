import { Component, OnInit, Inject, AfterViewInit } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { ConnectedAccountsListComponent, IConnectedAccountType } from 'src/app/shared/components/connected-accounts-list/connected-accounts-list.component';
import { ConnectedAccountsService, IConnectedAccount } from '../../../../shared/services/connected-accounts.service';

@Component({
	selector: 'app-connected-accounts',
	templateUrl: './connected-accounts.component.html',
	styleUrls: [ './connected-accounts.component.css' ],
	providers: [ ConnectedAccountsService ]
})
export class ConnectedAccountsComponent implements OnInit, AfterViewInit {
    public view = 'loading';
    public availableAccountTypes: string[] = [];
    public connectedAccounts: IConnectedAccount[] = [];

	constructor(
    private caService: ConnectedAccountsService,
    private dialog: MatDialog
	) {}

	ngOnInit() {
    this.filterAccountTypes = this.filterAccountTypes.bind(this);
  }
  ngAfterViewInit() {
    this.loadAccounts().then(() => this.switchView('overview'));
  }

  async loadAccounts() {
    const connected = await this.caService.getConnectedAccounts();
    this.connectedAccounts = connected;
    this.availableAccountTypes = ConnectedAccountsListComponent.CONNECTED_ACCOUNT_TYPES.filter(t => !connected.find(c => c.AccountType === t.name)).map(t => t.name);
  }

  public async connectAccount(accountType) {
      let redirect = await this.caService.getRedirect(accountType.name, 'connect');
      window.location.replace(redirect.url);
  }
  public async unlinkAccount(connectedAccount) {
    if (this.dialog.openDialogs.some((openDialog) => openDialog.componentInstance instanceof ConfirmDialogComponent)) {
      return;
    }
    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      width: '500px',
      data: `Sind Sie sicher, dass Sie die Verbindung zwischen diesem ${this.nameFor(connectedAccount)}-Account und Ihrem BefundExpress-Account lösen möchten?`
    });

    confirmDialog.afterClosed().subscribe(async (result) => {
      if (result) {
        await this.caService.unlinkConnectedAccount(connectedAccount.AccountType);
        await this.loadAccounts();
      }
    });
  }

  public switchView(viewName) {
      this.view = viewName;
  }
  public filterAccountTypes(accountType: IConnectedAccountType) {
    return this.availableAccountTypes.includes(accountType.name);
  }
  public nameFor(connectedAccount: IConnectedAccount) {
    return ConnectedAccountsListComponent.CONNECTED_ACCOUNT_TYPES.find(t => t.name === connectedAccount.AccountType).description;
  }
}
