export class PatientRequest {
  id: number;
  PatientID: number;
  PortalID: Date;
  RequestDate: Date;
  ResultDate: Date;
  SourceID: number;
  RequestExternalID: number;
  PatientExternalID: number;
  RequestResults: string;
  RequestResultsJSON: { testResult: 1 | 2 | 3 | 4; testTemplateName: 'LabPCRTest' | 'LabAntibodyTest' };
  RequestNo: number;
  Note: string;
  StatusID: number;
  CreatedOn: Date;
  CreatedBy: string;
  EditedOn: Date;
  EditedBy: string;
  patientName?: string;
}
