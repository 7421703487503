<div class="container pricing">
  <div class="pricing-box col-12 mt-4">
    <h2 class="pricing-title text-center">Unsere Preise</h2>
  
    <div class="row">
      <div id="pBox" class="col-lg-3 col-md-6 mb-5 mb-md-0 pricebox">
        <div class="top">
          <div class="name">FREE</div>
          <div class="price">0€</div>
          <div class="disclaimer">pro Monat zzgl. USt.</div>
        </div>
        <div class="bottom">
          <div class="bulletpoint">
            <span class="yellow">- </span><span class="perks">0 SMS pro Monat</span>
          </div>
          <div class="bulletpoint">
            <span class="yellow">- </span><span class="perks">Daten 1 Woche abrufbar</span>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-6 mb-5 mb-md-0 pricebox">
        <div class="top">
          <div class="name">MINI</div>
          <div class="price">9,90€*</div>
          <div class="disclaimer">pro Monat zzgl. USt.</div>
        </div>
        <div class="bottom">
          <div class="bulletpoint">
            <span class="yellow">- </span><span class="perks">50 SMS pro Monat</span>
          </div>
          <div class="bulletpoint">
            <span class="yellow">- </span><span class="perks">Daten 3 Monate abrufbar</span>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-6 mb-5 mb-md-0 pricebox">
        <div class="top">
          <div class="name">BASIC</div>
          <div class="price">14,90€*</div>
          <div class="disclaimer">pro Monat zzgl. USt.</div>
        </div>
        <div class="bottom">
          <div class="bulletpoint">
            <span class="yellow">- </span><span class="perks">120 SMS pro Monat</span>
          </div>
          <div class="bulletpoint">
            <span class="yellow">- </span><span class="perks">Daten 1 Jahr abrufbar</span>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-6 mb-5 mb-md-0 pricebox">
        <div class="top">
          <div class="name">PLUS</div>
          <div class="price">24,90€*</div>
          <div class="disclaimer">pro Monat zzgl. USt.</div>
        </div>
        <div class="bottom">
          <div class="bulletpoint">
            <span class="yellow">- </span><span class="perks">200 SMS pro Monat</span>
          </div>
          <div class="bulletpoint">
            <span class="yellow">- </span><span class="perks">Daten 5 Jahre abrufbar</span>
          </div>
        </div>
      </div>
    </div>
    
    <p class="margin-top text--size14">
      * Preise exkl. USt. Jährliche Zahlung im Voraus.
    </p>
  </div>
  </div>