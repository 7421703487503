import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RequestsViewComponent } from './containers/requests-view/requests-view.component';
import { RequestsComponent } from './components/requests/requests.component';
import { RequestsClientService } from './clients/requests-client.service';
import { RequestsService } from './services/requests.service';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatLegacyPaginatorModule as MatPaginatorModule } from '@angular/material/legacy-paginator';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { RequestButtonViewComponent } from './containers/request-button-view/request-button-view.component';
import { RequestButtonComponent } from './components/request-button/request-button.component';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatSortModule } from '@angular/material/sort';
import { RequestsDialogComponent } from './components/requests-dialog/requests-dialog.component';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatStepperModule } from '@angular/material/stepper';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RequestsContactListComponent } from './components/requests-contact-list/requests-contact-list.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { StatusFilterPipe } from './pipes/status-filter.pipe';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { RequestFilterPipe } from './pipes/request-filter.pipe';

@NgModule({
  // entryComponents: [RequestsViewComponent, RequestsDialogComponent],
  declarations: [
    RequestsViewComponent,
    RequestsComponent,
    RequestButtonViewComponent,
    RequestButtonComponent,
    RequestsDialogComponent,
    RequestsContactListComponent,
    StatusFilterPipe,
    RequestFilterPipe
  ],
  providers: [RequestsClientService, RequestsService],
  imports: [
    CommonModule,
    MatCheckboxModule,
    MatDialogModule,
    MatInputModule,
    MatTableModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatButtonModule,
    MatSortModule,
    MatStepperModule,
    MatRadioModule,
    MatDatepickerModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [RequestsViewComponent, RequestButtonViewComponent, RequestsContactListComponent]
})
export class RequestsModule {}
