import { Directive, ElementRef, Input, OnInit, HostListener } from '@angular/core';
import { XConfigShortcode } from '../classes/xconfig-shortcode';
import { ShortcodeService } from '../services/shortcode.service';
import { NgModel } from '@angular/forms';

@Directive({
	selector: '[shortcut]',
	providers: [ NgModel ]
})
export class MacroDirective implements OnInit {
	element: ElementRef;
	data;
	prevKey = false;

	constructor(element: ElementRef, private shortCodeData: ShortcodeService, private ngModel: NgModel) {
		this.element = element;
		this.data = shortCodeData.shortCodeMap;
	}

	ngOnInit() {}

  @HostListener('keyup', ['$event']) onKeyUp(event: KeyboardEvent) {
    if (event.key === '#') {
      if (this.prevKey) {
        this.prevKey = false;
        let temp = this.element.nativeElement.value;
        temp = temp.replace(/\r?\n|\r/g, " ");
        temp = temp.split('##')[0];
        temp = temp.split(' ');
        const shortCode = temp[temp.length - 1];
        const shortCodeLower = shortCode.toLowerCase();

				if (this.shortCodeData.shortCodeMap[shortCodeLower]) {
					const newValue = this.element.nativeElement.value.replace(
						`${shortCode}##`,
						this.shortCodeData.shortCodeMap[shortCodeLower].ShortcutText + ' '
					);

					this.element.nativeElement.value = newValue;
					const length = this.element.nativeElement.value.length;
					this.element.nativeElement.setSelectionRange(length, length);
					this.ngModel.update.emit(newValue);
				}
			} else {
				this.prevKey = true;
			}
		} else {
			this.prevKey = false;
		}
	}
}
