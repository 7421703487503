import { Component, OnInit, Input } from '@angular/core';
import { SmsStatisticMonthly } from '../../clients/sms-stats-client.service';

@Component({
  selector: 'app-sms-stats',
  templateUrl: './sms-stats.component.html',
  styleUrls: ['./sms-stats.component.css']
})
export class SmsStatsComponent implements OnInit {
  @Input()
  smsStats: SmsStatisticMonthly[];

  @Input()
  limit: number;

  displayedColumns = ['month', 'year', 'amount'];
  months = [
    'Januar',
    'Februar',
    'März',
    'April',
    'Mai',
    'Juni',
    'Juli',
    'August',
    'September',
    'Oktober',
    'November',
    'Dezember'
  ];

  constructor() {}

  ngOnInit() {
  }
}
