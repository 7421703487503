<div class="container">
  <div class="row justify-content-center align-items-center h-100">
    <div id="uploadBox" class="centered-box col col-12">
      <div class="text-center">
        <mat-radio-group [(ngModel)]="fileSendType">
          <mat-radio-button [value]="2" [disabled]="fileSentStatus != 0">BefundExpress Account</mat-radio-button>
          <mat-radio-button [value]="1" [disabled]="fileSentStatus != 0">E-Mail</mat-radio-button>
        </mat-radio-group>
      </div>

      <div *ngIf="fileSendType == 1">
        <form #sendFileForm="ngForm" (ngSubmit)="sendFile($event)" *ngIf="fileSentStatus < 2">
          <div class="form-group" *ngIf="!uploadData.filesToReSend">
            <app-file-uploader
              [maxFileAmount]="8"
              [maxFileSize]="10 * 1024 * 1024"
              (fileAddedEvt)="fileAdd($event)"
            ></app-file-uploader>
          </div>
          <div
            class="col col-10 offset-1 text-center"
            style="margin: 0px;"
            *ngIf="getFilesToSend() && !uploadData.signedFiles && !uploadData.filesToReSend"
          >
            <mat-list dense>
              <mat-list-item *ngFor="let file of getFilesToSend(); index as index" class="font-size-90p">
                <div class="remove-file" (click)="removeFile(index, 'normal')">
                  <i class="fa fa-times"></i>
                </div>
                <span class="hover-cursor">{{ file?.name }} </span>
              </mat-list-item>
            </mat-list>
          </div>
          <div
            class="col col-10 offset-1 text-center"
            style="margin: 0px;"
            *ngIf="uploadData.signedFiles && !uploadData.filesToReSend"
          >
            <mat-list dense>
              <mat-list-item *ngFor="let file of uploadData.signedFiles; index as index" class="font-size-90p">
                <div class="remove-file" (click)="removeFile(index, 'signed')">
                  <i class="fa fa-times"></i>
                </div>
                <span>{{ file?.fileName }} </span>
                <i style="color: green;" class="fa fa-check"></i>
              </mat-list-item>
            </mat-list>
          </div>
          <div class="col col-10 offset-1 text-center" style="margin: 0px;" *ngIf="uploadData.filesToReSend">
            <mat-list dense>
              <mat-list-item *ngFor="let file of uploadData.filesToReSend; index as index" class="font-size-90p">
                <div class="remove-file" (click)="removeFile(index, 'reSend')">
                  <i class="fa fa-times"></i>
                </div>
                <span>{{ file?.fileName }} </span>
              </mat-list-item>
            </mat-list>
          </div>
          <!-- <div class="text-center">
            <button
              mat-raised-button
              class="w-100"
              color="primary"
              [disabled]="!getFilesToSend()"
              (click)="showSignatureDialog(0)"
            >
              Dokumente signieren
            </button>
          </div> -->

          <div class="form-group col col-12 alert alert-danger font-size-75p" *ngIf="!wantsToBeNotified" role="alert">
            <div style="font-weight: bold;">
              Dieser Empfänger will nicht kontaktiert werden!
            </div>
          </div>

          <!-- <mat-checkbox
            [(ngModel)]="signDocuments"
            name="signDocuments"
            [disabled]="
              (!getFilesToSend() && !signDocuments) ||
              (!uploadData.signedFiles && signDocuments)
            "
            (click)="signAll()"
            >Dateien Signieren</mat-checkbox
          > -->

          <mat-form-field hideRequiredMarker>
            <input
              type="email"
              #emailField
              email
              matInput
              placeholder="Empfänger E-Mail"
              [(ngModel)]="uploadData.recipientEmail"
              #recipientEmail="ngModel"
              required
              name="recipientEmail"
            />
            <i
              class="fa fa-address-book hover-cursor input-right-icon"
              (click)="showFavoritesDialog()"
              title="Kontakte"
            ></i>
            <mat-error *ngIf="recipientEmail?.errors?.email && recipientEmail?.touched"
              >Bitte geben Sie eine gültige EMail Adresse ein</mat-error
            >
          </mat-form-field>

          <mat-form-field class="halfWidth" hideRequiredMarker>
            <input
              matInput
              placeholder="Code"
              [(ngModel)]="uploadData.uploadPIN"
              #uploadPIN="ngModel"
              required
              pattern="[A-Za-z0-9_-]{6,20}"
              name="uploadPIN"
            />

            <mat-error *ngIf="uploadPIN?.errors?.pattern && uploadPIN?.touched"
              >Der Code muss aus 6-20 Buchstaben und Zahlen bestehen.
            </mat-error>
          </mat-form-field>
          <mat-checkbox class="fillSpace" [(ngModel)]="sendSms" name="sendSms">Code per SMS</mat-checkbox>

          <mat-form-field *ngIf="!sendSms" hideRequiredMarker>
            <input
              matInput
              placeholder="Code bestätigen"
              [(ngModel)]="uploadData.confirmUploadPIN"
              #confirmUploadPIN="ngModel"
              pattern="[A-Za-z0-9_-]{6,20}"
              required
              name="confirmUploadPIN"
            />
            <mat-error *ngIf="uploadPIN?.value != confirmUploadPIN?.value"
              >Die eingegebenen Codes stimmen nicht überein
            </mat-error>
          </mat-form-field>

          <div *ngIf="sendSms">
            <mat-form-field hideRequiredMarker>
              <input
                type="tel"
                tel
                matInput
                placeholder="Empfänger Telefonnummer"
                [(ngModel)]="uploadData.recipientPhoneNo"
                #recipientPhoneNo="ngModel"
                [required]="sendSms"
                name="recipientPhoneNo"
              />
              <i
                class="fa fa-plus hover-cursor input-right-icon"
                (click)="showAddFavoritesDialog(uploadData.recipientEmail, uploadData.recipientPhoneNo)"
                title="Hinzufügen"
              ></i>
              <mat-error *ngIf="recipientPhoneNo?.touched">Bitte geben Sie eine gültige Telefonnummer ein</mat-error>
            </mat-form-field>
          </div>
          <mat-form-field>
            <textarea
              #senderComments
              matInput
              placeholder="Hinweise"
              [(ngModel)]="uploadData.senderComments"
              maxlength="2000"
              name="senderComments"
              matTextareaAutosize
              matAutosizeMinRows="2"
              matAutosizeMaxRows="5"
              shortcut
            ></textarea>
          </mat-form-field>
          <mat-form-field>
            <textarea
              matInput
              placeholder="Interne Hinweise (nicht für Empfänger ersichtlich)"
              [(ngModel)]="uploadData.internalNote"
              maxlength="2000"
              name="internalNote"
              matTextareaAutosize
              matAutosizeMinRows="2"
              matAutosizeMaxRows="5"
              shortcut
            ></textarea>
          </mat-form-field>

          <mat-expansion-panel [expanded]="panelOpenState">
            <mat-expansion-panel-header>
              <mat-panel-title>
                Daten der/des Betroffenen
              </mat-panel-title>
              <mat-panel-description> </mat-panel-description>
            </mat-expansion-panel-header>

            <mat-form-field>
              <mat-select
                placeholder="Titel der/des Betroffenen"
                [(ngModel)]="uploadData.patientTitle"
                name="patientTitle"
                #patientTitle="ngModel"
              >
                <mat-option></mat-option>
                <mat-option *ngFor="let title of personTitles" [value]="title">
                  {{ title }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field>
              <input
                type="text"
                matInput
                placeholder="Vorname der/des Betroffenen"
                [(ngModel)]="uploadData.patientFirstName"
                #patientFirstName="ngModel"
                name="patientFirstName"
              />
            </mat-form-field>
            <mat-form-field>
              <input
                type="text"
                matInput
                placeholder="Nachname der/des Betroffenen"
                [(ngModel)]="uploadData.patientLastName"
                #patientLastName="ngModel"
                name="patientLastName"
              />
            </mat-form-field>
          </mat-expansion-panel>

          <!--mat-checkbox [(ngModel)]="sendETresor" name="sendETresor" [disabled]="!showETresor">An ETresor mitsenden</mat-checkbox-->

          <!-- <mat-error *ngIf="!validNumber">Bitte geben Sie eine gültige Telefonnummer   ein</mat-error>
          <mat-error *ngIf="!!fileSendError"></mat-error> -->

          <div class="form-group col col-12 alert alert-danger font-size-75p" *ngIf="!validNumber" role="alert">
            <div>Bitte geben Sie eine gültige Telefonnummer ein</div>
          </div>
          <div class="form-group col col-12 alert alert-danger font-size-75p" *ngIf="!!fileSendError" role="alert">
            Fehler beim Versenden. Bitte überprüfen Sie Ihre Eingaben
          </div>
          <div class="button-row col col-10 offset-1 mt-3">
            <button
              *ngIf="fileSentStatus == 0"
              mat-raised-button
              class="w-100"
              color="primary"
              [disabled]="
                !sendFileForm.form.valid ||
                (!getFilesToSend() && !uploadData.signedFiles && !uploadData.filesToReSend) ||
                (!sendSms ? uploadData.uploadPIN != uploadData.confirmUploadPIN : !uploadData.recipientPhoneNo)
              "
            >
              Senden
            </button>
            <button class="w-100" color="primary" mat-raised-button disabled *ngIf="fileSentStatus == 1">
              <i class="fa fa-spinner fa-spin fa-pulse"></i>
            </button>
          </div>
        </form>
        <div class="text-center" *ngIf="fileSentStatus == 2">
          <div class="form-group col col-12">Code:&nbsp;{{ getPin() }}</div>
          <div class="form-group col col-12 alert alert-success" role="alert">
            Datei wurde erfolgreich versendet!
          </div>
          <button mat-button class="w-100" color="primary" (click)="resetFileSentStatus()">
            Zurück
          </button>
        </div>
      </div>
      <div *ngIf="fileSendType == 2">
        <app-send-file></app-send-file>
      </div>
    </div>
    <div id="pricing">
      <app-pricing></app-pricing>
    </div>
  </div>
  <!--<div id="lead_form">testing</div> -->
</div>

<!-- [disabled]="!sendFileForm.form.valid || !uploadData.fileToSend || uploadData.uploadPIN != uploadData.confirmUploadPIN" -->
