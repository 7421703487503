import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'app-request-button',
  templateUrl: './request-button.component.html',
  styleUrls: ['./request-button.component.css']
})
export class RequestButtonComponent implements OnInit {
  @Output() sendRequest = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  sendPatientRequest() {
    this.sendRequest.emit();
  }
}
