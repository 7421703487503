<!-- <nav>
  <div class="container-fluid">
    <div class="row text-center">
      <div class="col col-6 offset-3">
        <a routerLink="/upload" routerLinkActive="active">Versand</a>
        <a routerLink="/reportLog" routerLinkActive="active">Log</a>
      </div>
      <div class="col col-3 text-right">
        <button mat-button [matMenuTriggerFor]="userMenu">
          {{userName}}
          <i class="fa fa-angle-down"></i>
        </button>
        <mat-menu #userMenu="matMenu">
          <button mat-menu-item routerLink="/profile">Mein Profil</button>
          <button mat-menu-item (click)="logout($event)">Abmelden</button>
        </mat-menu>
      </div>
    </div>
  </div>
</nav> -->

<nav class="navbar navbar-expand-sm navbar-dark align-items-start">
  <div class="container-fluid nav-cont p-0">
    <!-- <div class ="profile-info">
        <p>{{userName}}</p>
        <p>{{labUserID}}</p>
    </div> -->
    <ul class="navbar-nav text--size0900 ml-auto bg-light rounded">
      <li class="nav-item p-0">
        <a routerLink="/upload" routerLinkActive="active" class="nav-link">Versand</a>
      </li>
      <li class="nav-item p-0">
        <a routerLink="/reportLog" routerLinkActive="active" class="nav-link">Journal</a>
      </li>
      <!----class="nav-item p-0">
      <a routerLink="/admission" routerLinkActive="active" class="nav-link">Admission</a>
    -->
      <li class="nav-item dropdown p-0 hover-cursor front">
        <div class="btn-group" ngbDropdown role="group">
          <a class="nav-link" role="button" ngbDropdownToggle>Einstellungen</a>
          <div class="dropdown-menu" ngbDropdownMenu>
            <a ngbDropdownItem class="dropdown-item" routerLink="/profile" routerLinkActive="active">Mein Profil</a>
            <a ngbDropdownItem class="dropdown-item" routerLink="/textblock" routerLinkActive="active">Text-Blöcke</a>
            <a ngbDropdownItem class="dropdown-item" routerLink="/favorites" routerLinkActive="active">Kontakte</a>
            <a ngbDropdownItem class="dropdown-item" routerLink="/statistics" routerLinkActive="active"
              >Versand Statistik</a
            >
          </div>
        </div>
      </li>
      <li class="nav-item p-0">
        <a (click)="logout($event)" href="#" class="nav-link">Abmelden</a>
      </li>
    </ul>
  </div>
</nav>
