import { Component, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ActivatedRoute } from '@angular/router';
import { Favorite } from '../../shared/classes/favorite';
import { FavoritesService } from '../../shared/services/favorites.service';
import { FavoritesDetailComponent } from './favorites-detail/favorites-detail.component';

@Component({
  selector: 'app-favorites',
  templateUrl: './favorites.component.html',
  styleUrls: ['./favorites.component.css']
})
export class FavoritesComponent implements OnInit {
  public clickLock = false;
  private favorites;
  public filteredFavorites;
  public searchName;

  constructor(private route: ActivatedRoute, private favoritesService: FavoritesService, private dialog: MatDialog) {}

  async ngOnInit() {
    await this.initData();
    this.route.queryParams.subscribe((params) => {
      const uid = params['uid'],
        tid = params['tid'],
        res = params['result'];

      // console.log(`UserID: ${uid}, TransactionID: ${tid}, Result: ${res}`);
    });
  }

  private async initData() {
    this.favorites = await this.getFavorites();
    this.favorites.sort((a, b) => (a.LastNameEn > b.LastNameEn ? 1 : b.LastNameEn > a.LastNameEn ? -1 : 0));
    this.filteredFavorites = this.favorites.slice(0, 100);
    // console.log('init');
  }

  public showDetailDialog() {
    if (
      this.dialog.openDialogs.some((openDialog) => openDialog.componentInstance instanceof FavoritesDetailComponent)
    ) {
      return;
    }
    const dialogRef = this.dialog.open(FavoritesDetailComponent, {});
    dialogRef.afterClosed().subscribe(async ({ reOpen, favoriteResult } = {}) => {
      await this.initData();
      if (reOpen) {
        this.edit(favoriteResult);
        // console.log('dialog closed');
      }
    });
  }

  async edit(data) {
    // console.log(data);
    if (this.clickLock) {
      // console.log('Clicklock');
      return;
    }
    this.clickLock = true;
    const dialogRef = this.dialog.open(FavoritesDetailComponent, {
      data: new Favorite(data)
    });
    this.clickLock = false;
    dialogRef.afterClosed().subscribe(async ({ reOpen, favoriteResult } = {}) => {
      await this.initData();
      this.filterFavorites(this.searchName);
      if (reOpen) {
        this.edit(favoriteResult);
        // console.log('dialog closed');
      }
    });
  }

  public getFavorites() {
    return this.favoritesService.getFavorites();
  }

  public filterFavorites(searchText) {
    const search = searchText?.replace(/ /g, ''); // Filter out whitespaces not correct fix
    // console.log(search);
    const filtered = this.favorites.filter((item) => {
      return !searchText || (item.FirstNameEn + item.LastNameEn).toLowerCase().indexOf(search.toLowerCase()) > -1;
    });
    this.filteredFavorites = filtered.slice(0, 100);
    return filtered;
  }
}

/*
				!searchText ||
				item.FirstNameEn.toLowerCase().indexOf(search.toLowerCase()) > -1 ||
				item.PersonTitle.toLowerCase().indexOf(search.toLowerCase()) > -1 ||
				item.LastNameEn.toLowerCase().indexOf(search.toLowerCase()) > -1
				*/
