import { Injectable } from '@angular/core';
import { AzureServiceService } from '../../../shared/services/azure-service.service';

@Injectable({
	providedIn: 'root'
})
export class AdmissionService {
	constructor(private azureService: AzureServiceService) {}

	public hasAccount = false;
	private externalServicesTable = this.azureService.client.getTable('CuberryExternalServices');
	public templates;

	async authenticate(loginData) {
		try {
			const res = await this.azureService.client.invokeApi('admissionauth', {
				body: loginData,
				method: 'POST'
			});

			console.log('Success', res.message);
			this.hasAccount = true;
		} catch (error) {
			console.log('Error', error.message);
		}
	}

	public getAccount() {
		return this.hasAccount;
	}

	async checkUserHasAccount() {
		const res = await this.externalServicesTable.read({
			CheckActive: true,
			ServiceID: 401
		});

		this.hasAccount = res.hasEntry;

		return this.hasAccount;
	}

	async removeAccount() {
		const res = await this.azureService.client.invokeApi('admissionauth', {
			method: 'DELETE'
		});

		this.hasAccount = false;
	}

	async read() {
		try {
			const res = await this.azureService.client.invokeApi('admission', {
				parameters: {
					requestType: 'templates'
				},
				method: 'GET'
			});

			this.templates = res.result;
		} catch (e) {
			console.log('Error', e);
		}
	}

	async createSurveyFromTemplate(options) {
		console.log('options:', options);
		try {
			const res = await this.azureService.client.invokeApi('admission', {
				// parameters: {},
				body: options,
				method: 'POST'
			});

			console.log('success', res);
		} catch (error) {
			console.log('error', error);
		}
	}

	async getSurveys(options) {
		const res = await this.azureService.client.invokeApi('admission', {
			parameters: options,
			method: 'GET'
		});
		console.log(res);
		return res;
	}

	public getTemplates() {
		return this.templates;
	}
}
