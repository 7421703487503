import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { PatientRequest } from '../../../shared/classes/patient-request';
import { AzureServiceService } from '../../../shared/services/azure-service.service';
@Injectable({
  providedIn: 'root'
})
export class RequestsClientService {
  requestsTable;

  constructor(private azureService: AzureServiceService, private http: HttpClient) {
    this.requestsTable = azureService.client.getTable('CCPatientRequest');
  }

  readRequests(params: any = {}): Observable<any[]> {
    return this.http.get<PatientRequest[]>(`${environment.azureAppURL}/tables/CCPatientRequest`, {
      params,
      headers: {
        'ZUMO-API-VERSION': '2.0.0',
        'X-ZUMO-AUTH': this.azureService.client.currentUser.mobileServiceAuthenticationToken
      }
    });
  }

  async deleteRequest(request) {
    console.log('req to delete: ', request);
    const params = { id: request.id };
    return this.http
      .delete<any>(`${environment.azureAppURL}/tables/CCPatientRequest`, {
        params,
        headers: {
          'ZUMO-API-VERSION': '2.0.0',
          'X-ZUMO-AUTH': this.azureService.client.currentUser.mobileServiceAuthenticationToken
        }
      })
      .toPromise();
  }

  async sendRequest(patientID: number) {
    const request = new PatientRequest();
    request.PatientID = patientID;
    return await Promise.resolve(this.requestsTable.insert(request));
  }

  sendRequestCollection(requests: PatientRequest[]): Observable<any> {
    return this.http.post(`${environment.azureAppURL}/api/sendpatientrequests`, requests, {
      headers: {
        'ZUMO-API-VERSION': '2.0.0',
        'X-ZUMO-AUTH': this.azureService.client.currentUser.mobileServiceAuthenticationToken
      }
    });
  }

  async update(request) {
    return await Promise.resolve(this.requestsTable.update(request));
  }

  async getPdf(parameters, templateName) {
    const formData = new FormData();
    formData.append('parameters', parameters);
    return await Promise.resolve(
      this.http
        .post(
          `${environment.azureAppURL}/api/pdfengine`,
          { parameters, templateName },
          {
            headers: {
              'ZUMO-API-VERSION': '2.0.0',
              'X-ZUMO-AUTH': this.azureService.client.currentUser.mobileServiceAuthenticationToken
            }
          }
        )
        .toPromise()
    );
  }
}
