import { Component, OnInit } from '@angular/core';
import { AzureServiceService } from '../../shared/services/azure-service.service';

import { PortalTypes } from '../../shared/collections/portal-types';
import { PersonTitles } from '../../shared/collections/person-titles';
import { IRegistrationData, RegistrationService } from 'src/app/shared/services/registration.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { IMessageDialogData, MessageDialogComponent } from 'src/app/shared/components/message-dialog/message-dialog.component';

@Component({
	selector: 'app-register',
	templateUrl: './register.component.html',
	styleUrls: [ './register.component.css' ]
})
export class RegisterComponent implements OnInit {
	public registerData: IRegistrationData;
	public registerPending = false;
	public registerSuccess = false;
	public registerErrorMsg = '';

	public isConnected = false;
	public connectedAccountType: string;
	public lockedFields: string[] = [];
	public accountTypeNames = { 'equip4ordi': 'Equip4Ordi' };

	public salutations = [ { id: 1, value: 'Herr' }, { id: 2, value: 'Frau' } ];
	public titles = PersonTitles;

	private registerErrors = {
		'1': 'Ungültiger Username (nur Buchstaben und Zahlen, mind. 4 Zeichen)!',
		'2': 'Ungültiges Passwort (mind. 7 Zeichen)!',
		'4': 'Dieser Username ist bereits vergeben!',
		'5': 'Diese EMail wird bereits verwendet!',
		'6': 'Interner Fehler: Registrierungstoken ungültig'
	};

	public physicianTypes = PortalTypes.get();

	public phonePrefixes = [
		{ text: 'AUT (+43)', value: '0043' },
		{ text: 'GER (+49)', value: '0049' },
		{ text: 'CH (+41)', value: '0041' }
	];

	constructor(private azureClient: AzureServiceService, private registrationService: RegistrationService, private route: ActivatedRoute, private router: Router, private dialog: MatDialog) {}

	ngOnInit() {
		// Fill data fields with default data
		this.registerData = this.registrationService.getRegistrationDefaultData();
		// Determine if this is a connected registration or a normal one
		if(this.route.snapshot.paramMap.get("connectedAccountType")) {
			// Let the template know
			this.isConnected = true;
			this.connectedAccountType = this.route.snapshot.paramMap.get("connectedAccountType");
			// Validate we have prefill token and data if we're coming from a connected account
			if(!this.registrationService.getRegistrationPrefillData() || !this.registrationService.getRegistrationToken()) {
				throw new Error("No registration data or token provided for connected accounts registration");
			}
			const prefillData = this.registrationService.getRegistrationPrefillData();
			// Add special option to titles so any specified freetext title is valid
			if(prefillData.title) this.titles.push(prefillData.title);
			// Lock all the fields for which prefill data has been provided
			this.lockedFields = Object.keys(this.registrationService.getRegistrationPrefillData());
			// Prefill fields
			this.registerData = { ...this.registerData, ...prefillData };
			// Clear data from registration service
			this.registrationService.setRegistrationPrefillData(null);
		}
	}

	public async registerClick(event) {
		try {
			this.registerErrorMsg = '';
			this.registerPending = true;
			if (!this.isLabOrPharmacy(this.registerData.fieldOfPractice)) {
				delete this.registerData.portalName;
			}
			const registerResult = await this.registrationService.register(this.registerData, this.isConnected ? this.registrationService.getRegistrationToken() : null);
			this.registerSuccess = true;
			this.registrationService.setRegistrationToken(null);
		} catch (err) {
			const errors = JSON.parse(err.request.response);
			this.registerErrorMsg = errors.map((error) => this.registerErrors[error.Error] || '').join('\r\n');
		} finally {
			this.registerPending = false;
		}
	}

	public isLabOrPharmacy(fieldOfPracticeID) {
		return +fieldOfPracticeID === 45 || +fieldOfPracticeID === 33;
	}

	public alreadyRegisteredClick() {
		if(!this.isConnected) {
			// In a normal registration form, "already registered" just redirects to login
			this.router.navigate(['login']);
		}else {
			// In a connected account registration, the user is supposed to connect an E4O account by using the settings while logged in.
			// Provide a guide to that end in a dialog
			const infoDialog = this.dialog.open(
				MessageDialogComponent,
				{
					width: '500px',
					data: {
						title: 'Bereits registriert?',
						message: 'Falls Sie bereits ein BefundExpress-Konto haben, loggen Sie sich bitte normal ein und verbinden Sie Ihr ' + this.accountTypeNames[this.connectedAccountType] + '-Konto unter Einstellungen ➡ Mein Profil ➡ Schnittstellen-Benutzer ➡ Neuen Account verbinden.',
						buttonLabel: 'Zum Login',
						buttonAction: () => {
							infoDialog.close();
							this.router.navigate(['login']);
						}
					} as IMessageDialogData
				}
			)
		}
		// Make sure the link doesn't reload the page
		return false;
	}

	// agbClick(event) {
	//   console.log(event);
	// }
}
