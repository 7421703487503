<div class="container">
  <div class="row justify-content-center align-items-center my-auto mx-auto h-100">

    <div class="text-box col-12 mt-4">
      <h4>Text-Blöcke</h4>
      <label>Die Textblöcke können in allen mehrzeiligen Textfeldern durch die Eingabe des Kürzels gefolgt von ## verwendet werden, z.B.: abc##</label>
      <div class="d-flex flex-row-reverse">
          <!-- <i class="fa fa-plus-circle fa-2x hover-cursor"></i> -->
          <button mat-button color="primary" (click)="showDetailDialog()">Hinzufügen</button>
        </div>
      <div class="row">
        <div class="col col-12 col-md-12">
          <mat-list>
              <mat-list-item *ngFor="let code of this.getShortCodes()" class="hover-cursor item" (click)="edit(code)">
              <h3 matLine>{{code.ShortcutKey}}</h3>
              <p matLine>
                {{code.ShortcutText}}
              </p>
              <mat-divider></mat-divider>
            </mat-list-item>
            
          </mat-list>
        </div>
      </div>
    </div>

  </div>
</div>