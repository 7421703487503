import { Component, OnInit } from "@angular/core";
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import { PDFDocument } from 'pdf-lib';

import { AzureServiceService } from "../../../shared/services/azure-service.service";
import { PortalsService } from "../../../shared/services/portals.service";
import { PortalTypes } from "../../../shared/collections/portal-types";
import { PersonTitles } from "../../../shared/collections/person-titles";
import { ServiceProviderTypes } from "../../../shared/collections/service-provider-types";
import { PortalFilterDialogComponent } from "../portal-filter-dialog/portal-filter-dialog.component";
import { UploadComponent } from "../upload.component";
import { GroupsService } from "../../../shared/services/groups.service";
import { GroupMember } from "../../../shared/classes/group-member";
import { SignDialogComponent } from "../sign-dialog/sign-dialog.component";
@Component({
  selector: "app-send-file",
  templateUrl: "./send-file.component.html",
  styleUrls: ["./send-file.component.css"],
})
export class SendFileComponent implements OnInit {
  public fileSentStatus = 0;
  public uploadData = {
    filesToReSend: null,
    reSendID: null,
    patientTitle: null,
    patientFirstName: null,
    patientLastName: null,
    filesToSend: null,
    recipientPortal: null,
    senderComments: '',
    internalNote: '',
    signedFiles: null
  };
  public convertedFiles;
  public signSubmit = false;
  public signDocuments = false;

  public portalTypes = PortalTypes;
  public serviceProviderTypes = ServiceProviderTypes;
  public personTitles = PersonTitles;
  public portalTypeFilter = null;
  private portals: Array<any> = [];
  private groups: Array<any> = [];
  private members: Array<any>;
  public filteredPortals: Array<any> = [];
  constructor(
    private azureClient: AzureServiceService,
    private portalsService: PortalsService,
    private dialog: MatDialog,
    private uploadComp: UploadComponent,
    private groupsService: GroupsService
  ) {}

  async ngOnInit() {
    this.portals = await this.portalsService.getAll();
    await this.initGroups();
    this.filteredPortals = [...this.portals];
    this.setData();
  }

  private setData() {
    if (history.state.data) {
      this.uploadData.filesToReSend = history.state.data.files;
      this.uploadData.reSendID = history.state.data.idx;
      this.uploadData.senderComments = history.state.data.senderComments;
      this.uploadData.patientTitle = history.state.data.patientTitle;
      this.uploadData.patientFirstName = history.state.data.patientFirstName;
      this.uploadData.patientLastName = history.state.data.patientLastName;
      this.uploadData.recipientPortal = this.filteredPortals.find(
        (portal) => portal.Name === history.state.data.recipientName
      );
    }
  }

  private async initGroups() {
    this.groups = await this.groupsService.getUserGroups();
    console.log('Groups:', this.groups);
    this.members = await this.groupsService.getGroupMembers(this.groups.map((item) => item.id));
    console.log('Members:', this.members);
  }

  public async sendFile(form?) {
    try {
      // console.log(this.uploadData.recipientPortal);
      // console.log(this.uploadData.fileToSend);
      if (this.signSubmit) {
        return;
      }
      if (this.fileSentStatus === 1) {
        return;
      }
      if (!this.azureClient.filesToUpload && !this.uploadData.signedFiles && !this.uploadData.filesToReSend) {
        return;
      }
      this.fileSentStatus = 1;
      this.uploadComp.setFileSentStatus(1);
      if (this.azureClient.filesToUpload?.length > 0 && !this.signDocuments) {
        await this.azureClient.sendFileToPortal(this.azureClient.filesToUpload, 'api/fileservice', this.uploadData);
      }
      if ((this.uploadData.signedFiles?.length > 0 && this.signDocuments) || this.uploadData.filesToReSend) {
        await this.azureClient.uploadFormDataFileSigned(this.uploadData, 'signedservice');
      }

      // console.log(form);
      this.resetUploadData();
      console.log('reset upload');
      // form.resetForm();
      if (form) {
        form.resetForm();
      }
      this.fileSentStatus = 2;
      this.uploadComp.setFileSentStatus(2);
    } catch (err) {
      console.log(err);
      this.fileSentStatus = 0;
      this.uploadComp.setFileSentStatus(0);
    }
  }

  private resetUploadData() {
    this.uploadData = {
      filesToReSend: null,
      reSendID: null,
      patientTitle: null,
      patientFirstName: null,
      patientLastName: null,
      filesToSend: null,
      recipientPortal: null,
      senderComments: '',
      internalNote: '',
      signedFiles: null
    };
    this.azureClient.filesToUpload = null;
    this.azureClient.filesIsEmpty = true;
    this.signSubmit = false;
    this.signDocuments = false;
  }

  public resetFileSentStatus() {
    this.fileSentStatus = 0;
    this.uploadComp.setFileSentStatus(0);
  }

  public getFilesToSend() {
    return this.azureClient.filesToUpload;
  }

  public fileAdd(files) {
    const fileArr: File[] = Array.from(files);
    if (this.azureClient.filesIsEmpty) {
      this.azureClient.filesToUpload = fileArr;
      this.azureClient.setFiles();
    } else {
      this.azureClient.filesToUpload.push(...fileArr);
    }
  }

  public signAll() {
    setTimeout(() => {
      if (this.signDocuments) {
        this.showSignatureDialog(0);
      } else {
        this.uploadData.signedFiles = null;
      }
    }, 200);
  }

  public removeFile(index, type) {
    if (type === 'normal') {
      this.azureClient.filesToUpload.splice(index, 1);
      if (this.azureClient.filesToUpload.length === 0) {
        this.azureClient.filesToUpload = null;
      }
    } else if(type === 'signed') {
      this.uploadData.signedFiles.splice(index, 1);
      if (this.uploadData.signedFiles.length === 0) {
        this.uploadData.signedFiles = null;
      }
    } else if(type === 'reSend'){
      this.uploadData.filesToReSend.splice(index, 1);
      if (this.uploadData.filesToReSend.length === 0) {
        this.uploadData.filesToReSend = null;
      }
    }
    this.azureClient.filesIsEmpty = true;
  }

  //PDF Details
  readFile(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);

      reader.readAsArrayBuffer(file);
    });
  }

  async getNumPages(file) {
    const arrayBuffer = await this.readFile(file) as any;

    const pdf = await PDFDocument.load(arrayBuffer);

    return pdf.getPages().length;
  }

  public displayFn(portal): string | undefined {
    return portal ? portal.Name : undefined;
  }

  public showPortalFilterDialog() {
    const dialogRef = this.dialog.open(PortalFilterDialogComponent, {
      width: '500px',
      data: {
        portals: this.portals,
        groups: this.groups,
        members: this.members,
      },
      // disableClose: true
    });
    dialogRef.afterClosed().subscribe((result) => {
      // console.log(result);
      if (!result) {
        return;
      }
      this.uploadData.recipientPortal = result;
      // if (!result || !result.length || !result[0].value) { return; }
      // this.uploadData.recipientPortal = result[0].value;
    });
  }

  public async convertFiles() {
    const res: any = await this.azureClient.getURLFromFile(this.azureClient.filesToUpload, 'api/fileconversion');
    console.log(res);
    const pages = await Promise.all(
      this.azureClient.filesToUpload.map(
        async (file) => await this.getNumPages(file)
      )
    );
    const filePaths = res.filePaths;
    const encIVs = res.encIVs;

    const result = await this.azureClient.getURL(filePaths, encIVs);
    console.log(result.fileUrls);
    const fileNames = res.fileNames.split(',');
    this.convertedFiles = result.fileUrls.map((url, index) => {
      return { url, fileName: fileNames[index], pages: pages[index] };
    });
  }

  public async showSignatureDialog(index) {
    if (!this.convertedFiles) {
      await this.convertFiles();
    }
    this.signSubmit = true;
    try {
      const dialogRef = this.dialog.open(SignDialogComponent, {
        width: '300px',
        height: 'auto',
        data: this.convertedFiles[index]
      });

      dialogRef.afterClosed().subscribe(async (result) => {
        if (!this.uploadData.signedFiles) {
          this.uploadData.signedFiles = [];
        }
        if (result !== 'Abort') {
          this.uploadData.signedFiles.push(result);
          //this.removeFile(index, false);
          if (index < this.convertedFiles.length - 1) {
            await this.showSignatureDialog(index + 1);
          }
        }
      });
      this.signSubmit = false;
    } catch (error) {
      console.log(error);
    }
  }
}
