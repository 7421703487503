import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

import { PromotionService } from '../services/promotion.service';
import { CuberryPromotion } from '../../../shared/classes/cuberry-promotion';
import { AzureServiceService } from '../../../shared/services/azure-service.service';

@Component({
	selector: 'app-promotion',
	templateUrl: './promotion.component.html',
	styleUrls: [ './promotion.component.css' ],
	providers: [ PromotionService ]
})
export class PromotionComponent implements OnInit {
	public promotionData: CuberryPromotion[];
	// public saveInProgress = false;
	constructor(private promotionService: PromotionService, private azureClient: AzureServiceService) {}

	@Input() saveInProgress: boolean;
	@Input() activeAboType;
	@Output() ordered = new EventEmitter<CuberryPromotion>();

	public aboName: String;
	public aboNames = {
		0: 'Free',
		4: 'Start',
		1: 'Mini',
		2: 'Basic',
		3: 'Plus'
	};

	async ngOnInit() {
		await this.getPromotions();
	}

	private async getPromotions(): Promise<CuberryPromotion[]> {
		try {
			const aboNr = this.azureClient.getUserAboType();
			console.log(this.aboNames);
			console.log(aboNr);
			this.aboName = this.aboNames[aboNr];
			const result = await this.promotionService.get();
			// console.log(this.userObject);
			console.log('aboname', this.aboName);
			this.promotionData = result;
			return result;
			// console.log(this.portalObject);
		} catch (err) {
			console.log(err);
		}
	}

	public filterUpgradePromotions(promotions = []): CuberryPromotion[] {
		return promotions.filter((item) => {
			if (item.FutureAboTypeID !== 4 && this.activeAboType !== 4) {
				return !this.activeAboType || item.FutureAboTypeID > this.activeAboType;
			} else {
				return this.activeAboType === 4
					? !this.activeAboType || item.FutureAboTypeID + 4 > this.activeAboType
					: !this.activeAboType || item.FutureAboTypeID - 4 > this.activeAboType;
			}
		});
	}

	public activePromoName(aboType: number) {
		const activePromo = this.promotionData
			? this.promotionData.find((item) => item.FutureAboTypeID === aboType)
			: null;
		return activePromo ? activePromo.PromoName : '';
	}

	public orderPromotion(promotionItem) {
		this.ordered.emit(promotionItem);
		// this.saveInProgress = true;
	}
}
