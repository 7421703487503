import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AzureServiceService } from '../../shared/services/azure-service.service';
import { FavoritesService } from '../../shared/services/favorites.service';
import { ShortcodeService } from '../../shared/services/shortcode.service';
import { ConnectedAccountsService } from '../../shared/services/connected-accounts.service';
import { RegistrationService } from 'src/app/shared/services/registration.service';

interface IOauthStateInfo {
  accountType: string;
  action: string;
  id: string;
}

@Component({
	selector: 'app-oauth',
	templateUrl: './oauth.component.html',
	styleUrls: [ './oauth.component.css' ],
	providers: [ ConnectedAccountsService ]
})
export class OauthComponent implements OnInit, AfterViewInit {
  public view = "";
  public errorDetails = "";

  private stateRegex = /(?<accountType>[^-]+)-(?<action>[^-]+)-(?<id>.+)/;
  private stateInfo: IOauthStateInfo = { accountType: "", action: "", id: "" };

  private authState: string;
  private authCode: string;

  private static readonly KEY_STATE = 'state';
  private static readonly KEY_CODE = 'code';

  private static readonly VALID_ACTIONS = ['connect', 'login'];

	constructor(
		private azureClient: AzureServiceService,
    private caService: ConnectedAccountsService,
    private registrationService: RegistrationService,
    private router: Router
	) {}

	ngOnInit() {
    try{
      // First, attempt to get state and code from the URL
      let params = new URLSearchParams(window.location.search);
      if(!params.has(OauthComponent.KEY_STATE) || !params.has(OauthComponent.KEY_CODE)) {
        throw new Error("Missing state or code in URL: " + window.location);
      }
      this.authState = params.get(OauthComponent.KEY_STATE);
      this.authCode = params.get(OauthComponent.KEY_CODE);
      // Then, extract the action (login/connect) from the state (little bit sketchy but what's life without some risk)
      const regexResult = this.stateRegex.exec(this.authState);
      if(!regexResult || !regexResult.groups || !regexResult.groups.accountType || !regexResult.groups.action || !regexResult.groups.id) {
        throw new Error("Invalid state: "+ this.authState);
      }
      this.stateInfo = regexResult.groups as any;
      // Verify it's one of the valid actions
      if(!OauthComponent.VALID_ACTIONS.includes(this.stateInfo.action)){
        throw new Error("Invalid action in state: " + this.stateInfo.action);
      }
      // Start with the connecting view
      this.view = "connecting";
    } catch(e) {
      this.showError(e);
    }
  }

  ngAfterViewInit() {
    const { action, accountType } = this.stateInfo;
    if(action === 'connect') {
      this.connectAccount(accountType, this.authState, this.authCode).then(() => this.showSuccess()).catch(err => this.showError(err));
    }else if(action === 'login') {
      this.loginAccount(accountType, this.authState, this.authCode).catch(err => this.showError(err));
    }
  }

  private async connectAccount(accountType, authState, authCode) {
    // Send off a request to the backend to finish the linking
    await this.caService.connect(accountType, { state: authState, code: authCode });
  }
  private async loginAccount(accountType, authState, authCode) {
    // Send off a request to the backend to attempt a login
    const loginResult = await this.caService.login(accountType, { state: authState, code: authCode });
    // Based on returned action, either proceed with login or redirect to registration
    if(loginResult.action === 'login') {
      // Finish login with the azure service
      this.azureClient.loginExternal(loginResult.login);
      this.azureClient.redirectAfterLogin();
    } else if (loginResult.action === 'register') {
      // Store registration data in registration service
      this.registrationService.setRegistrationToken(loginResult.registrationToken);
      this.registrationService.setRegistrationPrefillData({
        firstName: loginResult.profile.first_name,
        lastName: loginResult.profile.last_name,
        email: loginResult.profile.email,
        title: loginResult.profile.academic_title,
        salutation: loginResult.profile.gender === 'm' ? 1 : loginResult.profile.gender === 'f' ? 2 : null,
        portalName2: loginResult.profile.specialization
      })
      // Navigate to the registration page
      this.router.navigate(['register', 'via', accountType]);
    } else {
      throw new Error(`Unknown external login action: ${(loginResult as any).action}`);
    }
  }

  private showError(message) {
    // Re-format server errors and exception to show the nice error text
    if(message.error && message.error.error){
      message = message.error.error;
    } else if (message.message) {
      message = message.message;
    }
    this.errorDetails = message;
    this.view = "error";
  }
  private showSuccess() {
    this.view = "success";
  }

  get viewConnecting() { return this.view === "connecting"; }
  get viewLogin() { return this.view === "login"; }
  get viewSuccess() { return this.view === "success"; }
  get viewError() { return this.view === "error"; }

}
