import { Injectable } from '@angular/core';
import { BreakpointObserver, BreakpointState, Breakpoints } from '@angular/cdk/layout';
import { BehaviorSubject, Observable } from 'rxjs';

export enum APP_SCREEN_SIZES {
  small = 'small',
  medium = 'medium',
  large = 'large'
}

@Injectable({
  providedIn: 'root'
})
export class ApplicationStateService {

  private screenSize$$ = new BehaviorSubject<APP_SCREEN_SIZES>(APP_SCREEN_SIZES.small);
  screenSize$: Observable<APP_SCREEN_SIZES> = this.screenSize$$.asObservable();

  constructor(private breakpointObserver: BreakpointObserver) {
    this.breakpointObserver
      .observe([Breakpoints.Medium, Breakpoints.Large, Breakpoints.XLarge])
      // .pipe(takeUntil(this.destroy$$))
      .subscribe((state: BreakpointState) => {
        const currentBreakpoint = Object.keys(state.breakpoints).find(item => !!state.breakpoints[item]);
        let currentSize = APP_SCREEN_SIZES.small;
        if (currentBreakpoint === Breakpoints.Large || currentBreakpoint === Breakpoints.XLarge) {
          currentSize = APP_SCREEN_SIZES.large;
        } else if (currentBreakpoint === Breakpoints.Medium) {
          currentSize = APP_SCREEN_SIZES.medium;
        }
        this.screenSize$$.next(currentSize);
      });
  }
}
