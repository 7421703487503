import { Component, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ImpressumComponent } from './impressum/impressum.component';

@Component({
	selector: 'app-footer',
	templateUrl: './footer.component.html',
	styleUrls: [ './footer.component.css' ]
})
export class FooterComponent implements OnInit {
	currentYear: Date;

	constructor(private dialog: MatDialog) {
		this.currentYear = new Date();
	}

	public showImpressumDialog() {
		if(this.dialog.openDialogs.some(openDialog => openDialog.componentInstance instanceof ImpressumComponent)){
			return;
		  }
		const dialogRef = this.dialog.open(ImpressumComponent, {});
	}

	ngOnInit() {}
}
