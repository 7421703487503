import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConnectedAccountsService } from '../../shared/services/connected-accounts.service';
import { IConnectedAccountType } from 'src/app/shared/components/connected-accounts-list/connected-accounts-list.component';
import { AzureServiceService } from '../../shared/services/azure-service.service';
import { FavoritesService } from '../../shared/services/favorites.service';
import { ShortcodeService } from '../../shared/services/shortcode.service';

@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: [ './login.component.css' ],
	providers: [ ConnectedAccountsService ]
})
export class LoginComponent implements OnInit {
	public username = '';
	public password = '';
	public errorMessage = '';
	public loginPending = false;
	public externalLoginPending = false;
	constructor(
		private azureClient: AzureServiceService,
		private route: ActivatedRoute,
		private router: Router,
		private favoritesService: FavoritesService,
		private shortCodeService: ShortcodeService,
    private caService: ConnectedAccountsService
	) {}

	ngOnInit() {
		// Check if login is happening via the connected accounts route /login/via/<accountName>
		// and if yes, redirect to external login straight away
		const loginVia = this.route.snapshot.paramMap.get("connectedAccountType");
		if(loginVia) {
			this.externalLoginPending = true;
			this.externalLogin(loginVia);
		}
		// this.heroForm = new FormGroup({
		//   'name': new FormControl(this.hero.name, [
		//     Validators.required,
		//   ]),
		//   'alterEgo': new FormControl(this.hero.alterEgo),
		//   'power': new FormControl(this.hero.power, Validators.required)
		// });
	}

	async loginClick(event) {
		event.preventDefault();
		// console.log(event);
		if (!this.username || !this.password) {
			return;
		}
		this.errorMessage = '';
		try {
			this.loginPending = true;
			const loginRes = await this.azureClient.login(this.username, this.password);
			if (loginRes) {
				this.azureClient.redirectAfterLogin();
				// this.router.navigate(['upload']);
			} else {
				this.errorMessage = 'MFA wird aktuell nicht unterstützt, bitte kontaktieren sie den Service.';
			}
		} catch (err) {
			this.errorMessage = err.message;
		} finally {
			this.loginPending = false;
		}
	}

  async externalLoginClick(accountType: IConnectedAccountType) {
	await this.externalLogin(accountType.name);
  }
  async externalLogin(accountTypeName: string) {
	// Generate a redirect URI
    let redirect = await this.caService.getRedirect(accountTypeName, 'login');
    window.location.replace(redirect.url);
  }
}
