export class UserModel {
    id: string;
    FirstName: string;
    LastName: string;
    PersonTitle: string;
    PW: string;
    NewPW: string;
    PersonSalutationID: number;
    constructor(init?: Partial<UserModel>) {
        Object.assign(this, init);
    }
}
