<div class="container h-100">
  <div class="row justify-content-center align-items-center h-100">
    <div class="centered-box col col-12" *ngIf="checkFile == 1">
      <form #downloadForm="ngForm" (ngSubmit)="downloadFile($event)" *ngIf="fileDownloadStatus != 2">
        <mat-form-field hideRequiredMarker class="form-group">
          <input matInput placeholder="Code" [(ngModel)]="pin" required name="pin" />
        </mat-form-field>
        <div class="form-group col col-12 alert alert-danger font-size-75p" *ngIf="errorMessage" role="alert">
          Ungültiger Code
        </div>
        <div class="button-row col col-10 offset-1 mt-3">
          <button
            mat-raised-button
            class="w-100"
            color="primary"
            [disabled]="!downloadForm.form.valid"
            *ngIf="fileDownloadStatus == 0"
          >
            Überprüfen
          </button>
          <button class="w-100" color="primary" mat-raised-button disabled *ngIf="fileDownloadStatus == 1">
            <i class="fa fa-spinner fa-spin fa-pulse"></i>
          </button>
        </div>
      </form>
      <div class="text-center" *ngIf="fileDownloadStatus == 2">
        <!-- -->
        <div class="col col-12" id="senderComments">
          {{ senderComments || '' }}
        </div>
        <div class="form-group col col-8 offset-4" *ngIf="!!senderComments">
          <a mat-button color="primary" class="w-100" (click)="printToCart('senderComments')" target="_blank"
            ><i class="fa fa-print"></i> Hinweise drucken</a
          >
        </div>
        <div class="col col-10 offset-1">
          <div
            [ngClass]="{ 'form-group': !last }"
            *ngFor="let downloadLink of downloadLinks; last as last; index as index"
          >
            <a mat-raised-button color="primary" class="w-100" [href]="downloadLink" (click)="setSeen()" target="_blank"
              >Download
              {{ fileNames ? fileNames[index] : downloadLinks.length > 1 ? 'Dokument ' + (index + 1) : '' }}</a
            >
          </div>
        </div>
      </div>
    </div>
    <div class="col col-4 alert alert-info text-center" role="alert" *ngIf="checkFile == 0">
      <!-- -->
      Link wird überprüft
      <i class="fa fa-spinner fa-spin fa-pulse"></i>
    </div>
    <div class="col col-4 alert alert-danger text-center" role="alert" *ngIf="checkFile == 2">
      Ungültiger Link!
    </div>
  </div>
</div>
