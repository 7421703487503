import { Component, OnInit } from '@angular/core';
import { ShortcodeService } from '../shared/services/shortcode.service';
import { FavoritesService } from '../shared/services/favorites.service';
import { AdmissionService } from './profile/services/admission.service';
import { AdmissionComponent } from './admission/admission.component';

@Component({
	selector: 'app-protected',
	template: `<app-navbar></app-navbar>
             <router-outlet></router-outlet>
             <app-footer></app-footer>`
	// styleUrls: ['./protected.component.css']
})
export class ProtectedComponent implements OnInit {
	constructor(
		private shortcodeService: ShortcodeService,
		private favoritesService: FavoritesService,
		private admissionService: AdmissionService
	) // private admissionComponent: AdmissionComponent
	{
	}

	async ngOnInit() {
		await this.shortcodeService.read();
		await this.favoritesService.read();
		await this.admissionService.checkUserHasAccount();
		if (this.admissionService.getAccount()) {
			await this.admissionService.read();
			// this.admissionComponent.initData();
		}
	}
}
