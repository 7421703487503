import { Component, OnInit, ElementRef, Renderer2, ViewChild } from '@angular/core';
import { UploadComponent } from '../upload/upload.component';
import { AzureServiceService } from '../../shared/services/azure-service.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { ConfirmDialogComponent } from '../../shared/components/confirm-dialog/confirm-dialog.component';
import { PortalsService } from '../../shared/services/portals.service';
import { CuberryPromotion } from '../../shared/classes/cuberry-promotion';
import { BehaviorSubject, Observable } from 'rxjs';
import { APP_SCREEN_SIZES, ApplicationStateService } from '../../shared/services/application-state.service';

@Component({
	selector: 'app-pricing',
	templateUrl: './pricing.component.html',
	styleUrls: [ './pricing.component.css' ]
})
export class PricingComponent implements OnInit {
	constructor(
		private uploadComponent: UploadComponent,
		private azureClient: AzureServiceService,
		private dialog: MatDialog,
		private portalService: PortalsService,
		private snackBar: MatSnackBar,
		private renderer: Renderer2,
		private elem: ElementRef,
		private appStateService: ApplicationStateService
	) {}
	public aboType: Number;
	public portalObject;
	screenSize$: Observable<string>;
	currentSize: string;
  	APP_SCREEN_SIZES = APP_SCREEN_SIZES;

	@ViewChild('startBtn')
	startBtn: ElementRef;
	@ViewChild('miniBtn')
	miniBtn: ElementRef;
	@ViewChild('basicBtn')
	basicBtn: ElementRef;
	@ViewChild('plusBtn')
	plusBtn: ElementRef;

	@ViewChild('pBox')
	pBox: ElementRef;
	@ViewChild('bxLabel')
	bxLabel: ElementRef;
	@ViewChild('arrow')
	arrow: ElementRef;

	public userSettings = {
		freeText: 'Aktiv',
		startText: 'Jetzt upgraden!',
		miniText: 'Jetzt upgraden!',
		basicText: 'Jetzt upgraden!',
		plusText: 'Jetzt upgraden!'
	};
	public aboNames = {
		0: 'Free',
		4: 'Start',
		1: 'Mini',
		2: 'Basic',
		3: 'Plus'
	};
	private aboPrices = {
		4: '132€',
		1: '264€',
		2: '396€',
		3: '528€'
	};
	private aboInfo = {
		4: '15 SMS pro Monat, Daten 3 Monate abrufbar',
		1: '50 SMS pro Monat, Daten 1 Jahr abrufbar',
		2: '120 SMS pro Monat, Daten 7 Jahre abrufbar',
		3: '200 SMS pro Monat, Daten unlimitiert abrufbar'
	};

	async ngOnInit() {
		this.screenSize$ = this.appStateService.screenSize$;
		this.screenSize$.subscribe(currSize => this.currentSize = currSize);
		await this.getPortal();
		this.aboType = this.portalObject.AboType;
		if (
			this.aboType !== 0 &&
			this.aboType !== 1 &&
			this.aboType !== 2 &&
			this.aboType !== 3 &&
			this.aboType !== 4
		) {
			this.aboType = 0;
		}
		this.initUser();
		if (this.azureClient.showAd && this.currentSize !== APP_SCREEN_SIZES.small) {
			this.expandPricing();
			this.azureClient.showAd = false;
		}
	}

	private async getPortal() {
		try {
			const portalResult = await this.portalService.getOwnPortal();
			// console.log(this.userObject);
			this.portalObject = portalResult;
			// console.log(this.portalObject);
		} catch (err) {
			console.log(err);
		}
	}

	upgrade(id) {
		if(this.dialog.openDialogs.some(openDialog => openDialog.componentInstance instanceof ConfirmDialogComponent)){
			return;
		  }
		const dialogRef = this.dialog.open(ConfirmDialogComponent, {
			width: '500px',
			data: `BefundExpress ${this.aboNames[id]}
${this.aboPrices[id]} jährlich exkl. USt.
(Kostenpflichtig bestellen)`
		});

		dialogRef.afterClosed().subscribe(async (res) => {
			if (res) {
				this.snackBar.open('Vielen Dank, Ihr Abo wurde aufgestuft!', null, {
					duration: 5000,
					panelClass: 'blue-snackbar',
					verticalPosition: 'top'
				});

				const promotion = new CuberryPromotion();
				promotion.FutureAboTypeID = id;

				const result = await this.portalService.updateAboType(this.portalObject, promotion);
				this.aboType = result.AboType;
				const user = JSON.parse(localStorage.getItem('userobject'));
				user.ContactPortalObject.AboType = this.aboType;
				localStorage.setItem('userobject', JSON.stringify(user));
				console.log('Updated LocalStorage: ', JSON.parse(localStorage.getItem('userobject')));


				this.initUser();
			}
		});
	}
	initUser() {
		if (this.aboType === 4) {
			this.userSettings = {
				freeText: '-',
				startText: 'Aktiv',
				miniText: 'Jetzt upgraden!',
				basicText: 'Jetzt upgraden!',
				plusText: 'Jetzt upgraden!'
			};

			this.removeClasses();
			// document.getElementById('miniBtn').classList.add('active');
			// document.getElementById('basicBtn').classList.add('available');
			// document.getElementById('plusBtn').classList.add('available');

			this.renderer.addClass(this.startBtn.nativeElement, 'active');
			this.renderer.addClass(this.miniBtn.nativeElement, 'available');
			this.renderer.addClass(this.basicBtn.nativeElement, 'available');
			this.renderer.addClass(this.plusBtn.nativeElement, 'available');
		}
		if (this.aboType === 1) {
			this.userSettings = {
				freeText: '-',
				startText: '-',
				miniText: 'Aktiv',
				basicText: 'Jetzt upgraden!',
				plusText: 'Jetzt upgraden!'
			};

			this.removeClasses();
			// document.getElementById('miniBtn').classList.add('active');
			// document.getElementById('basicBtn').classList.add('available');
			// document.getElementById('plusBtn').classList.add('available');

			this.renderer.addClass(this.miniBtn.nativeElement, 'active');
			this.renderer.addClass(this.basicBtn.nativeElement, 'available');
			this.renderer.addClass(this.plusBtn.nativeElement, 'available');
		}
		if (this.aboType === 2) {
			this.userSettings = {
				freeText: '-',
				startText: '-',
				miniText: '-',
				basicText: 'Aktiv',
				plusText: 'Jetzt upgraden!'
			};

			this.removeClasses();
			// document.getElementById('basicBtn').classList.add('active');
			// document.getElementById('plusBtn').classList.add('available');

			this.renderer.addClass(this.basicBtn.nativeElement, 'active');
			this.renderer.addClass(this.plusBtn.nativeElement, 'available');
		}
		if (this.aboType === 3) {
			this.userSettings = {
				freeText: '-',
				startText: '-',
				miniText: '-',
				basicText: '-',
				plusText: 'Aktiv'
			};

			this.removeClasses();
			this.renderer.addClass(this.plusBtn.nativeElement, 'active');
		}
	}

	expandPricing() {
		this.uploadComponent.showPricing();
		document.getElementById('pBox').classList.toggle('fade');
		document.getElementById('arrow').classList.toggle('fa-arrow-circle-right');
		document.getElementById('bxLabel').classList.toggle('hide');

		this.renderer.addClass('pBox', 'fade');
	}

	removeClasses() {
		const elements = this.elem.nativeElement.querySelectorAll('.buttonField');

		elements.forEach((el) => {
			// el.classList.remove('active');
			// el.classList.remove('available');
			this.renderer.removeClass(el, 'active');
			this.renderer.removeClass(el, 'available');
		});
	}
}
