import { Injectable } from '@angular/core';
import { AzureServiceService } from './azure-service.service';
import { Favorite } from '../classes/favorite';

@Injectable()
export class FavoritesService {
  private favoritesTable;
  private favorites: Favorite[] = [];
  public favoriteMap;

  searchOffset = 0;

  constructor(private azureService: AzureServiceService) {
    this.favoritesTable = azureService.client.getTable('CRContact');
  }

  public async getFavorites() {
    if (this.favorites.length === 0) {
      await this.initFavorites();
    }
    return this.favorites;
  }

  public async reloadFavorites() {
    this.favorites = [];
    return this.getFavorites();
  }

  public async initFavorites() {
    console.log(`Loading Favorites: ${this.searchOffset + 3000}`);
    const readRes = await this.read();
    this.favorites.push(...readRes);
    if (readRes.length === 3000) {
      this.searchOffset += 3000;
      await this.initFavorites();
    } else {
      this.favoriteMap = this.favorites.reduce((obj, item) => {
        obj[item.id] = item;
        return obj;
      }, {});
    }
  }

  public async insert(favorite: Favorite) {
    try {
      const result = await Promise.resolve(this.favoritesTable.insert(favorite));
      //favorite.id = result.id;
      this.favorites.push(result);
      this.favoriteMap = this.favorites.reduce((obj, item) => {
        obj[item.id] = item;
        return obj;
      }, {});
      // this.read();
      // return this.getFavorites();
      return result;
    } catch (e) {
      console.log('Error inserting', e);
    }
  }

  public async update(favorite: Favorite, updatedFavorite: Favorite) {
    // console.log(updatedFavorite);
    const temp = favorite;
    temp.PersonTitle = updatedFavorite.PersonTitle;
    temp.FirstNameEn = updatedFavorite.FirstNameEn;
    temp.LastNameEn = updatedFavorite.LastNameEn;
    temp.EMail = updatedFavorite.EMail;
    temp.PhoneMobile = updatedFavorite.PhoneMobile;
    temp.Note = updatedFavorite.Note;
    temp.StatusID = updatedFavorite.StatusID;

    // console.log(temp);

    try {
      const result = await Promise.resolve(this.favoritesTable.update(temp));
      this.favoriteMap[favorite.id] = temp;
      this.favorites = Object.values(this.favoriteMap);
      return result;
    } catch (e) {
      console.log('Update Error', e);
    }
  }

  public async delete(favorite: Favorite) {
    try {
      await Promise.resolve(this.favoritesTable.del(favorite));
      delete this.favoriteMap[favorite.id];
      this.favorites = Object.values(this.favoriteMap);
      console.log('Deleted', this.favoriteMap);
    } catch (e) {
      console.log('Error deleting', e);
    }
  }

  public async read() {
    try {
      const result = await Promise.resolve(this.favoritesTable.read({ searchOffset: this.searchOffset }));
      //this.favorites = result;
      return result;
      //console.log('readfav', this.favorites);
    } catch (error) {
      console.log('READ', error);
    }
  }

  public async getCountries() {
    try {
      const countryTable = this.azureService.client.getTable('XConfigCountry');
      return await Promise.resolve(countryTable.read());
    } catch (e) {
      console.log(e);
    }
  }

  public async getCities() {
    try {
      const cityTable = this.azureService.client.getTable('XConfigCity');
      return await Promise.resolve(cityTable.read());
    } catch (e) {
      console.log(e);
    }
  }

  async registerPhone(favorite: Favorite) {
    console.log('TCL: registerPhone -> favorite', favorite);

    try {
      const res = await this.azureService.client.invokeApi('a-trust', {
        body: { contact: favorite },
        method: 'POST'
      });

      console.log('Success', res.result);
      const temp = this.favoriteMap[favorite.id];
      temp.ETresorTID = res.result.tid;
      this.favoriteMap[favorite.id] = temp;
      this.favorites = Object.values(this.favoriteMap);

      window.open(res.result.url);
    } catch (error) {
      console.log('Error', error.message);
    }
  }

  async connectAccounts(tid, uid) {
    try {
      const res = await this.azureService.client.invokeApi('a-trust', {
        body: { tid, uid },
        method: 'PUT'
      });

      console.log('Success', res.result);
    } catch (error) {
      console.log('Account-Link Error: ', error.message);
    }
  }
}
