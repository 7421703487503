import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-file-uploader',
  templateUrl: './file-uploader.component.html',
  styleUrls: ['./file-uploader.component.css']
})

export class FileUploaderComponent implements OnInit {
  public uploadErrorMessage: string;
  @Output() fileAddedEvt = new EventEmitter<FileList>();
  @Input() maxFileSize: number = (10 * 1024 * 1024);
  @Input() maxFileAmount = 8;
  constructor() { }

  ngOnInit() {
  }

  onFileAdded(event) {
    // if (event.action === Ng2FileInputAction.Added) {
    const files = event.target.files;
    if (!files) { return; }
    this.checkAndSetFile(files);
    // }
  }

  onDrop(event) {
    event.preventDefault();
    const files = event.dataTransfer.files;
    if (!files) { return; }
    this.checkAndSetFile(files);
  }

  private checkAndSetFile(files) {
    // console.log(file);
    this.uploadErrorMessage = null;
    const temp = Array.from(files) as File[];
    if(temp.length > 8){
      this.uploadErrorMessage = `Sie können maximal ${this.maxFileAmount} Dateien auf Einmal hochladen`;
      return;
    }
    temp.forEach(file => {
      if (file.size > this.maxFileSize) {
        this.uploadErrorMessage = `Die Datei darf nicht größer als ${this.maxFileSize / (1024 * 1024)}MB sein!`;
        return;
      }
      if ([
        'application/pdf',
        'image/jpg',
        'image/jpeg',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/msword'
      ].indexOf(file.type) === -1) {
        this.uploadErrorMessage = 'Es dürfen nur PDFs, MS Word Dateien (.doc, .docx) oder Bilder (.jpg) hochgeladen werden!';
        return;
      }
    });
    
    // this.fileToUpload = file;
    if(!this.uploadErrorMessage) {
      console.log('Added Files:', files);
      this.fileAddedEvt.next(files);
    }
    
    // console.log(this.fileToUpload);

  }

}
