<div class="container">
  <div
    class="row justify-content-center align-items-center my-auto mx-auto h-100"
  >
    <div class="text-box col-12 mt-4">
      <h4>Admission</h4>
      <mat-form-field>
        <input
          type="text"
          placeholder="Suche"
          matInput
          [(ngModel)]="searchName"
          name="searchName"
          (ngModelChange)="filterTemplates(searchName)"
        />
      </mat-form-field>

      <div class="row">
        <div class="col col-12 col-md-12">
          <mat-accordion>
            <mat-expansion-panel
              *ngFor="let template of filteredTemplates"
              class="hover-cursor item"
            >
              <mat-expansion-panel-header>
                <mat-panel-title>
                  {{ template.SurveyName }}
                </mat-panel-title>
                <mat-panel-description> </mat-panel-description>
              </mat-expansion-panel-header>

              <div *ngIf="fileSendType === 1">
                <mat-form-field>
                  <input matInput placeholder="Vorname" />
                </mat-form-field>

                <mat-form-field>
                  <input matInput placeholder="Nachname" />
                </mat-form-field>
                <button mat-raised-button class="w-100" color="primary">
                  Senden
                </button>
              </div>

              <div *ngIf="fileSendType === 2">
                <button
                  mat-raised-button
                  color="primary"
                  class="contactButton"
                  (click)="showFavoritesDialog()"
                >
                  Kontakt auswählen
                </button>
                <br />
                <mat-form-field>
                  <input
                    matInput
                    #emailField
                    placeholder="Email"
                    [(ngModel)]="recipientEMail"
                  />
                </mat-form-field>
                <button
                  [disabled]="!patientID"
                  mat-raised-button
                  class="w-100"
                  color="primary"
                  (click)="send(template)"
                >
                  Senden
                </button>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
      </div>
    </div>
  </div>
</div>
