import { SelectionModel } from '@angular/cdk/collections';
import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Favorite } from '../../../../shared/classes/favorite';
import { PatientRequest } from '../../../../shared/classes/patient-request';

@Component({
  selector: 'app-requests',
  templateUrl: './requests.component.html',
  styleUrls: ['./requests.component.css']
})
export class RequestsComponent implements OnInit, AfterViewInit {
  private destroy$$ = new Subject<void>();

  @Input() requests: PatientRequest[] = [];
  @Input() favorites: Favorite[];
  @Input() statusFilter: number;

  @Output() showDialog = new EventEmitter();

  @Output() sendSelected = new EventEmitter<PatientRequest[]>();

  @ViewChild(MatSort) set matSort(sort: MatSort) {
    this.dataSource.sort = sort;
  }

  @ViewChild(MatPaginator) set paginator(paginator: MatPaginator) {
    this.dataSource.paginator = paginator;
  }

  public hasFetched = false;
  public dataSource = new MatTableDataSource(this.requests);
  public status = ['Offen', 'In Bearbeitung', 'Resultat Erhalten', 'Versendet'];
  public resultMap = {
    '1': 'pos',
    '2': 'neg',
    '3': 'verzögert',
    '4': 'zu wiederholen'
  };

  displayedColumns = [
    'RequestDate',
    'RequestNo',
    'patientName',
    'ResultDate',
    'StatusID',
    'RequestResultsJSON.testTemplateName',
    'RequestResultsJSON.testResult'
  ];
  selection = new SelectionModel<PatientRequest>(true, []);

  @Output()
  selectionChange = new EventEmitter<PatientRequest[]>();

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.requests.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected() ? this.selection.clear() : this.requests.forEach((row) => this.selection.select(row));
  }

  constructor() {}

  ngOnInit(): void {
    this.selection.changed.pipe(takeUntil(this.destroy$$)).subscribe((result) => {
      this.selectionChange.emit(result.source.selected);
    });

    this.dataSource.sortingDataAccessor = (item, property) => {
      if (property === 'RequestResultsJSON.testResult') {
        return item?.RequestResultsJSON?.testResult ? this.resultMap[item.RequestResultsJSON.testResult] : '';
      } else {
        return item[property];
      }
    };
    // console.log(this.requests)
    // this.dataSource = new MatTableDataSource(this.requests);
  }

  ngOnDestroy() {
    this.destroy$$.next();
    this.destroy$$.complete();
  }

  ngOnChanges(changes: SimpleChanges) {
    // console.log(changes);
    if (changes.requests) {
      this.dataSource.data = changes.requests.currentValue;
      this.selection.clear();
    }
    if (changes.statusFilter) {
      this.displayedColumns =
        changes.statusFilter.currentValue == 2
          ? [...this.displayedColumns, 'select']
          : [...this.displayedColumns].filter((column) => column !== 'select');
    }
  }

  ngAfterViewInit() {
    // console.log(this.sort);
    // this.dataSource.sort = this.sort;
    // console.log(this.dataSource);
  }

  getFavoriteName(id) {
    // console.log({ favs: this.favorites, id });
    const favorite = this.favorites?.find((favorite) => favorite.id === id);
    return favorite ? `${favorite?.FirstNameEn} ${favorite?.LastNameEn}` : '';
  }

  onSendSelected(currentSelection: SelectionModel<PatientRequest>) {
    const selectedItems = currentSelection.selected;
    this.sendSelected.emit(selectedItems);
  }
}
