import { Component, OnInit, Inject, Output, EventEmitter, Input } from '@angular/core';

export interface IConnectedAccountType {
  name: string;
  description: string;
}

@Component({
	selector: 'app-connected-accounts-list',
	templateUrl: './connected-accounts-list.component.html',
	styleUrls: [ './connected-accounts-list.component.css' ]
})
export class ConnectedAccountsListComponent implements OnInit {
  @Input() filterTypes: (accountType: IConnectedAccountType) => boolean = () => true;
  @Output() accountClick: EventEmitter<any> = new EventEmitter();

  public static readonly CONNECTED_ACCOUNT_TYPES: IConnectedAccountType[] = [
      {
        name: 'equip4ordi',
        description: 'Equip4Ordi'
      }
    ]

	constructor(
	) {}

	ngOnInit() {}

  public accountClicked(accountType) {
      this.accountClick.emit(accountType);
  }
  public nameFor(accountType) { return accountType.description; }
  public imageFor(accountType) { return `../assets/images/accounts/${accountType.name}.png`; }
  get connectedAccountTypes() { return ConnectedAccountsListComponent.CONNECTED_ACCOUNT_TYPES.filter(this.filterTypes); }
}
