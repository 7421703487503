<div class="row mt-4">
  <ng-container [formGroup]="filterForm">
    <div class="col-2">
      <div class="form-group">
        <mat-form-field>
          <input
            matInput
            [matDatepicker]="fromDatepicker"
            placeholder="von"
            formControlName="fromDate"
            (dateChange)="filterItems(filterForm.value)"
          />
          <mat-datepicker-toggle matSuffix [for]="fromDatepicker"></mat-datepicker-toggle>
          <mat-datepicker #fromDatepicker></mat-datepicker>
        </mat-form-field>
      </div>
    </div>
    <div class="col-2">
      <div class="form-group">
        <mat-form-field>
          <input
            matInput
            [matDatepicker]="toDatepicker"
            placeholder="bis"
            formControlName="toDate"
            (dateChange)="filterItems(filterForm.value)"
          />
          <mat-datepicker-toggle matSuffix [for]="toDatepicker"></mat-datepicker-toggle>
          <mat-datepicker #toDatepicker></mat-datepicker>
        </mat-form-field>
      </div>
    </div>
  </ng-container>
  <div class="col-12 col-lg-8 pt-3">
    <mat-radio-group [(ngModel)]="filterStatusID">
      <mat-radio-button [value]="null">Alle</mat-radio-button>
      <mat-radio-button [value]="status.value" *ngFor="let status of statusValues">{{ status.text }}</mat-radio-button>
    </mat-radio-group>
  </div>
</div>
<div class="row">
  <div class="col-12 col-lg-10">
    <mat-form-field>
      <input matInput [(ngModel)]="filterString" placeholder="Suche" />
    </mat-form-field>
  </div>
  <div class="col-12 col-lg-2">
    <button
      (click)="onSendSelected(currentSelection)"
      mat-raised-button
      color="primary"
      [disabled]="filterStatusID != 2 || !currentSelection?.length"
    >
      Auswahl versenden
    </button>
  </div>
</div>
<div class="row">
  <div class="col-12">
    <app-requests
      [favorites]="favorites"
      [statusFilter]="filterStatusID"
      [requests]="requests$ | async | statusFilter: filterStatusID | requestFilter: filterString"
      (showDialog)="showDialog($event)"
      (selectionChange)="selectionChanged($event)"
      (sendSelected)="onSendSelected($event)"
    ></app-requests>
  </div>
</div>
