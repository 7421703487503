import { Component, OnInit } from '@angular/core';
import { Subject, Observable, of } from 'rxjs';
import { catchError, exhaustMap, takeUntil, switchMap } from 'rxjs/operators';
import * as moment from 'moment';
import { SmsStatsClientService, SmsStatisticMonthly } from '../../clients/sms-stats-client.service';
import { PortalsService } from '../../../../shared/services/portals.service';

@Component({
  selector: 'app-sms-stats-view',
  templateUrl: './sms-stats-view.component.html',
  styleUrls: ['./sms-stats-view.component.css']
})
export class SmsStatsViewComponent implements OnInit {
  monthlySmsStats$: Observable<SmsStatisticMonthly[]>;
  smsLimit: number;
  limits = {
    0: 0,
    4: 15,
    1: 50,
    2: 120,
    3: 200
  }

  constructor(private smsStatsService: SmsStatsClientService, private portalService: PortalsService) {}

  async ngOnInit() {
    this.monthlySmsStats$ = this.smsStatsService.readSmsStatistics();
    const portal = await this.portalService.getOwnPortal();
    this.smsLimit = this.limits[portal.AboType];
  }
}
