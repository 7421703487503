import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { AzureServiceService } from '../../shared/services/azure-service.service';
import { FormControl, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import * as numeral from 'numeral';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { FavoritesDialogComponent } from './favorites-dialog/favorites-dialog.component';
import { FavoritesService } from '../../shared/services/favorites.service';
import { Favorite } from '../../shared/classes/favorite';
import { FavoritesDetailComponent } from '../favorites/favorites-detail/favorites-detail.component';
import { PersonTitles } from '../../shared/collections/person-titles';
import * as forge from 'node-forge';
import { PortalsService } from '../../shared/services/portals.service';
import { SignatureService } from '../../shared/services/signature.service';
import { SignDialogComponent } from './sign-dialog/sign-dialog.component';
import { PDFDocument } from 'pdf-lib';
import { RequestsClientService } from '../requests/clients/requests-client.service';

@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.css']
})
export class UploadComponent implements OnInit {
  @ViewChild('emailField')
  emailField: ElementRef;
  @ViewChild('senderComments') senderInput;
  public uploadData = {
    filesToSend: null,
    filesToReSend: null,
    reSendID: '',
    uploadPIN: '',
    confirmUploadPIN: '',
    recipientEmail: '',
    senderComments: '',
    internalNote: '',
    recipientPhoneNo: '',
    smsID: '',
    patientTitle: '',
    patientFirstName: '',
    patientLastName: '',
    salt: '',
    iv: '',
    signedFiles: null,
    contactID: ''
  };

  public convertedFiles;
  public personTitles = PersonTitles;
  public fileSendType = 1;
  public fileSentStatus = 0;
  public fileSendError = false;
  public sendSms = true;
  public signDocuments = false;
  public validNumber = true;
  private contactExists = false;
  public panelOpenState = false;
  public showETresor = false;
  private currentContact: Favorite;
  public sendETresor = false;
  public wantsToBeNotified = true;
  public signSubmit = false;
  private reqID = null;

  constructor(
    private azureClient: AzureServiceService,
    private portalService: PortalsService,
    private dialog: MatDialog,
    private favoritesService: FavoritesService,
    public snackBar: MatSnackBar,
    private signatureService: SignatureService,
    private requestsService: RequestsClientService
  ) {}

  async ngOnInit() {
    this.uploadData.uploadPIN = this.generatePIN();
    await this.handleReSend();
    console.log('ABOTYPE:', this.azureClient.getUserAboType());
  }

  private async handleReSend() {
    const {
      state: { data: stateData }
    } = history;
    if (stateData?.idx) {
      if (stateData.recipientEmail) {
        this.uploadData.recipientEmail = stateData.recipientEmail;
        this.uploadData.senderComments = stateData.senderComments;
        this.uploadData.patientTitle = stateData.patientTitle;
        this.uploadData.patientFirstName = stateData.patientFirstName;
        this.uploadData.patientLastName = stateData.patientLastName;
        this.uploadData.filesToReSend = stateData.files;
        this.uploadData.reSendID = stateData.idx;
        this.uploadData.contactID = stateData.contactID;
        if (this.uploadData.contactID) {
          // console.log('contact');
          await this.setFavoriteCode();
        }
      } else {
        this.fileSendType = 2;
      }
    } else if (stateData?.reqID) {
      this.reqID = stateData.reqID;
      this.uploadData.filesToReSend = stateData.files;
      this.uploadData.contactID = stateData.contactID;
      this.uploadData.reSendID = '-1';
      await this.setFavoriteData(stateData.contactID);
    }
  }

  private async setFavoriteCode() {
    const favorites = await this.favoritesService.getFavorites();
    const result = favorites.find((favorite) => favorite.id === this.uploadData.contactID);
    if (!result) {
      return;
    }
    if (result.DefaultPW) {
      this.uploadData.uploadPIN = result.DefaultPW;
    }
  }

  private async setFavoriteData(contactID) {
    const favorites = await this.favoritesService.getFavorites();
    const result = favorites.find((favorite) => favorite.id === contactID);
    if (!result) {
      return;
    }
    const contactUploadData = {
      recipientEmail: result.EMail || '',
      patientTitle: result.PersonTitle || '',
      patientFirstName: result.FirstNameEn || '',
      patientLastName: result.LastNameEn || '',
      ...(result.DefaultPW && { uploadPIN: result.DefaultPW }),
      ...(result.DefaultPW && { confirmUploadPIN: result.DefaultPW }),
      ...(result.PhoneMobile && { recipientPhoneNo: result.PhoneMobile })
    };
    this.sendSms = result.DefaultSendSMS ?? true;
    this.wantsToBeNotified = result.StatusID !== 1;
    this.uploadData = { ...this.uploadData, ...contactUploadData };
  }

  public async fileAdd(files) {
    const fileArr: File[] = Array.from(files);

    const encArr = [];
    /* await fileArr.forEach(async (item) => {
			const encFile = await this.encryptFile(item);
			encArr.push({ name: item.name, file: encFile });
		}); */

    // console.log('Unencrypted Files', fileArr);
    // console.log('Encrypted Files', encArr);

    if (this.azureClient.filesIsEmpty) {
      this.azureClient.filesToUpload = fileArr;
      this.azureClient.setFiles();
    } else {
      this.azureClient.filesToUpload.push(...fileArr);
    }
  }

  public getFilesToSend() {
    return this.azureClient.filesToUpload;
  }

  public getPin() {
    return this.azureClient.getPin();
  }

  setRequestStatus() {}

  public async sendFile(event) {
    this.uploadData.senderComments = this.senderInput.nativeElement.value;
    console.log(this.uploadData.senderComments);
    if (this.signSubmit) {
      return;
    }
    try {
      if (this.fileSentStatus === 1) {
        return;
      }
      if (!this.azureClient.filesToUpload && !this.uploadData.signedFiles && !this.uploadData.filesToReSend) {
        return;
      }
      if (!this.uploadData.signedFiles) {
        this.uploadData.signedFiles = '';
      }
      this.fileSentStatus = 1;
      this.fileSendError = false;
      this.azureClient.setPin(this.uploadData.uploadPIN);
      if (this.sendSms) {
        this.validNumber = await this.sendPIN(this.uploadData.recipientPhoneNo);
      }
      if (this.validNumber) {
        if (this.sendETresor) {
          await this.sendToETresor();
        }

        const portal = await this.portalService.getOwnPortal();
        console.log(portal);
        this.azureClient.signWithName = portal.SignWithName;

        if (this.azureClient.filesToUpload?.length > 0 && !this.signDocuments) {
          await this.azureClient.uploadFormDataFile(this.azureClient.filesToUpload, 'api/fileupload', this.uploadData);
        }
        if (
          (this.uploadData.signedFiles?.length > 0 && this.signDocuments) ||
          this.uploadData.filesToReSend?.length > 0
        ) {
          await this.azureClient.uploadFormDataFileSigned(this.uploadData, 'signedupload');
        }
        if (this.reqID) {
          await this.requestsService.update({ id: this.reqID, StatusID: 3 });
        }
        this.resetUploadData();
        console.log('reset upload');
        this.fileSentStatus = 2;
      } else if (!this.validNumber) {
        this.fileSentStatus = 0;
      }
    } catch (err) {
      console.log(err);
      this.fileSendError = true;
      this.fileSentStatus = 0;
    }
    // .subscribe((res) => {
    //   console.log(res);
    // }, err => {
    //   console.log(err);
    // });
  }

  public removeFile(index, type) {
    if (type === 'normal') {
      this.azureClient.filesToUpload.splice(index, 1);
      if (this.azureClient.filesToUpload.length === 0) {
        this.azureClient.filesToUpload = null;
      }
    } else if (type === 'signed') {
      this.uploadData.signedFiles.splice(index, 1);
      if (this.uploadData.signedFiles.length === 0) {
        this.uploadData.signedFiles = null;
      }
    } else if (type === 'reSend') {
      this.uploadData.filesToReSend.splice(index, 1);
      if (this.uploadData.filesToReSend.length === 0) {
        this.uploadData.filesToReSend = null;
      }
    }
    this.azureClient.filesIsEmpty = true;
  }

  private resetUploadData() {
    this.uploadData = {
      filesToSend: null,
      filesToReSend: null,
      reSendID: '',
      uploadPIN: this.generatePIN(),
      confirmUploadPIN: '',
      recipientEmail: '',
      senderComments: '',
      internalNote: '',
      recipientPhoneNo: '',
      smsID: '',
      patientTitle: '',
      patientFirstName: '',
      patientLastName: '',
      salt: '',
      iv: '',
      signedFiles: null,
      contactID: ''
    };
    this.azureClient.filesToUpload = null;
    this.azureClient.filesIsEmpty = true;
    this.panelOpenState = false;
    this.showETresor = false;
    this.sendETresor = false;
    this.wantsToBeNotified = true;
    this.signSubmit = false;
    this.signDocuments = false;
  }

  public resetFileSentStatus() {
    this.resetUploadData();
    this.fileSentStatus = 0;
  }

  private generatePIN() {
    const number = Math.round(Math.random() * 1e6);
    return numeral(number).format('000000');
  }

  public signAll() {
    setTimeout(() => {
      if (this.signDocuments) {
        this.showSignatureDialog(0);
      } else {
        this.uploadData.signedFiles = null;
      }
    }, 200);
  }

  public async sendPIN(recipientPhoneNo) {
    try {
      const messageText =
        `Sie haben ein Dokument über den BefundExpress erhalten.` +
        ` Mit dem folgenden Code können Sie das Dokument abrufen: ${this.uploadData.uploadPIN}`;
      const result = await this.azureClient.sendSMS(
        messageText,
        // this.uploadData.uploadPIN,
        recipientPhoneNo
      );

      if (result) {
        this.uploadData.smsID = result;
        console.log('SMSID: ' + this.uploadData.smsID);
        return true;
      }
    } catch (err) {
      console.log(err);
      return false;
    }
    console.log(`Message: ${this.uploadData.uploadPIN} \n PhoneNo: ${recipientPhoneNo}`);
  }

  public async showFavoritesDialog() {
    if (
      this.dialog.openDialogs.some((openDialog) => openDialog.componentInstance instanceof FavoritesDialogComponent)
    ) {
      return;
    }

    const favorites = await this.favoritesService.getFavorites();
    const dialogRef = this.dialog.open(FavoritesDialogComponent, {
      width: '500px',
      height: 'auto',
      maxHeight: '900px',
      data: favorites
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (!result) {
        return;
      }
      console.log(result);
      if (result.EMail) {
        this.uploadData.recipientEmail = result.EMail;
        this.uploadData.patientTitle = result.PersonTitle;
        this.uploadData.patientFirstName = result.FirstNameEn;
        this.uploadData.patientLastName = result.LastNameEn;
        this.uploadData.contactID = result.id;
        if (result.DefaultPW) {
          this.uploadData.uploadPIN = result.DefaultPW;
        }
        this.sendSms = result.DefaultSendSMS ?? true;
        this.wantsToBeNotified = result.StatusID !== 1;

        this.panelOpenState = true;
        let elem: HTMLElement;
        elem = this.emailField.nativeElement as HTMLElement;
        elem.click();
        elem.blur();
        console.log('test');
        this.currentContact = result;
        if (result.ETresorUID) {
          console.log('true');
          this.showETresor = true;
        }
      }
      if (result.PhoneMobile) {
        this.uploadData.recipientPhoneNo = result.PhoneMobile;
      }
    });
  }

  public async convertFiles() {
    const res: any = await this.azureClient.getURLFromFile(this.azureClient.filesToUpload, 'api/fileconversion');
    console.log(res);
    const pages = await Promise.all(this.azureClient.filesToUpload.map(async (file) => await this.getNumPages(file)));
    const filePaths = res.filePaths;
    const encIVs = res.encIVs;

    const result = await this.azureClient.getURL(filePaths, encIVs);
    console.log(result.fileUrls);
    const fileNames = res.fileNames.split(',');
    this.convertedFiles = result.fileUrls.map((url, index) => {
      return { url, fileName: fileNames[index], pages: pages[index] };
    });
  }

  public async showSignatureDialog(index) {
    if (!this.convertedFiles) {
      await this.convertFiles();
    }
    this.signSubmit = true;
    try {
      if (this.dialog.openDialogs.some((openDialog) => openDialog.componentInstance instanceof SignDialogComponent)) {
        return;
      }
      const dialogRef = this.dialog.open(SignDialogComponent, {
        width: '300px',
        height: 'auto',
        data: this.convertedFiles[index]
      });

      dialogRef.afterClosed().subscribe(async (result) => {
        if (!this.uploadData.signedFiles) {
          this.uploadData.signedFiles = [];
        }
        if (result !== 'Abort') {
          this.uploadData.signedFiles.push(result);
          //this.removeFile(index, false);
          if (index < this.convertedFiles.length - 1) {
            await this.showSignatureDialog(index + 1);
          }
        }
      });
      this.signSubmit = false;
    } catch (error) {
      console.log(error);
    }
  }

  public async showAddFavoritesDialog(email, phoneNo) {
    const favorites = await this.favoritesService.getFavorites();
    favorites.forEach((favorite) => {
      if (favorite.EMail === email) {
        this.contactExists = true;
        this.snackBar.open('Die Email-Adresse ist bereits einem Kontakt zugewiesen', null, {
          duration: 3000,
          panelClass: 'orange-snackbar'
          // verticalPosition: 'top'
        });
        return;
      }
    });

    if (!this.contactExists) {
      if (
        this.dialog.openDialogs.some((openDialog) => openDialog.componentInstance instanceof FavoritesDetailComponent)
      ) {
        return;
      }
      const dialogRef = this.dialog.open(FavoritesDetailComponent, {
        data: new Favorite({
          PersonTitle: '',
          FirstNameEn: '',
          LastNameEn: '',
          EMail: email,
          PhoneMobile: phoneNo,
          edit: false
        })
      });
    }
    this.contactExists = false;
  }

  public setFileSentStatus(status) {
    this.fileSentStatus = status;
  }

  public showPricing() {
    document.getElementById('pricing').classList.toggle('show');
    document.getElementById('uploadBox').classList.toggle('move');
  }

  //PDF Details
  readFile(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);

      reader.readAsArrayBuffer(file);
    });
  }

  async getNumPages(file) {
    const arrayBuffer = (await this.readFile(file)) as any;

    const pdf = await PDFDocument.load(arrayBuffer);

    return pdf.getPages().length;
  }

  private getBase64(file) {
    const reader = new FileReader();

    return new Promise((resolve, reject) => {
      reader.readAsDataURL(file);
      reader.onerror = () => {
        reader.abort();
        reject(new DOMException('Problem parsing input file.'));
      };
      reader.onload = () => {
        resolve(reader.result);
      };
    });
  }

  private dataURLtoFile(dataurl, filename) {
    const arr = dataurl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  // private async encryptionTest(file) {
  //   try {
  //     const file64 = await this.getBase64(file);
  //     console.log('file64', file64);
  //     // AES Encrytpion
  //     const key = forge.random.getBytesSync(16);
  //     const iv = forge.random.getBytesSync(16);

  //     const cipher = forge.cipher.createCipher('AES-CBC', key);
  //     cipher.start({ iv: iv });
  //     const fileBuffer = forge.util.createBuffer(file64);
  //     cipher.update(fileBuffer);
  //     cipher.finish();
  //     const encrypted = cipher.output;

  //     const decipher = forge.cipher.createDecipher('AES-CBC', key);
  //     decipher.start({ iv: iv });
  //     decipher.update(encrypted);
  //     const result = decipher.finish();
  //     console.log('Decrypted File', this.dataURLtoFile(decipher.output.toString(), 'test'));

  //     // PKCS5 Encryption
  //     const numIterations = 10000;
  //     const salt = forge.random.getBytesSync(128);
  //     const password = '691821';

  //     const derivedKey = forge.pkcs5.pbkdf2(password, salt, numIterations, 16);
  //     const encoded = forge.util.encode64(derivedKey);

  //     // console.log('devKey', encoded);
  //   } catch (e) {
  //     console.log(e);
  //   }
  // }

  private async encryptFile(file) {
    try {
      const file64 = await this.getBase64(file);

      const key = this.generateKey();
      const iv = forge.random.getBytesSync(16);

      const cipher = forge.cipher.createCipher('AES-CBC', key);
      cipher.start({ iv: iv });
      const fileBuffer = forge.util.createBuffer(file64);
      cipher.update(fileBuffer);
      cipher.finish();
      const encrypted = cipher.output.toHex();
      return encrypted;
    } catch (e) {
      console.log('Error while encrypting', e);
    }
  }

  private generateKey() {
    const numIterations = 100000;
    const salt = forge.random.getBytesSync(128);

    const derivedKey = forge.pkcs5.pbkdf2(this.uploadData.uploadPIN, salt, numIterations, 16);
    // const encoded = forge.util.encode64(derivedKey);
    return derivedKey;
  }

  public async sendToETresor() {
    if (this.currentContact) {
      const blobs = await Promise.all(
        this.azureClient.filesToUpload.map((file) => {
          const blob = new Blob([file], {
            type: 'text/plain'
          });
          const newObj = {
            name: file.name,
            data: blob
          };

          return newObj;
        })
      );

      console.log('blobs: ', blobs);
      const res = await this.azureClient.sendToETresor(this.currentContact, blobs);
      return res;
    }
  }
}
