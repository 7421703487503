import { Injectable } from '@angular/core';

import { AzureServiceService } from '../../../shared/services/azure-service.service';
import { CuberryPromotion } from '../../../shared/classes/cuberry-promotion';

@Injectable()
export class PromotionService {

  constructor(private azureClient: AzureServiceService) { }
  private table = this.azureClient.client.getTable('CuberryPromotion');

  public async get(): Promise<CuberryPromotion[]> {
    const result = await this.table.read();
    return result as CuberryPromotion[];
  }
}
