import { Injectable } from '@angular/core';
import { Hl7Config } from '../classes/hl7-config';

@Injectable()
export class Hl7Service {

  constructor() { }


  public createFile(caseData: Hl7Config) {
    const msh = `MSH|^~\\&|Innomed^${caseData.msh.name}|${caseData.msh.sender}^${caseData.msh.erkennung}||${caseData.msh.empfaenger}|${caseData.msh.versendezeit}||ORM^O01|${caseData.msh.barcodeID}|P^A|2.3\n`;

    const pid = `PID|1|||${caseData.pid.patientID}|${caseData.pid.nachname}^${caseData.pid.vorname}^${caseData.pid.vorname2}^^${caseData.pid.titel}L^P||${caseData.pid.gebdat}|${caseData.pid.geschlecht}|||${caseData.pid.strasse}^^${caseData.pid.ort}^^${caseData.pid.plz}^AT^H|AUT||||||||${caseData.pid.svnr}\n`;

    const in1 = `IN1|1||${caseData.in1.kkID}|${caseData.in1.kkName}||||||||||||${caseData.in1.nachname}^${caseData.in1.vorname}^${caseData.in1.vorname2}^^${caseData.in1.titel}^L||${caseData.in1.gebdat}|${caseData.in1.strasse}^^${caseData.in1.ort}^^${caseData.in1.plz}^AT^P\n`;

    const in2 = `IN2||${caseData.in2.svnr}|^^${caseData.in2.dienstgeber}^^^^^^^L\n`;

    const orc = `ORC|NW|${caseData.orc.barcodeID}^ORDERID|||O||||${caseData.orc.datum}|^${caseData.orc.nachname}^${caseData.orc.vorname}^${caseData.orc.vorname2}^^${caseData.orc.titel}^^L||||||${caseData.orc.versendezeit}|||care01\n`;

    const obr = `OBR|1|${caseData.obr.barcodeID}^ORDERID\n`;

    let obx = '';
    caseData.obx.forEach((element, index) => {
      obx += `OBX|${index + 1}|ST|${element.krzID}^${element.krz}||${element.text}\n`;
    });

    const z10 = `Z10|${caseData.z10.woche}^${caseData.z10.zustellung}^${caseData.z10.sonstiges}\n`;

    const result = msh + pid + in1 + in2 + orc + obr + obx + z10;

    console.log(result);
  }
}
