<div class="container">
  <div class="row justify-content-center align-items-center my-auto mx-auto h-100">
    <app-promotion
      class="col-12 maxw-700"
      *ngIf="portalObject"
      [saveInProgress]="updateAboTypePending"
      [activeAboType]="portalObject.AboType"
      (ordered)="promotionOrdered($event)"
    ></app-promotion>

    <div class="profile-box col-12 mt-4">
      <div class="text-center" *ngIf="!userObject || !portalObject">
        Daten werden geladen...
        <i class="fa fa-spinner fa-spin fa-pulse"></i>
      </div>
      <form #profileForm="ngForm" (ngSubmit)="updateUser($event)" *ngIf="userObject && portalObject">
        <h4>Profil</h4>
        <div class="row">
          <div class="col col-12 col-md-3">
            <mat-form-field hideRequiredMarker>
              <mat-select
                placeholder="Anrede"
                [(ngModel)]="userObject.PersonSalutationID"
                name="salutation"
                #salutation="ngModel"
              >
                <mat-option></mat-option>
                <mat-option *ngFor="let salutation of salutations" [value]="salutation.id">
                  {{ salutation.value }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col col-12 col-md-3">
            <mat-form-field hideRequiredMarker>
              <input matInput placeholder="Titel" [(ngModel)]="userObject.PersonTitle" name="title" #title="ngModel" />
              <!-- <mat-option></mat-option>
                <mat-option *ngFor="let title of titles" [value]="title">
                  {{ title }}
                </mat-option>
              </mat-select> -->
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col col-12 col-md-6">
            <mat-form-field hideRequiredMarker>
              <input
                matInput
                placeholder="Vorname"
                [(ngModel)]="userObject.FirstName"
                required
                name="firstName"
                #firstName="ngModel"
              />
              <mat-error *ngIf="firstName?.errors && firstName?.touched">Bitte geben Sie Ihren Vornamen ein.</mat-error>
            </mat-form-field>
          </div>
          <div class="col col-12 col-md-6">
            <mat-form-field hideRequiredMarker>
              <input
                matInput
                placeholder="Nachname"
                [(ngModel)]="userObject.LastName"
                required
                name="lastName"
                #lastName="ngModel"
              />
              <mat-error *ngIf="lastName?.errors && lastName?.touched">Bitte geben Sie Ihren Vornamen ein.</mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col col-12 col-md-6">
            <mat-form-field hideRequiredMarker>
              <input
                matInput
                placeholder="Praxis-/Labor-/Apothekenname"
                [(ngModel)]="portalObject.Name"
                required
                maxlength="50"
                name="portalName"
                #portalName="ngModel"
              />
              <mat-error *ngIf="portalName?.errors && portalName?.touched"
                >Bitte geben Sie Ihren Ordinations/Apotheken Namen ein.</mat-error
              >
            </mat-form-field>
          </div>
          <div class="col col-12 col-md-6">
            <mat-form-field hideRequiredMarker>
              <input
                type="email"
                email
                matInput
                placeholder="Absender E-Mail"
                [(ngModel)]="portalObject.EmailHost"
                maxlength="50"
                required
                name="emailHost"
                #emailHost="ngModel"
              />
              <mat-error *ngIf="emailHost?.errors && emailHost?.touched"
                >Bitte geben Sie eine korrekte E-Mail Adresse ein.</mat-error
              >
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col col-12 col-md-6">
            <mat-form-field hideRequiredMarker>
              <input
                matInput
                placeholder="Postleitzahl"
                [(ngModel)]="portalObject.ZIPCode"
                maxlength="10"
                name="zipCode"
                #zipCode="ngModel"
              />
              <mat-error *ngIf="zipCode?.errors && zipCode?.touched"
                >Die Postleitzahl darf maximal 10 Zeichen lang sein.</mat-error
              >
            </mat-form-field>
          </div>
          <div class="col col-12 col-md-6">
            <mat-form-field hideRequiredMarker>
              <input
                matInput
                placeholder="Stadt"
                [(ngModel)]="portalObject.City"
                name="city"
                maxlength="50"
                #city="ngModel"
              />
              <mat-error *ngIf="city?.errors && city?.touched"
                >Der Name der Stadt darf maximal 50 Zeichen lang sein.</mat-error
              >
            </mat-form-field>
          </div>
        </div>
        <div class="row" *ngIf="portalObject.ParentPortalID">
          <div class="col col-12">
            <mat-form-field hideRequiredMarker>
              <input
                matInput
                placeholder="Download Ordner"
                [(ngModel)]="portalObject.LocalFileDLPath"
                maxlength="20"
                name="localFolder"
                pattern="^(\d|\w)+$"
                #localFolder="ngModel"
              />
              <mat-error *ngIf="localFolder?.errors && localFolder?.touched"
                >Der Name vom Ordner darf maximal 15 Zeichen lang sein, und darf keine Leer- oder Sonderzeichen
                enthalten</mat-error
              >
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col col-12 col-md-12">
            <mat-checkbox
              class="w-100 ws-normal"
              [(ngModel)]="portalObject.ShowInBxAddressBook"
              name="showInBxAddressBook"
            >
              Als Empfänger anzeigen (In der BefundExpress Empfängerliste sichtbar)
            </mat-checkbox>
          </div>
        </div>
        <div class="row">
          <div class="col col-12 col-md-12">
            <mat-checkbox class="w-100 ws-normal" [(ngModel)]="portalObject.SignWithName" name="signWithName">
              Versendete Emails automatisch mit meinem Namen unterschreiben
            </mat-checkbox>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col col-12 col-md-4 offset-md-4">
            <button
              mat-raised-button
              class="w-100"
              color="primary"
              *ngIf="!updatePending"
              [disabled]="!profileForm.form.valid"
            >
              Speichern
            </button>
            <!-- <mat-error class="error-msg" *ngIf="registerErrorMsg">{{registerErrorMsg}}</mat-error> -->
            <button class="w-100" color="primary" mat-raised-button disabled *ngIf="updatePending">
              <i class="fa fa-spinner fa-spin fa-pulse"></i>
            </button>
          </div>
        </div>
      </form>
    </div>

    <div class="profile-box col-12 mt-4" *ngIf="userObject">
      <form #passwordForm="ngForm" (ngSubmit)="updatePassword(passwordForm)">
        <h4>Passwort</h4>
        <div class="text--info">
          Das neue Passwort muss mindestens 7 Zeichen lang sein und muss sich vom aktuellen Passwort unterscheiden.
        </div>
        <div class="row">
          <div class="col col-12 col-md-6">
            <mat-form-field hideRequiredMarker>
              <input
                type="password"
                matInput
                placeholder="Aktuelles Passwort"
                required
                [(ngModel)]="userObject.PW"
                minlength="7"
                name="password"
                #password="ngModel"
              />
              <mat-error *ngIf="password?.errors && password?.touched"
                >Bitte geben Sie ihr aktuelles Passwort ein.</mat-error
              >
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col col-12 col-md-6">
            <mat-form-field hideRequiredMarker>
              <input
                type="password"
                matInput
                placeholder="Neues Passwort"
                required
                [(ngModel)]="userObject.NewPW"
                minlength="7"
                name="newPassword"
                #newPassword="ngModel"
              />
              <mat-error *ngIf="newPassword?.errors && newPassword?.touched"
                >Bitte geben Sie ein gültiges Passwort ein (mind. 7 Zeichen).</mat-error
              >
            </mat-form-field>
          </div>
          <div class="col col-12 col-md-6">
            <mat-form-field hideRequiredMarker>
              <input
                type="password"
                matInput
                placeholder="Passwort bestätigen"
                required
                [(ngModel)]="confirmPW"
                pattern="({{ newPassword?.value | strRegex }})"
                name="confirmPassword"
                #confirmPassword="ngModel"
              />
              <mat-error *ngIf="newPassword?.value && confirmPassword?.errors && confirmPassword?.touched"
                >Die Passwörter stimmen nicht überein.</mat-error
              >
            </mat-form-field>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col col-12 col-md-4 offset-md-4">
            <button
              mat-raised-button
              class="w-100"
              color="primary"
              *ngIf="!pwChangePending"
              [disabled]="!passwordForm.form.valid || password?.value == newPassword?.value"
            >
              Passwort &auml;ndern
            </button>
            <!-- <mat-error class="error-msg" *ngIf="registerErrorMsg">{{registerErrorMsg}}</mat-error>  -->
            <button class="w-100" color="primary" mat-raised-button disabled *ngIf="pwChangePending">
              <i class="fa fa-spinner fa-spin fa-pulse"></i>
            </button>
          </div>
        </div>
      </form>
    </div>

    <app-api-user class="col-12 maxw-700"></app-api-user>
  </div>
</div>
