import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { AdmissionService } from '../profile/services/admission.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { FavoritesDialogComponent } from '../upload/favorites-dialog/favorites-dialog.component';
import { FavoritesService } from '../../shared/services/favorites.service';

@Component({
	selector: 'app-admission',
	templateUrl: './admission.component.html',
	styleUrls: [ './admission.component.css' ]
})
export class AdmissionComponent implements OnInit {
	@ViewChild('emailField')
	emailField: ElementRef;

	public hasAccount = false;
	public templates = [];
	public filteredTemplates = [];
	public fileSendType = 2;
	public searchName;
	public recipientEMail;
	public patientData = {
		title: 'Dr.',
		firstName: '',
		lastName: ''
	};
	public patientID;

	constructor(
		private admissionService: AdmissionService,
		private dialog: MatDialog,
		private favoritesService: FavoritesService
	) {
		this.initData();
	}

	async ngOnInit() {
		await this.initData();
	}

	private async initData() {
		const res = await this.admissionService.read();
		this.templates = this.admissionService.getTemplates();
		console.log('temps', this.templates);
		this.filteredTemplates = this.templates;
		console.log('init', this.filteredTemplates);
	}

	public filterTemplates(searchText) {
		const filtered = this.templates.filter((item) => {
			return !searchText || item.SurveyName.toLowerCase().indexOf(searchText.toLowerCase()) > -1;
		});
		this.filteredTemplates = filtered;
		return filtered;
	}

	public select(template) {
		console.log(`Send ${template.SurveyName} ${this.fileSendType === 1 ? 'via Praxis' : 'via e-Mail'}`);
	}

	public async send(template) {
		try {
			template.recipientEMail = this.recipientEMail;
			template.templateIDs = [ template.id ];
			template.surveyTypeID = 2;
			template.patientData = this.patientData;
			template.patientID = this.patientID;

			const result = await this.admissionService.createSurveyFromTemplate(template);
			console.log('Sent', result);
		} catch (error) {
			console.log(error);
		}
	}

	public showFavoritesDialog() {
		if(this.dialog.openDialogs.some(openDialog => openDialog.componentInstance instanceof FavoritesDialogComponent)){
			return;
		  }
		const dialogRef = this.dialog.open(FavoritesDialogComponent, {
			width: '500px',
			height: '1200px',
			data: this.favoritesService.favoriteMap
		});
		dialogRef.afterClosed().subscribe((result) => {
			if (!result) {
				return;
			}
			console.log(result);
			if (result.EMail) {
				this.recipientEMail = result.EMail;
				this.patientData.firstName = result.FirstNameEn;
				this.patientData.lastName = result.LastNameEn;
				this.patientID = result.id;
				let elem: HTMLElement;
				elem = this.emailField.nativeElement as HTMLElement;
				elem.click();
				elem.blur();
				console.log('test');
			}
		});
	}
}
