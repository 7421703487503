<div class="container h-100">
    <div class="row justify-content-center align-items-center h-100">
      <div class="col col-4 alert alert-info text-center" role="alert" *ngIf="resultCode == 0 && status === 0">
        <!-- -->
        <i class="fa fa-spinner fa-spin fa-pulse"></i>
      </div>
      <div class="col col-4 alert alert-success text-center" role="alert" *ngIf="resultCode == 0 && status === 1">
        <!-- -->
        <i class="fa fa-check"></i>
        ETresor-Account erfolgreich verbunden!
        
      </div>
      <div class="col col-4 alert alert-danger text-center" role="alert" *ngIf="resultCode != 0">
        Ungültiger Link!
      </div>
    </div>
  </div>