import { Injectable } from '@angular/core';
import { AzureServiceService } from '../../../shared/services/azure-service.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';

export interface SmsStatisticMonthly {
  Anz?: number,
  M?: number,
  Y?: number

}

@Injectable({
  providedIn: 'root'
})
export class SmsStatsClientService {

  constructor(private azureService: AzureServiceService, private http: HttpClient) { }

  readSmsStatistics(params: any = {}): Observable<SmsStatisticMonthly[]> {
    params.RequestFrom = 'BxManagement';
    return this.http.get<SmsStatisticMonthly[]>(`${environment.azureAppURL}/tables/CCPatientDocumentDownload`,{
      params,
      headers: {
        'ZUMO-API-VERSION': '2.0.0',
        'X-ZUMO-AUTH': this.azureService.client.currentUser.mobileServiceAuthenticationToken
      }
    });
  }
}
