<div class="container">
  <div class="row justify-content-center align-items-center my-auto mx-auto h-100">

    <div class="text-box col-12 mt-4">
      <h4>Kontakte</h4>
      <mat-form-field>
          <input type="text" placeholder="Suche" matInput [(ngModel)]="searchName" name="searchName" (ngModelChange)="filterFavorites(searchName)"
        />
      </mat-form-field>

      <div class="d-flex flex-row-reverse">
          <!-- <i class="fa fa-plus-circle fa-2x hover-cursor"></i> -->
          <button mat-button color="primary" (click)="showDetailDialog()">Hinzufügen</button>
        </div>
      <div class="row">
        <div class="col col-12 col-md-12">
          <mat-list>
              <mat-list-item *ngFor="let favorite of filteredFavorites" class="hover-cursor item" (click)="edit(favorite)">
              <h3 matLine>{{favorite.PersonTitle}} {{favorite.FirstNameEn}} {{favorite.LastNameEn}}</h3>
              <label class="tel" matLine> {{favorite.PhoneMobile}}</label>
              <p class="email">
                {{favorite.EMail}}
              </p>
              <mat-divider></mat-divider>
            </mat-list-item>
            
          </mat-list>
        </div>
      </div>
    </div>

  </div>
</div>