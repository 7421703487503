<div class="text-center">
  <h2 mat-dialog-title>Bestätigen</h2>
  <div class="row">
    <div class="col col-12">
      <div class="form-group">
        <div class="text--prewrap">{{confMessage}}</div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col col-10 offset-1 mt-3">
      <div class="row">
        <div class="col-12 col-md-6">
          <button class="w-100" color="primary" [mat-dialog-close]="true" mat-raised-button>Ja</button>
        </div>
        <div class="col-12 col-md-6">
          <button mat-raised-button mat-dialog-close color="primary" class="w-100">Nein</button>
        </div>
      </div>
    </div>
  </div>
</div>