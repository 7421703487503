<div class="text-center">
  <h2 mat-dialog-title>{{promoItem.PromoName}}</h2>
  <mat-dialog-content class="mat-typography">
    <div class="row">
      <div class="col col-10 offset-1">
        <span>{{ promoItem.PromoResponse }}</span>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>OK</button>
  </mat-dialog-actions>
</div>