<div class="container centered">
  <div class="row justify-content-center my-auto mx-auto mb-xs-1">
    <div class="register-box col col-12" *ngIf="!registerSuccess">
      <h1>Registrierung</h1>
      <div class="info-box" *ngIf="!isConnected">
        Die Registrierung ist nur für Ärzte notwendig,
        <span style="font-weight: bold;">nicht</span> für Patienten!
      </div>
      <div class="hint-box" *ngIf="isConnected">
        <h5>Willkommen bei BefundExpress!</h5>
        <span>Ihre Daten werden von Ihrem {{accountTypeNames[connectedAccountType]}}-Konto übernommen. Bitte lesen und bestätigen Sie die AGB und die Datenschutzerklärung, um die Registrierung abzuschließen.</span>
      </div>
      
      <form #registerForm="ngForm" (ngSubmit)="registerClick($event)">
        <div class="row">
          <div class="col col-12 col-md-2">
            <mat-form-field hideRequiredMarker>
              <mat-select
                placeholder="Anrede"
                [(ngModel)]="registerData.salutation"
                [disabled]="lockedFields.includes('salutation')"
                name="salutation"
                #salutation="ngModel"
              >
                <mat-option></mat-option>
                <mat-option
                  *ngFor="let salutation of salutations"
                  [value]="salutation.id"
                >
                  {{ salutation.value }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col col-12 col-md-2">
            <mat-form-field hideRequiredMarker>
              <mat-select
                placeholder="Titel"
                [(ngModel)]="registerData.title"
                [disabled]="lockedFields.includes('title')"
                name="title"
                #title="ngModel"
              >
                <mat-option></mat-option>
                <mat-option *ngFor="let title of titles" [value]="title">
                  {{ title }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col col-12 col-md-4">
            <mat-form-field hideRequiredMarker>
              <input
                matInput
                placeholder="Vorname"
                [(ngModel)]="registerData.firstName"
                [disabled]="lockedFields.includes('firstName')"
                required
                name="firstName"
                maxlength="50"
                #firstName="ngModel"
              />
              <mat-error *ngIf="firstName?.errors && firstName?.touched"
                >Bitte geben Sie Ihren Vornamen ein.</mat-error
              >
            </mat-form-field>
          </div>
          <div class="col col-12 col-md-4">
            <mat-form-field hideRequiredMarker>
              <input
                matInput
                placeholder="Nachname"
                [(ngModel)]="registerData.lastName"
                [disabled]="lockedFields.includes('lastName')"
                required
                name="lastName"
                maxlength="50"
                #lastName="ngModel"
              />
              <mat-error *ngIf="lastName?.errors && lastName?.touched"
                >Bitte geben Sie Ihren Nachnamen ein.</mat-error
              >
            </mat-form-field>
          </div>
        </div>
        <div class="row" *ngIf="!isConnected">
          <div class="col col-12 col-md-6">
            <mat-form-field hideRequiredMarker>
              <mat-select
                placeholder="Fachgebiet"
                [(ngModel)]="registerData.fieldOfPractice"
                [disabled]="lockedFields.includes('fieldOfPractice')"
                required
                name="fieldOfPractice"
                #fieldOfPractice="ngModel"
              >
                <mat-option
                  *ngFor="let physicianType of physicianTypes"
                  [value]="physicianType.id"
                >
                  {{ physicianType.value }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div
            class="col col-12 col-md-6"
            *ngIf="isLabOrPharmacy(registerData.fieldOfPractice)"
          >
            <mat-form-field hideRequiredMarker>
              <input
                matInput
                placeholder="Labor-/Apothekenname"
                [(ngModel)]="registerData.portalName"
                [disabled]="lockedFields.includes('portalName')"
                required
                name="portalName"
                maxlength="50"
                #portalName="ngModel"
              />
              <mat-error *ngIf="portalName?.errors && portalName?.touched"
                >Bitte geben Sie den Namen ihres/r Labor/Apotheke
                ein.</mat-error
              >
            </mat-form-field>
          </div>
        </div>
        <div class="row" *ngIf="!isConnected">
          <div class="col col-12 col-md-3">
            <mat-form-field hideRequiredMarker>
              <mat-select
                [(ngModel)]="registerData.phonePrefix"
                [disabled]="lockedFields.includes('phonePrefix')"
                name="phonePrefix"
                required
                #phonePrefix="ngModel"
              >
                <mat-option
                  *ngFor="let prefix of phonePrefixes"
                  [value]="prefix.value"
                >
                  {{ prefix.text }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col col-12 col-md-4">
            <mat-form-field hideRequiredMarker>
              <input
                matInput
                placeholder="Mobil"
                [(ngModel)]="registerData.phoneMobile"
                [disabled]="lockedFields.includes('phoneMobile')"
                required
                maxlength="50"
                name="phoneMobile"
                #phoneMobile="ngModel"
              />
              <mat-error *ngIf="phoneMobile?.errors && phoneMobile?.touched"
                >Bitte geben Sie Ihre Telefonnr. ein.</mat-error
              >
            </mat-form-field>
          </div>
          <div class="col col-12 col-md-5">
            <mat-form-field hideRequiredMarker>
              <input
                type="email"
                email
                matInput
                placeholder="E-Mail"
                [(ngModel)]="registerData.email"
                [disabled]="lockedFields.includes('email')"
                maxlength="50"
                required
                name="email"
                #email="ngModel"
              />
              <mat-error *ngIf="email?.errors && email?.touched"
                >Bitte geben Sie eine gültige EMail Adresse ein.</mat-error
              >
            </mat-form-field>
          </div>
        </div>
        <div class="row" *ngIf="!isConnected">
          <div class="col col-12">
            <mat-form-field hideRequiredMarker>
              <input
                matInput
                placeholder="Benutzername"
                [(ngModel)]="registerData.username"
                required
                name="username"
                pattern="^[a-zA-Z0-9]{4,}$"
                #username="ngModel"
              />
              <mat-error *ngIf="username?.errors && username?.touched"
                >Bitte geben Sie einen gültigen Username ein (nur Buchstaben und
                Zahlen, mind. 4 Zeichen).</mat-error
              >
            </mat-form-field>
          </div>
        </div>
        <div class="row" *ngIf="!isConnected">
          <div class="col col-12 col-md-6">
            <mat-form-field hideRequiredMarker>
              <input
                type="password"
                matInput
                placeholder="Passwort"
                [(ngModel)]="registerData.password"
                required
                minlength="7"
                name="password"
                #password="ngModel"
              />
              <mat-error *ngIf="password?.errors && password?.touched"
                >Bitte geben Sie ein gültiges Passwort ein (mind. 7
                Zeichen).</mat-error
              >
            </mat-form-field>
          </div>
          <div class="col col-12 col-md-6">
            <mat-form-field hideRequiredMarker>
              <input
                type="password"
                matInput
                placeholder="Passwort bestätigen"
                [(ngModel)]="registerData.confirmPassword"
                pattern="({{ password?.value | strRegex }})"
                required
                name="confirmPassword"
                #confirmPassword="ngModel"
              />
              <mat-error
                *ngIf="confirmPassword?.errors && confirmPassword?.touched"
                >Die Passwörter stimmen nicht überein.</mat-error
              >
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col col-12">
            <div class="form-group">
              Mit der Bestätigung der Allgemeinen Geschäftsbedingungen und der
              Betätigung des Buttons "Registrieren", erklären Sie sich
              einerseits mit unseren Allgemeinen Geschäftsbedingungen
              einverstanden und geben außerdem eine gültige Bestellung für die
              Nutzung von BefundExpress bei der Peacequare GmbH ab.
            </div>
          </div>
        </div>
        <div class="row align-items-center">
          <div class="col col-12 col-md-6">
            <div class="form-group">
              <mat-checkbox
                class="w-100 ws-normal"
                [(ngModel)]="registerData.agbAccepted"
                name="agbAccepted"
                >Ich stimme den
                <a href="https://befundexpress.at/rechtliches" target="_blank"
                  >AGB</a
                >
                von BefundExpress zu.</mat-checkbox
              >
            </div>
            <div class="form-group">
              <mat-checkbox
                class="w-100 ws-normal"
                [(ngModel)]="registerData.dsgvoAccepted"
                name="dsgvoAccepted"
                >Ich stimme der
                <a href="https://befundexpress.at/rechtliches" target="_blank"
                  >Datenschutzerklärung</a
                >
                zu.</mat-checkbox
              >
            </div>

            <div class="form-group" *ngIf="!isConnected">
              <a (click)="alreadyRegisteredClick()" class="w-100" href="#"
                >Ich bin bereits registriert</a
              >
            </div>
          </div>
          <div class="col col-12 col-md-4 offset-md-2">
            <button
              mat-raised-button
              class="w-100"
              color="primary"
              *ngIf="!registerPending"
              [disabled]="
                !registerForm.form.valid ||
                !registerData.agbAccepted ||
                !registerData.dsgvoAccepted
              "
            >
              Registrieren
            </button>
            <mat-error class="error-msg" *ngIf="registerErrorMsg">{{
              registerErrorMsg
            }}</mat-error>
            <button
              class="w-100"
              color="primary"
              mat-raised-button
              disabled
              *ngIf="registerPending"
            >
              <i class="fa fa-spinner fa-spin fa-pulse"></i>
            </button>
          </div>
        </div>
      </form>
    </div>
    <div class="register-box col col-10" *ngIf="registerSuccess">
      <div class="row">
        <div class="col col-12">
          <h1>Registrierung erfolgreich!</h1>
        </div>
        <div class="col col-12 text-center" *ngIf="!isConnected">
          Sobald Sie vom BefundExpress Team aktiviert wurden, erhalten Sie eine
          Bestätigungsmail.
        </div>
        <div class="col col-12 text-center" *ngIf="isConnected">
          Vielen Dank für Ihre Registrierung. Sie können sich jetzt mit Ihrem {{accountTypeNames[connectedAccountType]}}-Konto einloggen.
        </div>
        <div class="col col-4 offset-4 mt-2">
          <a mat-button class="w-100" color="primary" [routerLink]="'/login/via/' + connectedAccountType"
            >Jetzt einloggen</a
          >
        </div>
      </div>
    </div>
  </div>
</div>
