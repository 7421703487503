import { Injectable } from '@angular/core';
import { XConfigShortcode } from '../classes/xconfig-shortcode';
import { AzureServiceService } from './azure-service.service';

@Injectable()
export class ShortcodeService {
	private shortcutTable;
	private shortCodes: XConfigShortcode[];
	public shortCodeMap;

	// ngOnInit(): void {
	//   // Called after the constructor, initializing input properties, and the first call to ngOnChanges.
	//   // Add 'implements OnInit' to the class.
	// }

	constructor(private azureService: AzureServiceService) {
		this.shortcutTable = azureService.client.getTable('XConfigShortcut');
	}

	public async update(shortCodeObject: XConfigShortcode, updatedObject: XConfigShortcode) {
		console.log(updatedObject);
		const temp = shortCodeObject;
		temp.ShortcutKey = updatedObject.ShortcutKey;
		temp.ShortcutText = updatedObject.ShortcutText;

		// console.log(temp);

		try {
			const result = await Promise.resolve(this.shortcutTable.update(temp));
			this.shortCodeMap[shortCodeObject.ShortcutKey] = temp;
			this.shortCodes = Object.values(this.shortCodeMap);
			await this.read(); //temp
		} catch (e) {
			console.log('Update Error', e);
		}
	}

	public getShortCodes() {
		return this.shortCodes;
	}

	public async insert(shortCodeObject: XConfigShortcode) {
		try {
			const result = await Promise.resolve(this.shortcutTable.insert(shortCodeObject));
			const shortCode = new XConfigShortcode(result);
			this.shortCodes.push(shortCode);
			this.shortCodeMap = this.shortCodes.reduce((obj, item) => {
				obj[item.ShortcutKey.toLowerCase()] = item;
				return obj;
			}, {});
			console.log('Done!', result);
			return this.shortCodes;
		} catch (e) {
			console.log('Error inserting', e);
		}
	}

	public async delete(shortCodeObject: XConfigShortcode) {
		try {
			console.log('WHERE ID', shortCodeObject);
			await Promise.resolve(this.shortcutTable.del(shortCodeObject));
			delete this.shortCodeMap[shortCodeObject.ShortcutKey];
			this.shortCodes = Object.values(this.shortCodeMap);
			console.log('Deleted', this.shortCodeMap);
		} catch (e) {
			console.log('Error deleting', e);
		}
	}

	public async read() {
		try {
			const result = await Promise.resolve(this.shortcutTable.read());
			this.shortCodes = result;
			this.shortCodeMap = result.reduce((obj, item) => {
				obj[item.ShortcutKey.toLowerCase()] = item;
				return obj;
			}, {});
		} catch (error) {
			console.log('Read Error', error);
		}
	}
}
