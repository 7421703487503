export class XConfigShortcode {
    id: any;
    Active: any;
    CreatedBy: any;
    CreatedOn: any;
    EditedBy: any;
    EditedOn: any;
    PortalID: any;
    ShortcutKey: any;
    ShortcutText: any;
    TextTypeID: any;

    constructor(inputData) {
        this.id = inputData.id;
        this.Active = inputData.Active;
        this.CreatedBy = inputData.CreatedBy;
        this.CreatedOn = inputData.CreatedOn;
        this.EditedBy = inputData.EditedBy;
        this.EditedOn = inputData.EditedOn;
        this.PortalID = inputData.PortalID;
        this.ShortcutKey = inputData.ShortcutKey;
        this.ShortcutText = inputData.ShortcutText;
        this.TextTypeID = inputData.TextTypeID;
    }
}
