<div class="container h-100">
  <div class="row justify-content-center align-items-center h-100">
    <div class="oauth-box col col-12" *ngIf="viewConnecting">
      <h1>{{stateInfo.action == "connect" ? "Ihr Account wird verbunden...." : "Sie werden eingeloggt..."}}</h1>
      <i class="fa fa-spinner fa-spin fa-pulse oauth-spinner"></i>
      <h3>Dies nimmt nur ein paar Sekunden in Anspruch. Bitte schließen Sie diese Seite nicht.</h3>
    </div>
    <div class="oauth-box col col-12" *ngIf="viewSuccess">
        <h1 class="oauth-notice-success">
          <i class="fa fa-solid fa-check oauth-mark"></i>
          Ihr Account wurde erfolgreich verbunden!
        </h1>
      <button routerLink="/profile" routerLinkActive="active" mat-raised-button class="w-100" color="primary">Zurück zum Profil</button>
    </div>
    <div class="oauth-box col col-12" *ngIf="viewError">
      <h1 class="oauth-notice-error">
        <i class="fa fa-solid fa-exclamation oauth-mark"></i>
        Bei der Verarbeitung ist ein Fehler aufgetreten.
      </h1>
      <h3>Bitte versuchen Sie es erneut. Sollte der Fehler bestehen bleiben, leiten Sie die folgenden Fehlerdetails bitte weiter: <span class="error-details">{{errorDetails}}</span></h3>
      <button routerLink="/profile" routerLinkActive="active" mat-raised-button class="w-100" color="primary">Zurück zum Profil</button>
  </div>
  </div>
</div>
