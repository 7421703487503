<mat-list *ngIf="answered">
  <mat-list-item *ngFor="let question of answeredQuestions">
    <h3 matLine>{{ question.label }}</h3>
    <p matLine>
      <span> {{ question.answer }} </span>
    </p>
  </mat-list-item>
</mat-list>

<div *ngIf="!answered">
  <h4>Diese Umfrage wurde noch nicht beantwortet!</h4>
</div>
