import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MatLegacySelectionList as MatSelectionList } from '@angular/material/legacy-list';
import { ServiceProviderTypes } from '../../../shared/collections/service-provider-types';

@Component({
  selector: 'app-portal-filter-dialog',
  templateUrl: './portal-filter-dialog.component.html',
  styleUrls: ['./portal-filter-dialog.component.css']
})
export class PortalFilterDialogComponent implements OnInit {
  private portals;
  public groups;
  public members;
  public filteredPortals;
  public serviceProviderTypeID: Number = null;
  public portalFilterText: String;
  public serviceProviderTypes = ServiceProviderTypes;

  @ViewChild(MatSelectionList) portalList: MatSelectionList;

  constructor(public dialogRef: MatDialogRef<PortalFilterDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.portals = data.portals.slice();
    this.groups = data.groups?.slice();
    this.members = data.members?.slice();
    this.filteredPortals = this.sortByFavorite(data.portals.slice());
  }

  ngOnInit() {
    // this.portalList.selectedOptions = new SelectionModel<MatListOption>(false);
    // this.portalList.selectedOptions._multiple = false;
  }

  public filterPortals(searchText, portalType) {
    const filtered = this.portals.filter(
      (item) =>
        (!searchText ||
          item.Name.toLowerCase().indexOf(searchText.toLowerCase()) > -1 ||
          item.ZIPCode?.toLowerCase().indexOf(searchText.toLowerCase()) > -1) &&
        (!portalType ||
          (portalType < 0
            ? this.getPortalsInGroup(portalType * -1).includes(item.idx)
            : item.ServiceProviderTypeID === portalType))
    );
    this.filteredPortals = filtered;
    return filtered;
  }

  sortByFavorite(portals) {
    const favorites = JSON.parse(JSON.parse(localStorage.getItem('userobject')).FavoritePortalsJSON);
    return favorites
      ? portals.sort((a, b) => {
          if (favorites.includes(a.idx) && !favorites.includes(b.idx)) {
            return -1;
          }
          if (!favorites.includes(a.idx) && favorites.includes(b.idx)) {
            return 1;
          }
          return a.idx - b.idx;
        })
      : portals;
  }

  public getPortalsInGroup(groupID) {
    const groups = this.members.filter((item) => item.GroupID == groupID);
    return groups.map((item) => item.PortalID);
  }

  public getPortalLabel(portalObject) {
    return portalObject
      ? [portalObject.Name, portalObject.ZIPCode, portalObject.City].filter((val) => !!val).join(' ')
      : '';
  }
}
