import { Component, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { ShortcodeService } from '../../shared/services/shortcode.service';
import { TextBlockDetailComponent } from './text-block-detail/text-block-detail.component';
import { XConfigShortcode } from '../../shared/classes/xconfig-shortcode';

@Component({
	selector: 'app-text-block',
	templateUrl: './text-block.component.html',
	styleUrls: [ './text-block.component.css' ]
})
export class TextBlockComponent implements OnInit {
	public clickLock = false;

	constructor(private shortCodeService: ShortcodeService, private dialog: MatDialog) {}

	async ngOnInit() {
		await this.shortCodeService.read();
		this.getShortCodes();
	}

	edit(data) {
		if (!this.clickLock) {
			this.clickLock = true;
			const dialogRef = this.dialog.open(TextBlockDetailComponent, {
				data: new XConfigShortcode(data)
			});

			// await dialogRef.afterClosed().subscribe(async result => {
			//   if (result) {
			//     const shortCodeObject = new XConfigShortcode({
			//       ShortcutKey: result.shortcutKey,
			//       ShortcutText: result.shortcutText
			//     });
			//     await this.shortCodeService.update(data, shortCodeObject);
			//   }
			// });

			this.clickLock = false;
		} else {
			console.log('Clicklock');
		}
	}

	public showDetailDialog() {
		if(this.dialog.openDialogs.some(openDialog => openDialog.componentInstance instanceof TextBlockDetailComponent)){
			return;
		  }
		const dialogRef = this.dialog.open(TextBlockDetailComponent, {});

		// await dialogRef.afterClosed().subscribe(async result => {
		//   console.log(result);
		//   const shortCodeObject = new XConfigShortcode({
		//     ShortcutKey: result.shortcutKey,
		//     ShortcutText: result.shortcutText
		//   });
		//   await this.shortCodeService.insert(shortCodeObject);
		// });
	}

	public getShortCodes() {
		return this.shortCodeService.getShortCodes();
	}
}
