<label class="file-upload" (drop)="onDrop($event)">
  <input class="fileupload" accept=".pdf,.doc,.docx,.jpg,.jpeg" type="file" multiple="true" (change)="onFileAdded($event)" />
  <i class="fa fa-plus"></i>
  <div class="file-upload-text">
    <div class="file-upload-text--large">Dateien zum Hochladen auswählen</div>
    <div class="file-upload-text--small">(oder hierher ziehen)</div>
  </div>
</label>
<div class="formgroup" *ngIf="uploadErrorMessage">
    <div class="alert alert-warning" role="alert"> {{uploadErrorMessage}}</div>
  </div>