<div (click)="close()" class="d-flex flex-row-reverse">
  <i class="fas fa-times hover-cursor"></i>
</div>
<h2 mat-dialog-title>{{ this.header }}</h2>

<mat-dialog-content [formGroup]="form">
  <div [ngClass]="(screenSize$ | async) !== APP_SCREEN_SIZES.small ? 'form-container' : 'mobile-container'">
    <div>
      <mat-form-field>
        <mat-select placeholder="Titel" formControlName="title" [(ngModel)]="this.favorite.PersonTitle">
          <mat-option></mat-option>
          <mat-option *ngFor="let title of personTitles" [value]="title">
            {{ title }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field>
        <input
          matInput
          minlength="1"
          placeholder="Vorname"
          formControlName="firstName"
          [(ngModel)]="this.favorite.FirstNameEn"
        />
      </mat-form-field>
      <mat-form-field>
        <input
          matInput
          minlength="1"
          placeholder="Nachname"
          formControlName="lastName"
          [(ngModel)]="this.favorite.LastNameEn"
        />
      </mat-form-field>
      <mat-form-field>
        <input type="email" matInput placeholder="EMail" formControlName="email" [(ngModel)]="this.favorite.EMail" />
      </mat-form-field>
      <mat-form-field>
        <input
          type="tel"
          tel
          matInput
          placeholder="Telefonnummer"
          [(ngModel)]="this.favorite.PhoneMobile"
          formControlName="phone"
          name="phoneNo"
        />
        <!-- <mat-error *ngIf="phone?.touched">Bitte geben Sie eine gültige Telefonnummer ein</mat-error> -->
      </mat-form-field>
    </div>
    <div>
      <mat-form-field>
        <input
          matInput
          placeholder="Standard-Code"
          minlength="6"
          maxlength="20"
          formControlName="password"
          pattern="[a-z0-9_-]{6,20}"
          [(ngModel)]="favorite.DefaultPW"
        />
        <mat-error *ngIf="this.favorite.DefaultPW?.errors?.pattern && this.favorite.DefaultPW?.touched"
          >Der Code muss aus 6-20 Buchstaben und Zahlen bestehen.
        </mat-error>
      </mat-form-field>

      <mat-form-field>
        <textarea
          type="note"
          matInput
          cdkTextareaAutosize
          placeholder="Notizen"
          formControlName="note"
          [(ngModel)]="this.favorite.Note"
        ></textarea>
      </mat-form-field>

      <mat-checkbox [ngModelOptions]="{ standalone: true }" [(ngModel)]="notNotified" name="notNotified"
        >Will nicht kontaktiert werden</mat-checkbox
      >
      <br />
      <mat-checkbox [ngModelOptions]="{ standalone: true }" [(ngModel)]="sendSMS" name="sendSMS"
        >Code per SMS zuschicken</mat-checkbox
      >

      <!--button mat-raised-button class="w-100" color="primary" (click)="registerPhone()" *ngIf="!this.favorite.ETresorUID"-->
      <!--img src="../../../../assets/images/handysignatur.jpg" alt="Handysignatur" srcset=""-->
      <!--ETresor-Account verbinden-->
      <!--/button-->
      <!--div class="col col-4 alert alert-success text-center" role="alert" *ngIf="this.favorite.ETresorUID"-->
      <!-- -->
      <!--i class="fa fa-check"></i-->
      <!--ETresor-Account verbunden-->

      <!--/div-->
    </div>
  </div>
  <div *ngIf="displayExtension">
    <hr />
    <div class="row">
      <div class="col-12 col-lg-6">
        <app-favorites-extension
          [form]="this.form"
          [favorite]="this.favorite"
          [editMode]="editMode"
        ></app-favorites-extension>
      </div>
      <div class="col-12 col-lg-6">
        <!-- <div class="row">
        <div class="col-12"> -->
        <ng-container *ngIf="requestsState$ | async as requestsState">
          <app-requests-contact-list
            [requests]="requestsState.items"
            (itemClicked)="showRequestDialog($event, favorite)"
          ></app-requests-contact-list>
        </ng-container>
        <!-- </div>
      </div> -->
      </div>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions class="d-inline">
  <button *ngIf="editMode" class="float-left" mat-button color="warn" (click)="delete()">
    Entfernen
  </button>
  <!-- <app-request-button-view
    [patientID]="this.favorite.id"
    *ngIf="displayExtension && editMode"
  ></app-request-button-view> -->

  <button
    class="float-right"
    mat-button
    color="primary"
    [disabled]="!favorite.FirstNameEn || !favorite.LastNameEn"
    (click)="save()"
  >
    Speichern
  </button>

  <button (click)="saveAndAddRequest(favorite)" class="float-right" *ngIf="displayExtension" mat-raised-button color="primary">
    Speichern &amp; Anfrage erstellen
  </button>

  <button (click)="addPatientRequest(favorite.id)" *ngIf="displayExtension && favorite.id" mat-raised-button color="primary" class="float-right">
    Anfrage erstellen
  </button>


</mat-dialog-actions>
