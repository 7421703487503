import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import * as moment from 'moment';
import { BehaviorSubject, merge, Observable, of, Subject } from 'rxjs';
import { catchError, map, scan, switchMap } from 'rxjs/operators';
import { Favorite } from '../../../../shared/classes/favorite';
import { PatientRequest } from '../../../../shared/classes/patient-request';
import { FavoritesService } from '../../../../shared/services/favorites.service';
import { RequestsDialogComponent } from '../../components/requests-dialog/requests-dialog.component';
import { RequestsService } from '../../services/requests.service';

@Component({
  selector: 'app-requests-view',
  templateUrl: './requests-view.component.html',
  styleUrls: ['./requests-view.component.css']
})
export class RequestsViewComponent implements OnInit {
  // @Input() filter: string;
  requests$: Observable<any[]>;
  favorites: Favorite[];

  filterForm: UntypedFormGroup;
  filterStatusID: number = null;
  filterString = '';
  statusValues = [
    { value: 0, text: 'Offen' },
    { value: 1, text: 'In Bearbeitung' },
    { value: 2, text: 'Resultat Erhalten' },
    { value: 3, text: 'Versendet' }
  ];

  private readRequests$$: BehaviorSubject<{ fromDate: string; toDate: string }>;
  private updateRequest$$ = new Subject<PatientRequest>();

  public currentSelection: PatientRequest[];

  constructor(
    private requestsService: RequestsService,
    private favoritesService: FavoritesService,
    private dialog: MatDialog,
    private formBuilder: UntypedFormBuilder
  ) {}

  async ngOnInit() {
    this.initFilterForm();
    this.favorites = await this.favoritesService.reloadFavorites();
    // const filterUpdates = this.filterForm.valueChanges.pipe(tap((value) => console.log(value))).subscribe();
    this.readRequests$$ = new BehaviorSubject({
      fromDate: this.filterForm.get('fromDate').value.toISOString(),
      toDate: this.filterForm.get('toDate').value.toISOString()
    });
    this.requests$ = merge(
      this.readRequests$$.pipe(
        switchMap(({ fromDate, toDate }) =>
          this.requestsService.readRequests({
            fromDate,
            toDate
          })
        ),

        map((result) => ({ data: result, action: 'read' }))
      ),
      this.updateRequest$$.pipe(map((item) => ({ data: item, action: 'update' })))
    ).pipe(
      catchError((err) => of({ action: 'error' })),
      scan((acc, curr: { action?: string; data?: any }) => {
        if (curr.action === 'read') {
          return [...curr.data];
        }
        if (curr.action === 'update') {
          return acc.map((item) => (item.id === curr.data.id ? curr.data : item));
        }
        return acc;
      }, []),

      // set patientName for each PatientRequest entry to enable filtering/sorting
      map((result: PatientRequest[]) =>
        result.map((item) => {
          if (item.patientName) return item;

          const patientID = item.PatientID;
          const favorite = this.favorites?.find((favorite) => favorite.id === patientID);
          const patientName = [favorite?.FirstNameEn, favorite?.LastNameEn].filter((value) => !!value).join(' ');
          return { ...item, patientName };
        })
      )
    );
  }

  initFilterForm() {
    this.filterForm = this.formBuilder.group({
      fromDate: [moment.utc().startOf('day')],
      toDate: [moment.utc().endOf('day')]
    });
  }

  filterItems(filterValues) {
    const { fromDate, toDate } = filterValues;
    this.readRequests$$.next({ fromDate: fromDate.toISOString(), toDate: toDate.toISOString() });
  }

  showDialog(request: PatientRequest) {
    if (this.dialog.openDialogs.some((openDialog) => openDialog.componentInstance instanceof RequestsDialogComponent)) {
      return;
    }
    const favorite = this.favorites.find((item) => item.id == request.PatientID);
    const dialogRef = this.dialog.open(RequestsDialogComponent, {
      width: '900px',
      autoFocus: false,
      data: {
        request,
        updateRequestItem: (requestData: PatientRequest) => {
          this.requestsService.updateRequest(requestData).then((result) => this.updateRequest$$.next(result));
        },
        getPdf: (requestData: PatientRequest, sendToMA: boolean) =>
          this.requestsService.printRequestResult(requestData, favorite, sendToMA),
        deleteRequest: (requestData: PatientRequest) => this.requestsService.deleteRequest(requestData)
      }
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (!result) {
        return;
      }
      console.log(result);
    });
  }

  selectionChanged(changedSelection: PatientRequest[]) {
    this.currentSelection = [...changedSelection];
  }

  onSendSelected(selectedRequests: PatientRequest[]) {
    return this.requestsService.sendRequestCollection(selectedRequests).catch(console.log);
  }
}
