<div class="row" >
    <div class="profile-box  col-12 mt-4">
      <h4>Aktives Abo: {{aboName}}</h4>
    </div>
  </div>
<div class="row" *ngIf="filterUpgradePromotions(promotionData).length">
  <div class="profile-box col-12 mt-4" *ngFor="let promotion of filterUpgradePromotions(promotionData)">
    <div class="row">
      <div class="col col-12">
        <h4>{{promotion.PromoName}}</h4>
        <span>{{promotion.PromoText}}</span>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col col-12 col-md-4 offset-md-4">
        <button mat-raised-button class="w-100" color="primary" *ngIf="!saveInProgress" (click)="orderPromotion(promotion)">
          {{promotion.FutureAboTypeID >= 0 ? 'Kostenpflichtig bestellen' : 'Bestellen' }}
        </button>
        <!-- <mat-error class="error-msg" *ngIf="registerErrorMsg">{{registerErrorMsg}}</mat-error>  -->
        <button class="w-100" color="primary" mat-raised-button disabled *ngIf="saveInProgress">
          <i class="fa fa-spinner fa-spin fa-pulse"></i>
        </button>
      </div>
    </div>
  </div>
</div>
