export const MessageTypes = {
    values: [
        { id: 0, value: 'E-Mail' },
        { id: 1, value: 'Bx User' },
        { id: 2, value: 'B2B' },
        { id: 3, value: 'B2B' },
        { id: 4, value: 'B2B' },
        { id: 12, value: 'B2C' },
        { id: 13, value: 'B2C' },
        { id: 21, value: 'B2C' },
        { id: 22, value: 'B2C' },
        { id: 23, value: 'B2C' },
        { id: 31, value: 'B2C' },
        { id: 32, value: 'B2B' },
    ],
    get(id?: number) {
        return typeof id === 'undefined' ? this.values.slice() : this.values.find(item => item.id === id);
    }
};

