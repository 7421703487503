<div class="text-center">
  <h2 mat-dialog-title>Dokument öffnen</h2>
  <div class="row">
    <div class="col col-10 offset-1" *ngIf="!downloadData.RecipientName">
      <label>Absender:</label>
      <span>{{ downloadData.SenderPortalName }}</span>
    </div>
    <div class="col col-10 offset-1" *ngIf="downloadData.RecipientName">
      <label>Empfänger:</label>
      <span>{{ downloadData.RecipientName }}</span>
    </div>
  </div>
  <div class="row">
    <div class="col col-10 offset-1">
      <label>{{ downloadData.documentType === 1 ? 'Versendet' : 'Empfangen' }} am:</label>
      <span>{{ downloadData?.CreatedOn | date: 'dd.MM.yyyy HH:mm' }}</span>
    </div>
  </div>
  <div class="row" *ngIf="downloadData.AffectedPersonFirstName || downloadData.AffectedPersonLastName">
    <div class="col col-10 offset-1">
      <label>Betroffene Person/Patient:</label>
      <span>{{ downloadData.AffectedPersonTitle || '' }} </span>
      <span>{{ downloadData.AffectedPersonFirstName || '' }} </span>
      <span>{{ downloadData.AffectedPersonLastName || '' }}</span>
    </div>
  </div>
  <div class="row" *ngIf="downloadData.SenderCommentsSender">
    <div class="col col-10 offset-1">
      <label>Hinweise:</label>
      <span class="multi-line-text">{{ downloadData.SenderCommentsSender }}</span>
    </div>
  </div>
  <div class="row" *ngIf="downloadData.documentType === 2">
    <div class="col col-10 offset-1">
      <mat-form-field>
        <textarea type="note" matInput [(ngModel)]="downloadData.RecipientComments"></textarea>
      </mat-form-field>
      <button class="w-80 send-again" color="secondary" mat-raised-button (click)="update()">Speichern</button>
    </div>
  </div>

  <div class="row">
    <div class="col col-10 offset-1 mt-3">
      <button class="w-100" color="primary" mat-raised-button disabled *ngIf="!downloadData.downloadLinks">
        <i class="fa fa-spinner fa-spin fa-pulse"></i>
      </button>
      <!-- <a [href]="downloadData.downloadLink" target="_blank"> -->
      <div [ngClass]="{ 'form-group': !last }" *ngFor="let downloadLink of downloadLinks; last as last; index as index">
        <a
          mat-raised-button
          color="primary"
          class="w-100"
          (click)="setSeen(downloadData)"
          [href]="downloadLink"
          target="_blank"
          >{{
            downloadData.fileNames
              ? downloadData.fileNames[index]
              : downloadLinks.length > 1
              ? 'Dokument ' + (index + 1)
              : ''
          }}
          Öffnen</a
        >
      </div>
      <!-- </a> -->
    </div>
  </div>
  <div class="row">
    <div class="col col-10 offset-1" *ngIf="downloadData.RecipientName">
      <button class="w-80 send-again" color="secondary" mat-raised-button (click)="sendAgain()">Erneut Senden</button>
    </div>
  </div>
</div>
