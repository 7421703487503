import { Injectable } from '@angular/core';
import { AzureServiceService } from './azure-service.service';
import { CuberryPromotion } from '../classes/cuberry-promotion';
@Injectable()
export class PortalsService {
	private portals = [];
	private portalTable;
	constructor(private azureService: AzureServiceService) {
		this.portalTable = azureService.client.getTable('CuberryPortals');
	}

	public async update(portalObject) {
		return await this.portalTable.update({
			id: portalObject.id,
			Name: portalObject.Name,
			EmailHost: portalObject.EmailHost,
			ZIPCode: portalObject.ZIPCode,
			City: portalObject.City,
			ShowInBxAddressBook: portalObject.ShowInBxAddressBook,
			FileAvailabilityIn: portalObject.FileAvailabilityIn,
			FileAvailabilityOut: portalObject.FileAvailabilityOut,
			SignWithName: portalObject.SignWithName,
			LocalFileDLPath: portalObject.LocalFileDLPath
		});
	}

	public async updateAboType(portalObject, promotionObject: CuberryPromotion) {
		return await this.portalTable.update({
			id: portalObject.id,
			AboType: promotionObject.FutureAboTypeID,
			AboStartDate: promotionObject.ServiceStartDate || new Date()
		});
	}

	public async getAll() {
		return await this.portalTable.read({ RequestFrom: 'ListView' });
	}

	public async getOwnPortal() {
		return await this.portalTable.read();
	}
}
