import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule, FormControl, Validators } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatSortModule } from '@angular/material/sort';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatDatepickerModule } from '@angular/material/datepicker';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { LoginComponent } from './public/login/login.component';
import { AzureServiceService } from './shared/services/azure-service.service';
import { DownloadService } from './shared/services/download.service';
import { UploadComponent } from './protected/upload/upload.component';
import { FileUploaderComponent } from './shared/components/file-uploader/file-uploader.component';
import { DownloadComponent } from './public/download/download.component';
import { NavbarComponent } from './protected/navbar/navbar.component';
import { ProtectedComponent } from './protected/protected.component';
import { RegisterComponent } from './public/register/register.component';
import { ReportLogComponent } from './protected/report-log/report-log.component';
import { PasswordResetComponent } from './public/password-reset/password-reset.component';
import { ProfileComponent } from './protected/profile/profile.component';
import { AgbComponent } from './public/agb/agb.component';
import { HomeComponent } from './public/home/home.component';
import { StrRegexPipe } from './shared/pipes/str-regex.pipe';
import { ContactUsComponent } from './shared/components/contact-us/contact-us.component';
import { FooterComponent } from './shared/components/footer/footer.component';
import { SendFileComponent } from './protected/upload/send-file/send-file.component';
import { PortalsService } from './shared/services/portals.service';
import { ReportLogDetailComponent } from './protected/report-log/report-log-detail/report-log-detail.component';
import { PortalFilterDialogComponent } from './protected/upload/portal-filter-dialog/portal-filter-dialog.component';
import { PromotionComponent } from './protected/profile/promotion/promotion.component';
import { PromotionOrderedDialogComponent } from './protected/profile/promotion-ordered-dialog/promotion-ordered-dialog.component';
import { ApiUserComponent } from './protected/profile/api-user/api-user.component';
import { ConfirmDialogComponent } from './shared/components/confirm-dialog/confirm-dialog.component';
import { MacroDirective } from './shared/directives/macro.directive';
import { TextBlockComponent } from './protected/text-block/text-block.component';
import { TextBlockDetailComponent } from './protected/text-block/text-block-detail/text-block-detail.component';
import { ShortcodeService } from './shared/services/shortcode.service';
import { FavoritesComponent } from './protected/favorites/favorites.component';
import { FavoritesService } from './shared/services/favorites.service';
import { FavoritesDetailComponent } from './protected/favorites/favorites-detail/favorites-detail.component';
import { FavoritesDialogComponent } from './protected/upload/favorites-dialog/favorites-dialog.component';
import { Hl7Service } from './shared/services/hl7.service';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MatGridListModule } from '@angular/material/grid-list';
import { ImpressumComponent } from './shared/components/footer/impressum/impressum.component';
import { PricingComponent } from './protected/pricing/pricing.component';
import { PricingMobileComponent } from './protected/pricing-mobile/pricing-mobile.component';
import { ApiDetailComponent } from './protected/profile/api-user/api-detail/api-detail.component';
import { AdmissionComponent } from './protected/admission/admission.component';
import { AdmissionLogDetailComponent } from './protected/report-log/admission-log-detail/admission-log-detail.component';
import { PhoneSignatureModule } from './protected/features/phone-signature/phone-signature.module';
import { ETresorComponent } from './public/etresor/etresor.component';
import { SignDialogComponent } from './protected/upload/sign-dialog/sign-dialog.component';
import { SmsStatsModule } from './protected/sms-stats/sms-stats.module';
import { FavoritesExtensionComponent } from './protected/favorites/favorites-extension/favorites-extension.component';
import { MomentDateModule, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { RequestsModule } from './protected/requests/requests.module';
import { MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material/core';
import { ConnectedAccountsComponent } from './protected/profile/api-user/connected-accounts/connected-accounts.component';
import { ConnectedAccountsListComponent } from './shared/components/connected-accounts-list/connected-accounts-list.component';
import { OauthComponent } from './public/oauth/oauth.component';
import { RegistrationService } from './shared/services/registration.service';
import { MessageDialogComponent } from './shared/components/message-dialog/message-dialog.component';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    UploadComponent,
    FileUploaderComponent,
    DownloadComponent,
    NavbarComponent,
    ProtectedComponent,
    RegisterComponent,
    ReportLogComponent,
    PasswordResetComponent,
    ProfileComponent,
    OauthComponent,
    AgbComponent,
    HomeComponent,
    StrRegexPipe,
    ContactUsComponent,
    FooterComponent,
    SendFileComponent,
    ReportLogDetailComponent,
    PortalFilterDialogComponent,
    PromotionComponent,
    PromotionOrderedDialogComponent,
    ApiUserComponent,
    ConnectedAccountsComponent,
    ConfirmDialogComponent,
    MessageDialogComponent,
    MacroDirective,
    TextBlockComponent,
    TextBlockDetailComponent,
    FavoritesComponent,
    FavoritesDetailComponent,
    FavoritesDialogComponent,
    ImpressumComponent,
    PricingComponent,
    PricingMobileComponent,
    ApiDetailComponent,
    AdmissionComponent,
    AdmissionLogDetailComponent,
    ETresorComponent,
    SignDialogComponent,
    FavoritesExtensionComponent,
    ConnectedAccountsListComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatDatepickerModule,
    MomentDateModule,
    MatMenuModule,
    MatSelectModule,
    MatCheckboxModule,
    MatTableModule,
    MatSortModule,
    MatSnackBarModule,
    MatRadioModule,
    MatAutocompleteModule,
    MatDialogModule,
    MatListModule,
    MatGridListModule,
    MatCardModule,
    MatProgressSpinnerModule,
    NgbModule,
    MatExpansionModule,
    PhoneSignatureModule,
    SmsStatsModule,
    RequestsModule
  ],
  providers: [
    AzureServiceService,
    DownloadService,
    PortalsService,
    ShortcodeService,
    FavoritesService,
    Hl7Service,
    RegistrationService,
    UploadComponent,
    PricingComponent,
    AdmissionComponent,
    SendFileComponent,
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    { provide: MAT_DATE_LOCALE, useValue: 'de-AT' },
    // { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
    {
      provide: MAT_DATE_FORMATS,
      useValue: {
        parse: {
          dateInput: ['DDMMYYYY', 'DD.MM.YYYY', 'DDMMYY']
        },
        display: {
          dateInput: 'DD.MM.YYYY',
          monthYearLabel: 'MMM YYYY',
          dateA11yLabel: 'LL',
          monthYearA11yLabel: 'MMMM YYYY'
        }
      }
    }
  ],
  // entryComponents: [
  //   ReportLogDetailComponent,
  //   PortalFilterDialogComponent,
  //   PromotionOrderedDialogComponent,
  //   ConfirmDialogComponent,
  //   MessageDialogComponent,
  //   TextBlockDetailComponent,
  //   FavoritesDetailComponent,
  //   FavoritesDialogComponent,
  //   ImpressumComponent,
  //   ApiDetailComponent,
  //   AdmissionLogDetailComponent,
  //   SignDialogComponent
  // ],
  bootstrap: [AppComponent]
})
export class AppModule {}
