<div *ngIf="requests?.length > 0">
  <mat-table #table [dataSource]="dataSource" matSortActive="reqdate" matSortDirection="desc" matSort>
    <ng-container matColumnDef="RequestDate">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Datum der Anfrage</mat-header-cell>
      <mat-cell *matCellDef="let entry">{{ entry.RequestDate | date: 'dd.MM.yyyy HH:mm' }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="RequestNo">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Nummer</mat-header-cell>
      <mat-cell *matCellDef="let entry">{{ entry.RequestNo }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="patientName">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Patient</mat-header-cell>
      <mat-cell class="description-cell" *matCellDef="let entry">{{ entry.patientName }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="ResultDate">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Datum des Ergebnisses</mat-header-cell>
      <mat-cell *matCellDef="let entry">{{ entry.ResultDate | date: 'dd.MM.yyyy HH:mm' }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="StatusID">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Status</mat-header-cell>
      <mat-cell *matCellDef="let entry">
        {{ status[entry.StatusID] }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="RequestResultsJSON.testTemplateName">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Testart</mat-header-cell>
      <mat-cell *matCellDef="let entry">
        {{ entry?.RequestResultsJSON?.testTemplateName }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="RequestResultsJSON.testResult">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Ergebnis</mat-header-cell>
      <mat-cell *matCellDef="let entry">
        {{ entry?.RequestResultsJSON?.testResult ? resultMap[entry.RequestResultsJSON.testResult] : '' }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="select">
      <mat-header-cell *matHeaderCellDef>
        <mat-checkbox
          (change)="$event ? masterToggle() : null"
          [checked]="selection.hasValue() && isAllSelected()"
          [indeterminate]="selection.hasValue() && !isAllSelected()"
        >
        </mat-checkbox>
      </mat-header-cell>
      <mat-cell *matCellDef="let row" (click)="$event.stopPropagation()">
        <mat-checkbox (change)="$event ? selection.toggle(row) : null" [checked]="selection.isSelected(row)">
        </mat-checkbox>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>

    <mat-row *matRowDef="let row; columns: displayedColumns" (click)="showDialog.emit(row)"></mat-row>


  </mat-table>
  <mat-paginator [pageSize]="100"></mat-paginator>
</div>
<div *ngIf="requests?.length === 0">Keine Anfragen vorhanden</div>
