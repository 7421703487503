import { Component, OnInit, Input } from '@angular/core';
import { RequestsClientService } from '../../clients/requests-client.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-request-button-view',
  templateUrl: './request-button-view.component.html',
  styleUrls: ['./request-button-view.component.css']
})
export class RequestButtonViewComponent implements OnInit {
  @Input() patientID;
  requestStatus = 0;

  constructor(private requestsService: RequestsClientService, private dialog: MatDialog) {}

  ngOnInit(): void {}

  async sendPatientRequest() {
    if (!this.patientID) {
      return;
    }
    this.requestStatus = 1;
    console.log('reqPending');
    await this.requestsService.sendRequest(this.patientID);
    console.log('reqDone');
    this.requestStatus = 2;
    setTimeout(() => {
      this.dialog.closeAll();
    }, 300);
  }
}
