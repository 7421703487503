import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SmsStatsViewComponent } from './containers/sms-stats-view/sms-stats-view.component';
import { SmsStatsComponent } from './components/sms-stats/sms-stats.component';
import { SmsStatsClientService } from './clients/sms-stats-client.service';
import { SmsStatsService } from './services/sms-stats.service';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatLegacyPaginatorModule as MatPaginatorModule } from '@angular/material/legacy-paginator';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';



@NgModule({
  // entryComponents: [SmsStatsViewComponent],
  declarations: [SmsStatsViewComponent, SmsStatsComponent],
  providers:[SmsStatsClientService, SmsStatsService],
  imports: [
    CommonModule,
    MatInputModule,
    MatTableModule,
    MatPaginatorModule,
    MatProgressSpinnerModule
  ],
  exports: [SmsStatsViewComponent]
})
export class SmsStatsModule { }
