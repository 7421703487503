import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { DocumentDownload } from '../../shared/classes/document-download';
import { MessageTypes } from '../../shared/collections/message-types';
import { PortalTypes } from '../../shared/collections/portal-types';
import { ApplicationStateService, APP_SCREEN_SIZES } from '../../shared/services/application-state.service';
import { AzureServiceService } from '../../shared/services/azure-service.service';
import { DownloadService } from '../../shared/services/download.service';
import { FavoritesService } from '../../shared/services/favorites.service';
import { ReportLogDetailComponent } from './report-log-detail/report-log-detail.component';

@Component({
  selector: 'app-report-log',
  templateUrl: './report-log.component.html',
  styleUrls: ['./report-log.component.css']
})
export class ReportLogComponent implements OnInit, AfterViewInit {
  private initData: DocumentDownload[] = [];
  public dataSource = new MatTableDataSource(this.initData);
  public dataSourceReceived = new MatTableDataSource(this.initData);
  // public dataSourceAdmission = new MatTableDataSource(this.initData);
  public dataSourceNoFilter = { sent: null, received: null };
  public displayRequests = JSON.parse(localStorage.getItem('userobject')).PortalTypeID === 99;
  screenSize$: Observable<string>;
  APP_SCREEN_SIZES = APP_SCREEN_SIZES;

  // private downloadTable;
  public loading = false;
  public displayedColumns = [
    'MessageTypeID',
    'CreatedOn',
    'RecipientName',
    'AffectedPersonTitle',
    'AffectedPersonFirstName',
    'AffectedPersonLastName',
    'InternalNote',
    'TextStatus',
    'DownloadStatus'
  ];
  public displayedColumnsMobile = [
    'MessageTypeID',
    'CreatedOn',
    'RecipientName',
    'AffectedPersonTitle',
    'AffectedPersonFirstName',
    'AffectedPersonLastName',
    'InternalNote',
    'TextStatus'
  ];
  public displayedColumnsReceived = [
    'CreatedOn',
    'SenderPortalName',
    'AffectedPersonTitle',
    'AffectedPersonFirstName',
    'AffectedPersonLastName',
    'Status'
  ];
  // public displayedColumnsAdmission = [
  //   'MessageTypeID',
  //   'CreatedOn',
  //   'TemplateName',
  //   'AffectedPersonTitle',
  //   'AffectedPersonFirstName',
  //   'AffectedPersonLastName',
  //   'Status'
  // ];

  public filterOptions = [
    { name: 'Alle Ergebnisse', value: 'all' },
    { name: 'Jahr', value: 'year' },
    { name: 'Monat', value: 'month' },
    { name: 'Woche', value: 'week' },
    { name: 'Heute', value: 'today' }
  ];

  public messageTypes = MessageTypes;
  public portalTypes = PortalTypes;

  private tableClickLock = false;
  public documentType = 1;
  public dateFilter = 'today';
  private endDate: Date;

  filterForm: UntypedFormGroup;

  public cachedEntries: any[] = [];

  @ViewChild('table', { read: MatSort, static: true })
  sort: MatSort;
  @ViewChild('tableReceived', { read: MatSort, static: true })
  sortReceived: MatSort;
  // @ViewChild('tableAdmission', { read: MatSort, static: true })
  // sortAdmission: MatSort;

  constructor(
    private azureClient: AzureServiceService,
    private downloadService: DownloadService,
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    public snackBar: MatSnackBar,
    // private admissionService: AdmissionService,
    private favoritesService: FavoritesService,
    private appStateService: ApplicationStateService,
    private formBuilder: UntypedFormBuilder
  ) {
    // this.downloadTable = azureClient.client.getTable('CCPatientDocumentDownload');
  }

  async ngOnInit() {
    this.initFilterForm();
    this.screenSize$ = this.appStateService.screenSize$;
    await this.updateDateFilter(this.filterForm.value);
    let docIdx: any = this.route.snapshot.paramMap.get('idx');
    docIdx = docIdx ? parseInt(docIdx, 10) : null;
    if (docIdx) {
      const downloadElement = this.dataSourceReceived.data.slice().find((item) => item.idx === docIdx);
      downloadElement
        ? ((this.documentType = 2), this.selectDownloadEntry(downloadElement))
        : (this.router.navigate(['reportLog']),
          this.snackBar.open('Fehler beim Laden des Befundes', null, {
            duration: 3000,
            panelClass: 'orange-snackbar'
            // verticalPosition: 'top'
          }));
    }
    await this.setSmsStatus();
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSourceReceived.sort = this.sortReceived;
    // this.dataSourceAdmission.sort = this.sortAdmission;
  }

  async setSmsStatus() {
    this.dataSource?.data.reduce((prev, e) => {
      return prev
        .then(() => {
          return new Promise((resolve) => setTimeout(resolve, 200));
        })
        .then(() => {
          if (e.SMSID && e.SMSStatusID !== 1) {
            return this.azureClient.checkSMS(e.SMSID).then((smsStatusID) => {
              e.SMSStatusID = smsStatusID;
            });
          }
          return Promise.resolve();
        });
    }, Promise.resolve());

    // this.dataSource?.data.forEach(async (e) => {
    //   if (e.SMSID) {
    //     if (e.SMSStatusID !== 1) {
    //       e.SMSStatusID = await this.azureClient.checkSMS(e.SMSID);
    //     }
    //   }
    // });
  }

  initFilterForm() {
    this.filterForm = this.formBuilder.group({
      fromDate: [moment.utc().startOf('day')],
      toDate: [moment.utc().endOf('day')]
    });
  }

  applyFilterToSent(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  applyFilterToReceived(filterValue: string) {
    this.dataSourceReceived.filter = filterValue.trim().toLowerCase();
  }

  // applyFilterToAdmission(filterValue: string) {
  //   this.dataSourceAdmission.filter = filterValue.trim().toLowerCase();
  // }

  public async readData(readParams?: any) {
    try {
      this.loading = true;
      const result = await this.downloadService.read(readParams);

      // const visibleSent = result.Sent.filter((element) => element.CreatedOn > this.endDate);
      const sentWithNames = (result.Sent || []).map((item) => {
        item.RecipientName = item.RecipientEMail || item.RecipientPortalName;
        return item;
      });
      this.dataSource.data = sentWithNames;
      // console.log(this.dataSourceNoFilter);
      //console.log('Sent: ', this.dataSource.data);
      // const visibleReceived = result.Received.filter((element) => element.CreatedOn > this.endDate);
      // this.dataSourceNoFilter.received = result.Received;
      this.dataSourceReceived.data = result.Received;
      // const resultAdmission = await this.admissionService.getSurveys({});

      // resultAdmission.result.forEach((item) => {
      //   item.QuestionsJSON = JSON.parse(item.QuestionsJSON);
      //   item.ResultsJSON = JSON.parse(item.ResultsJSON);
      // });
      // this.dataSourceAdmission = resultAdmission.result;
      // console.log('Admission Results:', resultAdmission.result);
      await this.setSmsStatus();
      return result;
    } catch (err) {
      console.log(err);
    } finally {
      this.loading = false;
    }
  }

  public async selectDownloadEntry(data) {
    if (this.tableClickLock) {
      return;
    }
    this.tableClickLock = true;
    console.log(data);
    try {
      // if (!this.checkDownloadMaxAge(data)) {
      //   return this.showSnackbar('Dieses Dokument ist älter als eine Woche');
      // }
      const cachedEntry = this.cachedEntries.find((item) => item.idx === data.idx);
      if (cachedEntry) {
        const refresh = moment.duration(moment().diff(cachedEntry.cachedTS)).asMinutes() > 4;
        if (!refresh) {
          this.showDetailDialog(cachedEntry);
          return cachedEntry;
        } else {
          this.cachedEntries = this.cachedEntries.filter((item) => item.idx !== data.idx);
        }
      }
      const result = await this.downloadService.downloadDocumentByIDx(data);
      if (!result.fileUrls) {
        throw new Error('no fileUrl');
      }
      const entryData = {
        downloadLinks: result.fileUrls,
        patientInfo: result.patientInfo,
        documentType: this.documentType,
        fileNames: result.fileNames ? result.fileNames.split(',') : null,
        cachedTS: new Date(),
        ...data
      };
      this.cachedEntries = [...this.cachedEntries, entryData];
      this.showDetailDialog(entryData);
      return entryData;
    } catch (err) {
      console.log(err);
      this.showSnackbar('Fehler beim Laden des Befundes');
      // this.goBackToList();
    } finally {
      this.tableClickLock = false;
    }
    // this.router.navigate([`reportLog/${data.idx}`]);
  }

  public checkDownloadMaxAge(downloadEntry) {
    const maxAge = (() => {
      const tempDate = new Date();
      tempDate.setDate(tempDate.getDate() - 8);
      return tempDate;
    })();
    return downloadEntry.CreatedOn > maxAge;
  }

  public showDetailDialog(dialogData) {
    console.log(dialogData);
    if (
      this.dialog.openDialogs.some((openDialog) => openDialog.componentInstance instanceof ReportLogDetailComponent)
    ) {
      return;
    }
    const dialogRef = this.dialog.open(ReportLogDetailComponent, {
      width: '500px',
      data: dialogData
    });

    const subscribeDialog = dialogRef.componentInstance.onSubmitReason.subscribe((data) => {
      const { id, RecipientComments: recipientComments } = data;
      this.setRecipientComments(id, recipientComments);
    });
    dialogRef.afterClosed().subscribe((result) => {
      subscribeDialog.unsubscribe();
    });
  }

  private setRecipientComments(id, recipientComments) {
    console.log('setting comments: ', id, recipientComments);
    this.dataSourceReceived.data = this.dataSourceReceived.data.map((item) => {
      if (item.idx == id) {
        item.RecipientComments = recipientComments;
        console.log(item);
      }
      return item;
    });
    console.log(this.dataSourceReceived.data);
  }

  private showSnackbar(text: string) {
    this.snackBar.open(text, null, {
      duration: 3000,
      panelClass: 'orange-snackbar'
      // verticalPosition: 'top'
    });
    // dialogRef.afterClosed().subscribe(result => {
    //   this.router.navigate(['reportLog']);
    // });
  }

  public getFavoriteName(id, field) {
    if (this.favoritesService.favoriteMap) {
      const selected = this.favoritesService.favoriteMap[id];

      return selected && selected[field] ? selected[field] : null;
    }
  }

  // public selectSurvey(row) {
  //   if (row.StatusID === 2) {
  //     if (
  //       this.dialog.openDialogs.some((openDialog) => openDialog.componentInstance instanceof ReportLogDetailComponent)
  //     ) {
  //       return;
  //     }
  //     const dialogRef = this.dialog.open(AdmissionLogDetailComponent, {
  //       width: 'auto',
  //       minWidth: '500px',
  //       height: 'auto',
  //       maxHeight: '900px',
  //       data: row
  //     });
  //   } else {
  //     if (
  //       this.dialog.openDialogs.some(
  //         (openDialog) => openDialog.componentInstance instanceof AdmissionLogDetailComponent
  //       )
  //     ) {
  //       return;
  //     }
  //     const dialogRef = this.dialog.open(AdmissionLogDetailComponent, {
  //       width: '500px'
  //     });
  //   }
  // }

  public async updateDateFilter({ fromDate, toDate }: { fromDate: moment.Moment; toDate: moment.Moment }) {
    // console.log('updateFilter: ', this.dateFilter);
    await this.readData({
      fromDate: moment(fromDate).toISOString(),
      toDate: moment(toDate).toISOString()
    });
  }
}
