import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FavoritesService } from '../../shared/services/favorites.service';

@Component({
	selector: 'app-etresor',
	templateUrl: './etresor.component.html',
	styleUrls: [ './etresor.component.css' ]
})
export class ETresorComponent implements OnInit {
	private uid;
	private tid;
	public resultCode;
	public status = 0;

	constructor(private route: ActivatedRoute, private favoritesService: FavoritesService) {}

	async ngOnInit() {
		this.route.queryParams.subscribe(async (params) => {
			this.uid = params['uid'];
			this.tid = params['tid'];
			this.resultCode = params['result'];

			console.log(`UserID: ${this.uid}, TransactionID: ${this.tid}, Result: ${this.resultCode}`);
			if (this.uid && this.tid) {
				const result = await this.favoritesService.connectAccounts(this.tid, this.uid);
				this.status = 1;
				console.log(result);
			}
		});
	}
}
