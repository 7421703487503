import { Component, OnInit, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

import { CuberryPromotion } from '../../../shared/classes/cuberry-promotion';

@Component({
  selector: 'app-promotion-ordered-dialog',
  templateUrl: './promotion-ordered-dialog.component.html',
  styleUrls: ['./promotion-ordered-dialog.component.css']
})
export class PromotionOrderedDialogComponent implements OnInit {
  public promoItem: CuberryPromotion;

  constructor(
    public dialogRef: MatDialogRef<PromotionOrderedDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CuberryPromotion) {
    // console.log(data);
    this.promoItem = data;
  }

  ngOnInit() {
  }

}
