import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AtrustButtonComponent } from './components/atrust-button/atrust-button.component';
import { FavoritesService } from '../../../shared/services/favorites.service';

@NgModule({
	declarations: [ AtrustButtonComponent ],
	imports: [ CommonModule ],
	exports: [ AtrustButtonComponent ],
	providers: [ FavoritesService ]
})
export class PhoneSignatureModule {}
