<div class="container">
  <div>
    <div class="text-muted small mb-0 text-left" style="float: left;">
      Copyright &copy;
      <a href="http://www.peacequare.com/" target="_blank">Peacequare</a> {{currentYear | date: 'yyyy'}}
    </div>
    <div class="text-muted small mb-0 text-right">
      <a href="https://befundexpress.at/rechtliches" target="_blank">Rechtliches</a> |
      <a (click)="showImpressumDialog()" data-toggle="modal" data-target="#legalNoticeModal" href="#legalNoticeModal">Impressum</a><!--  |
      <a data-toggle="modal" data-target="#contactUsModal" href="#contactUsModal">Kontakt</a> -->
    </div>
  </div>
  <!-- <p class="text-muted small mb-0">Copyright &copy; <a href="http://www.peacequare.com/">Peacequare</a> 2018</p>
  <p class="text-muted small mb-0"><a href="rechtliches.html">Rechtliches</a> | <a data-toggle="modal" data-target="#legalNoticeModal" href="#legalNoticeModal">Impressum</a> | <a data-toggle="modal" data-target="#contactUsModal" href="#contactUsModal">Kontakt</a></p> -->
</div>