import { Component, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';

import { PersonTitles } from '../../shared/collections/person-titles';
import { UserModel } from '../../shared/classes/user-model';
import { ProfileService } from './services/profile.service';
import { PortalsService } from '../../shared/services/portals.service';
import { ApiUserService } from './services/api-user.service';
import { CuberryPromotion } from '../../shared/classes/cuberry-promotion';
import { PromotionOrderedDialogComponent } from './promotion-ordered-dialog/promotion-ordered-dialog.component';
import { AdmissionService } from './services/admission.service';

@Component({
	selector: 'app-profile',
	templateUrl: './profile.component.html',
	styleUrls: [ './profile.component.css' ],
	providers: [ ProfileService, PortalsService ]
})
export class ProfileComponent implements OnInit {
	public userObject: UserModel;
	public portalObject;
	public confirmPW: '';
	public updatePending = false;
	public pwChangePending = false;
	public updateAboTypePending = false;
	public salutations = [ { id: 1, value: 'Herr' }, { id: 2, value: 'Frau' } ];
	public titles = PersonTitles;
	public promotionData;

	constructor(
		private profileService: ProfileService,
		public snackBar: MatSnackBar,
		private portalsService: PortalsService,
		private dialog: MatDialog,
		private admissionService: AdmissionService
	) {}

	async ngOnInit() {
		await this.getUser();
		await this.getPortal();
		await this.admissionService.checkUserHasAccount();
		await this.admissionService.getTemplates();
	}

	private async getUser() {
		try {
			const userResult = await this.profileService.get();
			// this.userObject = new UserModel();
			// console.log(this.userObject);
			this.userObject = new UserModel(<UserModel>userResult);
			// console.log(this.userObject);
		} catch (err) {
			console.log(err);
		}
	}

	private async getPortal() {
		try {
			const portalResult = await this.portalsService.getOwnPortal();
			// console.log(this.userObject);
			this.portalObject = portalResult;
			// console.log(this.portalObject);
		} catch (err) {
			console.log(err);
		}
	}

	public async updateUser(event) {
		event.preventDefault();
		let snackBarText;
		try {
			this.updatePending = true;
			const updateResult = await Promise.all([
				this.profileService.update(this.userObject),
				this.portalsService.update(this.portalObject)
			]);
			// this.userObject = new UserModel();
			// console.log(this.userObject);
			this.userObject = updateResult[0];
			this.portalObject = updateResult[1];
			this.confirmPW = '';
			snackBarText = 'Ihr Profil wurde erfolgreich gespeichert!';

			const localObject = JSON.parse(localStorage.getItem("userobject"));
			localObject.PersonTitle = this.userObject.PersonTitle;
			localObject.FirstName = this.userObject.FirstName;
			localObject.LastName = this.userObject.LastName;
			localStorage.setItem("userobject", JSON.stringify(localObject));

		} catch (err) {
			snackBarText = 'Fehler beim Speichern Ihres Profils!';
			console.log(err);
		} finally {
			this.snackBar.open(snackBarText, null, {
				duration: 3000,
				panelClass: 'orange-snackbar'
				// verticalPosition: 'top'
			});
			this.updatePending = false;
		}
	}

	public async updatePassword(form) {
		// event.preventDefault();
		// console.log(event);
		let snackBarText;
		try {
			this.pwChangePending = true;
			const updateResult = await this.profileService.changePassword(this.userObject);
			// this.userObject = new UserModel();
			// console.log(this.userObject);
			// this.userObject = updateResult;
			this.confirmPW = null;
			this.userObject.PW = null;
			this.userObject.NewPW = null;
			form.resetForm();
			snackBarText =
				'Ihr Passwort wurde erfolgreich geändert!\r\nSie erhalten in Kürze eine Bestätigung per EMail';
		} catch (err) {
			const error = err.request.response && JSON.parse(err.request.response);
			if (error[0].Error === 9) {
				snackBarText = 'Fehler bei der Änderung Ihres Passworts!\r\nFalsche Eingabe beim aktuellen Passwort';
			} else {
				snackBarText = 'Fehler bei der Änderung Ihres Passworts!';
			}
		} finally {
			this.snackBar.open(snackBarText, null, {
				duration: 3000,
				panelClass: 'orange-snackbar'
				// verticalPosition: 'top'
			});
			this.pwChangePending = false;
		}
	}

	public async promotionOrdered(promotion: CuberryPromotion) {
		this.updateAboTypePending = true;
		try {
			const result = await this.portalsService.updateAboType(this.portalObject, promotion);
			this.portalObject.AboType = result.AboType;
			this.showPromoOrderedDialog(promotion);
			return result;
		} catch (err) {
			console.log(err);
		} finally {
			this.updateAboTypePending = false;
		}
		// console.log(promotion);
	}

	public showPromoOrderedDialog(promoItem: CuberryPromotion) {
		if(this.dialog.openDialogs.some(openDialog => openDialog.componentInstance instanceof PromotionOrderedDialogComponent)){
			return;
		  }
		this.dialog.open(PromotionOrderedDialogComponent, {
			width: '500px',
			data: promoItem
		});
	}
}
