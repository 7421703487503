import { Pipe, PipeTransform } from '@angular/core';
import { PatientRequest } from 'src/app/shared/classes/patient-request';

@Pipe({
  name: 'requestFilter'
})
export class RequestFilterPipe implements PipeTransform {
  transform(value: PatientRequest[], filterString: string): unknown {
    const filter = (filterString || '').trim().toLowerCase();

    return !filter
      ? value
      : (value || []).filter(
          (item) => item.patientName.toLowerCase().includes(filter) || ((item.RequestNo ?? '') + '').toLowerCase().includes(filter)
        );
  }
}
